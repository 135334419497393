import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import {
  PPMForSiteMap,
  PPMForSiteMapParams,
  PPMForSiteMapResponse,
} from "./types";
import { collapseTimestreamNodeData } from "../../pages/OrganizationMap/collapseTimestreamNodeData";
import { TimestreamNodePPM } from "../timestreamNodePPM/types";

interface PPMForSiteMapRaw {
  result: TimestreamNodePPM[];
}

export const ppmForSiteMapApi = createApi({
  reducerPath: "ppmForSiteMapApi",
  baseQuery,
  tagTypes: ["PPMForSiteMap"],
  endpoints: (builder) => ({
    getPPMForSiteMap: builder.query<PPMForSiteMapResponse, PPMForSiteMapParams>(
      {
        query: (payload) => {
          const { siteId, startDate, timezone, ppmEndDateHours } = payload;
          const params = {
            startDate,
            timezone,
            ppmEndDateHours,
          };
          return {
            url: `site/${siteId}/ppmForSiteMap`,
            method: "GET",
            params,
          };
        },
        providesTags: ["PPMForSiteMap"],
        transformResponse: (response: PPMForSiteMapRaw) => {
          let mapPPM: PPMForSiteMap[] = [];
          if (response.result !== undefined) {
            mapPPM = collapseTimestreamNodeData(response.result);
          }
          return { result: mapPPM };
        },
      }
    ),
  }),
});

export const { useGetPPMForSiteMapQuery } = ppmForSiteMapApi;
