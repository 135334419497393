import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Form,
  Select,
  DatePicker,
  Button,
  Table,
  Spin,
  Modal,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useLazyGetOrganizationsQuery } from "../../store/organizations/api";
import { FormValues } from "./types";
import {
  AlarmIncidentType,
  ConcentrationAlarmIncident,
  AlarmIncidentEnum,
} from "../../store/orgAlarmIncidentsV2/types";
import { useLazyGetOrgAlarmIncidentsQuery } from "../../store/orgAlarmIncidentsV2/api";
import { Site } from "../../store/sites/types";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { useGetGlobalConfigQuery } from "../../store/globalConfig/api";
import { currentOrganizationSelector } from "../../store/organizations/selector";
import { useGetCurrentUserQuery } from "../../store/auth/api";
import { Organization } from "../../store/organizations/types";
import {
  convertTimezone,
  getDateString,
  isValidTimestamp,
  TIMESTAMP_FORMAT,
} from "../../helpers/dayjsHelpers";
import { setCurrentSite } from "../../store/sites/slice";
import { defaultLat, defaultLon } from "../../components/LayoutV2/LayoutV2";
import BulkAssignSiteAlarmsConfig from "../../components/SiteAlarmsForm/BulkAssignSiteAlarmsConfig/BulkAssignSiteAlarmsConfig";
import styles from "./styles.module.scss";
import { Key } from "antd/lib/table/interface";
import { useNavigate } from "react-router-dom";
import {
  setCurrentTab,
  setEndTimestamp,
  setStartTimestamp,
} from "../../store/selections/slice";
import {
  setShowMapController,
  setShowSideNavigation,
} from "../../store/orgMap/slice";
import { zoomInSite } from "../OrganizationMap/mapApi";
import { setCurrentAlarmIncident } from "../../store/gpaqsAlarmIncidents/slice";
import IncidentDetails from "./IncidentDetails/IncidentDetails";
import { GpaqsAlarmIncident } from "../../components/MapController/types";
import { mcfHrToMcfDayAndKgHrStr } from "../../helpers/unitsHelpers";

const { Option } = Select;

const GREEN = "#52C41A";
const greenBackgroundStyle = { background: GREEN, borderColor: GREEN };

const OrgAlarmsV2: FC = () => {
  const globalConfig = useGetGlobalConfigQuery(null).data;
  const [showTriageModal, setShowTriageModal] = useState(false);
  const [triageIncident, setTriageIncident] =
    useState<null | AlarmIncidentType>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [orgId, setOrgId] = useState<string>("");
  const [showBulkAssign, setShowBulkAssign] = useState(false);
  const [getOrganizationsLazyQuery, resultGetOrganizations] =
    useLazyGetOrganizationsQuery();
  const [getOrgAlarmIncidentsTrigger, orgAlarmIncidentsResult] =
    useLazyGetOrgAlarmIncidentsQuery();
  const currentOrg = useAppSelector(currentOrganizationSelector);

  const userData = useGetCurrentUserQuery(null);
  const isAdmin = userData?.data?.isAdmin ?? false;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(
    () =>
      setIsLoading(
        resultGetOrganizations.isLoading ||
          orgAlarmIncidentsResult.isLoading ||
          userData.isLoading
      ),
    [
      resultGetOrganizations.isLoading,
      orgAlarmIncidentsResult.isLoading,
      userData.isLoading,
    ]
  );

  let gpaqsAlarmIncidents: GpaqsAlarmIncident[] = [];
  let concentrationAlarmIncidents: ConcentrationAlarmIncident[] = [];
  let sites: Site[] = [];

  if (orgAlarmIncidentsResult.data !== undefined) {
    sites = [...orgAlarmIncidentsResult.data.sites];
    sites.sort((a: Site, b: Site) => a.name.localeCompare(b.name));

    gpaqsAlarmIncidents = orgAlarmIncidentsResult.data.orgAlarmIncidents;
    concentrationAlarmIncidents =
      orgAlarmIncidentsResult.data.orgConcentrationAlarmIncidents;
  }

  const sitesObj = Object.fromEntries(sites.map((s: Site) => [s.SiteId, s]));
  const siteIdFilter = sites.map((site) => ({
    text: site.name,
    value: site.SiteId,
  }));

  const tableData: AlarmIncidentType[] = [];
  if (gpaqsAlarmIncidents !== undefined) {
    tableData.push(...gpaqsAlarmIncidents);
  }
  if (concentrationAlarmIncidents !== undefined) {
    tableData.push(...concentrationAlarmIncidents);
  }
  tableData.sort((a: AlarmIncidentType, b: AlarmIncidentType) => {
    return new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime();
  });

  const currentOrgId = currentOrg?.OrgId;

  useEffect(() => {
    if (currentOrgId !== undefined && currentOrgId !== null) {
      setOrgId(currentOrgId);
    }
  }, [currentOrgId]);

  const currentOrgName = currentOrg?.name;

  const defaultOption = (
    <Option value={currentOrgId} key={currentOrgId}>
      {currentOrgName}
    </Option>
  );

  let options: JSX.Element[] = [defaultOption];

  if (resultGetOrganizations.data !== undefined) {
    const organizations = [...resultGetOrganizations.data];
    organizations.sort((a: Organization, b: Organization) =>
      a.name.localeCompare(b.name)
    );
    options = organizations.map((org) => {
      return (
        <Option value={org.OrgId} key={org.OrgId}>
          {org.name}
        </Option>
      );
    });
  }

  const onClickBulkAssign = (): void => {
    if (orgId === "") {
      window.alert("Please select an organization");
    } else {
      dispatch(setCurrentSite(null));
      setShowBulkAssign(true);
    }
  };

  const onOrgChange = (orgId: string): void => {
    setOrgId(orgId);
  };

  const onFinish = async (): Promise<void> => {
    const { month, organization } = form.getFieldsValue();
    if (month !== null && orgId !== null) {
      await getOrgAlarmIncidentsTrigger({
        orgId: organization,
        date: month.format("YYYY-MM-01"),
      });
    }
  };

  const handleLinkToSiteMap = (
    timestamp: string,
    incident: AlarmIncidentType
  ): void => {
    const site = sitesObj[incident.SiteId];
    if (site === null || site === undefined) {
      return;
    }
    const { timezone } = site;
    const timestampSiteTz = convertTimezone(timestamp, "UTC", timezone);

    const newStartTimestampSiteTz = dayjs(timestampSiteTz)
      .startOf("day")
      .format(TIMESTAMP_FORMAT);
    const newEndTimestampSiteTz = dayjs(newStartTimestampSiteTz)
      .add(1, "day")
      .format(TIMESTAMP_FORMAT);

    const newStartTimestampUtc = convertTimezone(
      newStartTimestampSiteTz,
      timezone,
      "UTC"
    );
    const newEndTimestampUtc = convertTimezone(
      newEndTimestampSiteTz,
      timezone,
      "UTC"
    );

    dispatch(setCurrentSite(site));
    dispatch(setCurrentTab("Site Map"));
    dispatch(setShowMapController(true));
    dispatch(setCurrentAlarmIncident(incident));
    dispatch(setShowSideNavigation(false));
    dispatch(setStartTimestamp(newStartTimestampUtc));
    dispatch(setEndTimestamp(newEndTimestampUtc));
    navigate("/");

    const defaultLatLon = {
      latAPI: defaultLat,
      longAPI: defaultLon,
    };
    const { latAPI, longAPI } = site?.details ?? defaultLatLon;
    zoomInSite({
      latitude: latAPI,
      longitude: longAPI,
    });
  };

  const [form] = Form.useForm<FormValues>();

  const columns = [
    {
      title: "Info",
      key: "info",
      render: (record: AlarmIncidentType) => {
        return (
          <InfoCircleOutlined
            style={{ color: "#1677ff" }}
            onClick={() => {
              setTriageIncident(record);
              setShowTriageModal(true);
            }}
          >
            Details
          </InfoCircleOutlined>
        );
      },
    },
    {
      title: "Start Time",
      key: "startTime",
      render: (incident: AlarmIncidentType) => {
        const timestamp =
          incident.type === AlarmIncidentEnum.GPAQS
            ? (incident as GpaqsAlarmIncident)?.most_likely_event?.Timestamp ??
              "N/A"
            : incident?.Timestamp ?? "N/A";
        const site = sitesObj[incident.SiteId];
        const { timezone } = site;
        return (
          <Tooltip title="View in Site Map">
            <Button
              type="link"
              onClick={() => handleLinkToSiteMap(timestamp, incident)}
            >
              {convertTimezone(timestamp, "UTC", timezone)} {timezone}
            </Button>
          </Tooltip>
        );
      },
      sorter: (a: AlarmIncidentType, b: AlarmIncidentType) => {
        return (
          new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime()
        );
      },
    },
    {
      title: "Site",
      dataIndex: "SiteId",
      key: "SiteId",
      filters: siteIdFilter,
      render: (SiteId: string) => {
        const site = sitesObj[SiteId];
        return site !== undefined ? site.name : "N/A";
      },
      onFilter: (value: boolean | Key, record: { SiteId: string }) => {
        return record.SiteId === value;
      },
    },
    {
      title: "Measurement",
      key: "measurement",
      render: (record: AlarmIncidentType) => {
        if (record.type === AlarmIncidentEnum.GPAQS) {
          const value =
            (record as GpaqsAlarmIncident)?.most_likely_event?.[
              "avgRate(MCF/h)"
            ] ?? undefined;
          if (value === undefined || isNaN(value) || value === 0) {
            return "N/A";
          }
          return mcfHrToMcfDayAndKgHrStr(value, globalConfig);
        }
        if (record.type === AlarmIncidentEnum.CONCENTRATION) {
          const value = Number((record as ConcentrationAlarmIncident).ppm);
          return value.toFixed(0) + " ppm";
        }
      },
    },
    {
      title: "Alarm Generated",
      dataIndex: "should_alarm",
      key: "should_alarm",
      render: (shouldAlert: boolean) => {
        if (shouldAlert) return "true";
        return "false";
      },
    },
    {
      title: "End Time",
      key: "end_time",
      render: (incident: AlarmIncidentType) => {
        switch (incident.type) {
          case "gpaqsAlarm": {
            const gpaqsAlarmIncident = incident as GpaqsAlarmIncident;
            const timestamp = gpaqsAlarmIncident.end_time;
            const site = sitesObj[incident.SiteId];
            const { timezone } = site;
            if (!isValidTimestamp(timestamp)) {
              break;
            }
            return (
              <span>
                {convertTimezone(timestamp, "UTC", timezone)} {timezone}
              </span>
            );
          }
          case "highConcentrationAlarm":
            break;
        }
        return <span>N/A</span>;
      },
    },
    {
      title: "Recipients",
      dataIndex: "recipients",
      key: "recipients",
      render: (recipients: string | string[] | undefined) => {
        if (Array.isArray(recipients)) {
          return recipients.join(" - ");
        }
        if (recipients !== undefined && recipients !== null) {
          const displayText = recipients.replaceAll(";", " - ");
          return displayText;
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string | undefined) => {
        if (status !== undefined || status !== null) {
          return status;
        } else {
          return "Triage";
        }
      },
    },
    {
      title: "Incident Type",
      dataIndex: "incidentType",
      key: "incidentType",
      render: (incidentType: string | undefined) => {
        if (incidentType !== undefined || incidentType !== null) {
          return incidentType;
        } else {
          return "N/A";
        }
      },
    },
  ];

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (isAdmin) {
        await getOrganizationsLazyQuery(null);
      }
      if (currentOrgId !== null && currentOrgId !== undefined) {
        await getOrgAlarmIncidentsTrigger({
          orgId: currentOrgId,
          date: getDateString(),
        });
      }
    };

    void fetchData();
  }, [isAdmin]);

  return (
    <div>
      {showTriageModal ? (
        <Modal
          open={true}
          onCancel={() => {
            setShowTriageModal(false);
            setTriageIncident(null);
          }}
          footer={[]}
        >
          {triageIncident !== null ? (
            <IncidentDetails
              incident={triageIncident}
              refreshIncidents={onFinish}
              onCancel={() => {
                setShowTriageModal(false);
                setTriageIncident(null);
              }}
            />
          ) : null}
        </Modal>
      ) : null}
      <BulkAssignSiteAlarmsConfig
        orgId={orgId}
        showBulkAssign={showBulkAssign}
        setShowBulkAssign={setShowBulkAssign}
      />
      <h3>Organization Incidents</h3>
      <div className={styles.FormWrapper}>
        <Form<FormValues>
          form={form}
          name="organization_month_form"
          onFinish={onFinish}
          layout="inline"
          initialValues={{
            organization: currentOrgId,
            month: null,
          }}
        >
          <Form.Item<FormValues>
            name="organization"
            label="Organization"
            rules={[
              {
                required: true,
                message: "Please select an organization",
              },
            ]}
          >
            <Select
              placeholder="Select organization"
              style={{ width: 200 }}
              disabled={!isAdmin}
              onChange={onOrgChange}
            >
              {options}
            </Select>
          </Form.Item>

          <Form.Item<FormValues>
            name="month"
            label="Month"
            rules={[
              {
                required: true,
                message: "Please select a month",
              },
            ]}
          >
            <DatePicker
              picker="month"
              placeholder="Select month"
              style={{ width: 200 }}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          <Form.Item style={{ display: "flex", marginLeft: "auto" }}>
            <Button
              type="primary"
              style={greenBackgroundStyle}
              onClick={() => onClickBulkAssign()}
            >
              Bulk Assign
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div>
        <div className={styles.Spin}>
          {isLoading ? (
            <Spin />
          ) : (
            <Table<AlarmIncidentType>
              columns={columns}
              dataSource={tableData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrgAlarmsV2;
