import { FC } from "react";
import { Radio, Button, RadioChangeEvent } from "antd";
import EditCell from "../EditCell/EditCell";
import styles from "./styles.module.scss";
import { EditGridControlProps } from "./types";
import { LayerSwitch } from "../../LayersSwitch/LayersSwitch";

export const EditGridControls: FC<EditGridControlProps> = ({
  onSaveGridConfig,
  handleEditTypeChange,
  selectedEditType,
  selectedCellToEdit,
  formKey,
  onSaveEditCell,
  requireDroneImage,
  setShowDroneImageLayer,
  showDroneImageLayer
}) => {
  return (
    <div className={styles.RadioGroupVertical}>
      {requireDroneImage && (
        <div>{LayerSwitch("Drone Image", setShowDroneImageLayer, showDroneImageLayer)}</div>
      )}
      <Radio.Group
        value={selectedEditType}
        onChange={(e: RadioChangeEvent) => {
          const value = Number(e.target.value);
          if (!isNaN(value)) {
            handleEditTypeChange(value);
          }
        }}
      >
        <Radio value={1}>Set Active Locations</Radio>
        <br />
        <Radio value={2}>Add Equipment Details</Radio>
      </Radio.Group>
      <br></br>
      {selectedEditType === 1 ? (
        <div>
          <div>
            <h3>Select Active Locations</h3>
            <div>
              Select the areas of the pad that have production equipment and can
              be potential leak sources.
            </div>
          </div>
          <Button
            style={{ marginTop: 10 }}
            type="primary"
            onClick={() => onSaveGridConfig(false)}
          >
            Save Grid
          </Button>
        </div>
      ) : (
        <div>
          <h3>Input Additional Equipment Information</h3>
          <div>
            Classify equipment in each portion of the pad, add equipment height,
            and provide a common name.
          </div>
          <br />
          <EditCell
            cell={selectedCellToEdit}
            key={formKey}
            onSaveEditCell={onSaveEditCell}
          />
        </div>
      )}
    </div>
  );
};

export default EditGridControls;
