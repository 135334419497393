import { Grid } from "../../../pages/SiteMapv2/helpers";
import { Node } from "../../../store/nodes/types";

export interface GridVisibilityControlsProps {
  siteNodes: Node[];
  siteGrid: Grid;
  requireDroneImage: boolean;
  showDroneImage: boolean;
  setShowDroneImageLayer: () => void;
}

export interface Color {
  r: number;
  g: number;
  b: number;
  a: number;
}

export const colorRates = [
  { color: "red", legend: "0% to 25%" },
  {
    color: "orange",
    legend: "25% to 50%",
  },
  {
    color: "lime",
    legend: "50% to 75%",
  },
  {
    color: "blue",
    legend: "above 75%",
  },
];
