import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { GetRawNodeSamplesResponse, GetRawNodeSamplesParams } from "./types";

export const rawNodeSamplesApi = createApi({
  reducerPath: "rawNodeSamplesApi",
  baseQuery,
  endpoints: (builder) => ({
    getRawNodeSamples: builder.query<
    GetRawNodeSamplesResponse,
    GetRawNodeSamplesParams
    >({
      query: (payload) => {
        const { id, nodeIds, startDate, endDate, requestedFields, timezone } =
          payload;
        const params = {
          startDate,
          endDate,
          nodeIds,
          requestedFields,
          timezone,
        };
        return {
          url: `rawNodeSamples/${id}`,
          method: "GET",
          params,
        };
      },
    }),
  }),
});
