import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import type { SiteAlarmsConfig, PostBulkAssignSiteAlarmsConfigPayload } from "./types";

export const siteAlarmsConfigApi = createApi({
  reducerPath: "siteAlarmsConfigApi",
  baseQuery,
  tagTypes: ["SiteAlarmsConfig"],
  endpoints: (builder) => ({
    getSiteAlarmsConfig: builder.query<SiteAlarmsConfig, string>({
      query: (siteId: string) => `siteAlarmsConfig/${siteId}`,
      providesTags: ["SiteAlarmsConfig"],
    }),
    createSiteAlarmsConfig: builder.mutation<null, SiteAlarmsConfig>({
      query: (payload: SiteAlarmsConfig) => ({
        url: "siteAlarmsConfig",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["SiteAlarmsConfig"],
    }),
    postBulkAssignSiteAlarmsConfig: builder.mutation<null, PostBulkAssignSiteAlarmsConfigPayload>({
      query: (payload: PostBulkAssignSiteAlarmsConfigPayload) => ({
        url: `org/${payload.OrgId}/siteAlarmsConfig`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["SiteAlarmsConfig"]
    })
  }),
});

export const {
  useGetSiteAlarmsConfigQuery,
  useCreateSiteAlarmsConfigMutation,
  usePostBulkAssignSiteAlarmsConfigMutation,
} = siteAlarmsConfigApi;
