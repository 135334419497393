export const createGridOverlay = (Maps, map, siteGrid, gMatrix) => {
  const createCanvas = () => {
    const canvas = document.createElement("canvas");
    canvas.width = map.getWidth();
    canvas.height = map.getHeight();
    canvas.style.position = "absolute";
    canvas.style.pointerEvents = "none";
    return canvas;
  };

  const addEventHandlers = (redrawFunc) => {
    Maps.Events.addHandler(map, "viewchange", redrawFunc);
    Maps.Events.addHandler(map, "viewchangeend", redrawFunc);
  };

  const drawCell = (context, map, Maps, siteGrid, cell) => {
    const { minLong, maxLong, minLat, maxLat, gridX, gridY } = siteGrid;
    const { x, y, fillColor, strokeColor } = cell;

    const cellWidth = (maxLong - minLong) / gridX;
    const cellHeight = (maxLat - minLat) / gridY;

    const cellMinLong = minLong + (x - 1) * cellWidth;
    const cellMaxLong = minLong + x * cellWidth;
    const cellMinLat = minLat + (y - 1) * cellHeight;
    const cellMaxLat = minLat + y * cellHeight;

    const topLeft = map.tryLocationToPixel(
      new Maps.Location(cellMaxLat, cellMinLong),
      Maps.PixelReference.control
    );
    const bottomRight = map.tryLocationToPixel(
      new Maps.Location(cellMinLat, cellMaxLong),
      Maps.PixelReference.control
    );

    const rectWidth = bottomRight.x - topLeft.x;
    const rectHeight = bottomRight.y - topLeft.y;

    context.fillStyle = fillColor;
    context.strokeStyle = strokeColor;
    context.lineWidth = 1;

    context.beginPath();
    context.rect(topLeft.x, topLeft.y, rectWidth, rectHeight);
    context.fill();
    context.stroke();
  };

  const redraw = (canvas) => {
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    gMatrix.forEach((cell) => drawCell(context, map, Maps, siteGrid, cell));
  };

  const overlay = new Maps.CustomOverlay({
    beneathLabels: true,
    drawOrder: 0
  });

  const canvas = createCanvas();
  overlay.setHtmlElement(canvas);

  overlay.onLoad = () => {
    redraw(canvas);
    addEventHandlers(() => redraw(canvas));
  };

  return overlay;
};
