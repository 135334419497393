import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";

export interface GpaqsRollingAveragesObject {
  "7_days_average_emission_rates": string;
  "7_days_average_standard_deviation": string;
  "30_days_average_emission_rates": string;
  "30_days_average_standard_deviation": string;
  "90_days_average_emission_rates": string;
  "90_days_average_standard_deviation": string;
  SiteId: string;
  Timestamp: string;
}

interface GpaqsRollingAvgsPayload {
  siteId: string;
  date?: string;
}

interface GpaqsRollingAvgsRangePayload {
  siteId: string;
  startDate: string;
  endDate: string;
}

export const gpaqsRollingAverages = createApi({
  reducerPath: "gpaqsRollingAveragesApi",
  baseQuery,
  tagTypes: ["GpaqsRollingAverages"],
  endpoints: (builder) => ({
    getGpaqsRollingAverages: builder.query<
    GpaqsRollingAveragesObject,
    GpaqsRollingAvgsPayload
    >({
      query: (payload: GpaqsRollingAvgsPayload) => {
        const { siteId, date } = payload;
        const hasDate = date !== undefined;
        if (hasDate) {
          return `site/${siteId}/oneGpaqsAverageEmissionRate?date=${date}`;
        } else {
          return `site/${siteId}/oneGpaqsAverageEmissionRate`;
        }
      },
      providesTags: ["GpaqsRollingAverages"],
    }),
    getRangeGpaqsRollingAverages: builder.query<GpaqsRollingAveragesObject[], GpaqsRollingAvgsRangePayload>({
      query: (payload: GpaqsRollingAvgsRangePayload) => {
        const { siteId, startDate, endDate } = payload;
        return `site/${siteId}/rangeGpaqsAverageEmissionRates?startDate=${startDate}&endDate=${endDate}`;
      },
      providesTags: ["GpaqsRollingAverages"]
    })
  }),
});

export const { useGetGpaqsRollingAveragesQuery, useGetRangeGpaqsRollingAveragesQuery } = gpaqsRollingAverages;
