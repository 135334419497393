import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import {
  GetOrgAlarmIncidentsParams,
  GetOrgAlarmIncidentsResult,
} from "./types";

export const orgAlarmIncidentsApi = createApi({
  reducerPath: "orgAlarmIncidentsApi",
  baseQuery,
  tagTypes: ["orgAlarmIncidents"],
  endpoints: (builder) => ({
    getOrgAlarmIncidents: builder.query<
    GetOrgAlarmIncidentsResult,
    GetOrgAlarmIncidentsParams
    >({
      query: (params: GetOrgAlarmIncidentsParams) =>
        `orgAlarmIncidentsV2/${params.orgId}?date=${params.date}`,
      providesTags: ["orgAlarmIncidents"],
    }),
  }),
});

export const { useLazyGetOrgAlarmIncidentsQuery } = orgAlarmIncidentsApi;
