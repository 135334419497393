import { Button, Table, Switch, Input, Tooltip } from "antd";
import { FC, useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { currentOrganizationSelector } from "../../store/organizations/selector";
import { useToggleSiteMutation } from "../../store/sites/api";
import { setCurrentSite } from "../../store/sites/slice";
import { Site } from "../../store/sites/types";
import { setGoBackRoute, setPageTitle } from "../../store/ui/slice";
import type { ColumnsType } from "antd/es/table";
import styles from "./styles.module.scss";
import { useGetCustomerAlarmIncidentsQuery } from "../../store/alarmIncidents/api";
import {
  AlarmIncidentParams,
  SiteAlarm,
} from "../../store/alarmIncidents/types";
import StatusTag from "../../components/StatusTags/StatusTags";

const Sites: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentOrganization = useAppSelector(currentOrganizationSelector);
  const [toggleSiteMutation] = useToggleSiteMutation();
  const [filteredTableData, setFilteredTableData] = useState<SiteAlarm[]>([]);

  const navigateAndLinkSite = (record: Site): void => {
    dispatch(setCurrentSite(record));
    record.isSingleNode ?? false
      ? navigate(`/sites/${record.SiteId}`)
      : navigate(`/sites/${record.SiteId}`);
  };
  let OrgId = "";

  if (currentOrganization !== null) {
    OrgId = currentOrganization.OrgId;
  }

  const params: AlarmIncidentParams = {
    ids: String(OrgId),
  };

  const { data, isLoading, isError } =
    useGetCustomerAlarmIncidentsQuery(params);

  const onToggleSite = (siteId: string, newValue: boolean): void => {
    const payload = { SiteId: siteId, OrgId, newValue };
    toggleSiteMutation(payload)
      .unwrap()
      .catch((err) => console.log(err));
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const search = e.target.value.toLowerCase();
    setFilteredTableData(
      tableData.filter((siteObj) => {
        return siteObj.name.toLowerCase().includes(search);
      })
    );
  };

  const columns: ColumnsType<SiteAlarm> = [
    {
      title: "Facility Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: Site) => (
        <a onClick={() => navigateAndLinkSite(record)}>{text}</a>
      ),
      sorter: (a, b): number => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Type",
      dataIndex: "config",
      key: "config",
      render: (config) => {
        let algorithm = "";
        switch (Number(config.algorithm)) {
          case 1:
            algorithm = "Methane Only";
            break;
          case 2:
            algorithm = "Mostly Methane";
            break;
          case 3:
            algorithm = "Natural Gas";
            break;
          default:
            algorithm = "";
        }
        return <div>{algorithm}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, record: Site) => {
        return <StatusTag siteStatus={status} isActive={record.active} />;
      },
    },
    {
      title: "Leak Rate (MCFH)",
      dataIndex: "leakRate",
      key: "leakRate",
      render: (text: string) => <span>{text ?? "-"} </span>,
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (text: string) => {
        let displayTime = "";
        if (text !== undefined && text !== null) {
          displayTime = new Date(text + "Z").toLocaleString();
          if (displayTime === "Invalid Date") displayTime = "";
        }
        return <span>{displayTime}</span>;
      },
    },
    {
      title: "Likely Source",
      dataIndex: "source",
      key: "source",
      render: (text: string) => <span>{text ?? "-"}</span>,
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (active: boolean, record: Site) => {
        return (
          <Switch
            defaultChecked={active}
            onChange={(checked: boolean) =>
              onToggleSite(record.SiteId, checked)
            }
          />
        );
      },
    },
  ];

  let tableData: SiteAlarm[] = [];

  if (
    !isError &&
    !isLoading &&
    data !== undefined &&
    data[0] !== undefined &&
    data[0].sites !== undefined
  ) {
    tableData = data[0].sites?.map((el) => {
      return {
        ...el,
        key: el.SiteId,
      };
    });
  }

  useEffect(() => {
    if (currentOrganization !== null) {
      dispatch(setPageTitle(currentOrganization.name));
      dispatch(setGoBackRoute("/organizations"));
    }
  }, []);

  useEffect(() => {
    setFilteredTableData(tableData);
  }, [isLoading]);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "17.5rem" }}>
          <Tooltip title="Search by Facility Name">
            <Input
              placeholder="Search..."
              allowClear
              onChange={(event) => onChangeSearch(event)}
            />
          </Tooltip>
        </div>
        <div className={styles.NewSiteWrapper}>
          <Button type="primary" onClick={() => navigate("/sites/new")}>
            Create Site
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={filteredTableData}
        loading={isLoading}
      />
    </div>
  );
};

export default Sites;
