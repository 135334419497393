import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { AlarmIncidentType } from "../orgAlarmIncidentsV2/types";

interface AlarmIncidentsState {
  selectedAlarmIncident: null | AlarmIncidentType;
}

const initialState: AlarmIncidentsState = {
  selectedAlarmIncident: null,
};

export const gpaqsAlarmIncidentsSlice: Slice = createSlice({
  name: "gpaqsAlarmIncidentsState",
  initialState,
  reducers: {
    setCurrentAlarmIncident: (
      state: AlarmIncidentsState,
      action: PayloadAction<AlarmIncidentType>
    ) => {
      state.selectedAlarmIncident = action.payload;
    },
  },
});

export const { setCurrentAlarmIncident } = gpaqsAlarmIncidentsSlice.actions;

export default gpaqsAlarmIncidentsSlice.reducer;
