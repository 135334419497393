import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

interface OrgMapState {
  showMapController: boolean;
  showSideNavigation: boolean;
}

const initialState: OrgMapState = {
  showMapController: false,
  showSideNavigation: true,
};

export const orgMapSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setShowMapController: (
      state: OrgMapState,
      action: PayloadAction<boolean>,
    ) => {
      state.showMapController = action.payload;
    },
    setShowSideNavigation: (
      state: OrgMapState,
      action: PayloadAction<boolean>,
    ) => {
      state.showSideNavigation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState;
    });
  }
});

export const { setShowMapController, setShowSideNavigation } = orgMapSlice.actions;

export default orgMapSlice.reducer;
