import { FC } from "react";
import { Typography } from "antd";
import { AlarmSectionProps } from "./types";
import styles from "./styles.module.scss";

const { Text } = Typography;

const AlarmSection: FC<AlarmSectionProps> = ({
  mainText,
  description,
  element,
}) => {
  return (
    <div className={styles.AlarmSection}>
      <div>
        <div><Text strong={true}>{mainText}</Text></div>
        <Text type="secondary">{description}</Text>
      </div>
      <div className={styles.FlexOne} />
      <div className={styles.Element}>{element}</div>
    </div>
  );
};

export default AlarmSection;
