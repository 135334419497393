import { FC } from "react";
import AverageEmissonRatesChart from "./AverageEmissonRatesChart/AverageEmissionRatesChart";
import { singleNodeSitePAQSApi } from "../../store/singleNodeSitePAQS/api";
import { QuantityUnit } from "../../helpers/unitsHelpers";
import { gpaqsRollingAverages } from "../../store/gpaqsRollingAverages/api";
import { EpaBaselineDisplays } from "../../pages/SiteStats/types";
import { SiteBaseline } from "../../store/siteBaselines/types";
import { getDateString } from "../../helpers/dayjsHelpers";

interface AverageEmissionRatesProps {
  siteId: string;
  siteName: string;
  selectedUnits: QuantityUnit;
  timezone?: string;
  resetChartKey: number;
  chartTimerange: { startTimestamp: string; endTimestamp: string };
  handleSetChartTimerange: (
    startTimestamp: string,
    endTimestamp: string
  ) => void;
  siteBaseline: SiteBaseline | null;
  epaBaselineDisplay: EpaBaselineDisplays;
}
const AverageEmissionRates: FC<AverageEmissionRatesProps> = ({
  siteId,
  siteName,
  selectedUnits,
  timezone,
  resetChartKey,
  chartTimerange,
  handleSetChartTimerange,
  siteBaseline,
  epaBaselineDisplay,
}) => {
  const singleNodeSitePAQSPayload = {
    siteId,
    siteName,
    startDate: chartTimerange.startTimestamp,
    endDate: chartTimerange.endTimestamp,
    timezone,
  };
  const singleNodeSitePAQSResponse =
    singleNodeSitePAQSApi.endpoints.getGpaqsAverageEmissionRates.useQuery(
      singleNodeSitePAQSPayload,
      { skip: siteId === "" }
    );
  const data = singleNodeSitePAQSResponse?.data?.result ?? [];
  const rollingAveragesResponse = gpaqsRollingAverages.endpoints.getRangeGpaqsRollingAverages.useQuery(
    {
      siteId,
      startDate: getDateString(chartTimerange.startTimestamp),
      endDate: chartTimerange.endTimestamp,
    },
    { skip: siteId === "" }
  );
  const rollingAverages = rollingAveragesResponse.data;
  return (
    <div style={{ width: "99%" }}>
      <AverageEmissonRatesChart
        siteName={siteName}
        data={data}
        units={selectedUnits}
        timezone={timezone}
        resetChartKey={resetChartKey}
        chartTimerange={chartTimerange}
        handleSetChartTimerange={handleSetChartTimerange}
        siteBaseline={siteBaseline}
        epaBaselineDisplay={epaBaselineDisplay}
        rollingAverages={rollingAverages}
      />
    </div>
  );
};

export default AverageEmissionRates;
