import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { DeviceDataSnapshotParams, DeviceDataSnapshotResponse } from "./types";

export const deviceDataSnapshotApi = createApi({
  reducerPath: "deviceDataSnapshotApi",
  baseQuery,
  endpoints: (builder) => ({
    requestDeviceDataSnapshot: builder.query<
    DeviceDataSnapshotResponse,
    DeviceDataSnapshotParams
    >({
      query: (payload) => {
        const { nodeId, endDate, recipients } = payload;
        const params = { endDate, recipients };
        return {
          url: `nodes/${nodeId}/requestDeviceDataSnapshot`,
          method: "GET",
          params,
        };
      },
    }),
  }),
});
