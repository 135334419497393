import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import uiReducer from "./ui/slice";
import authReducer from "./auth/slice";
import nodesReducer from "./nodes/slice";
import organizationsReducer from "./organizations/slice";
import sitesReducer from "./sites/slice";
import orgMapReducer from "./orgMap/slice";
import selectionsRedcer from "./selections/slice";
import gpaqsAlarmIncidentsState from "./gpaqsAlarmIncidents/slice";

import { dailyEmissionsApi } from "./dailyEmissions/api";
import { organizationsApi } from "./organizations/api";
import { sitesApi } from "./sites/api";
import { siteAlarmsApi } from "./siteAlarms/api";
import { usersApi } from "./users/api";
import { customerSitesApi } from "./customerSites/api";
import { authApi } from "./auth/api";
import { customerAlarmIncidentApi } from "./alarmIncidents/api";
import { requestCsvDownloadApi } from "./requestCsvDownload/api";
import { nodesPPMApi } from "./nodesPPM/api";
import { timestreamNodePPMApi } from "./timestreamNodePPM/api";
import { siteNodesApi } from "./nodes/api";
import { nodeSignOffApi } from "./nodeSignOff/api";
import { siteGridConfig } from "./gridConfig/api";
import { gpaqsAlarmIncidents } from "./gpaqsAlarmIncidents/api";
import { statusChecksApi } from "./statusChecks/api";
import { ppmForSiteMapApi } from "./ppmForSiteMap/api";
import { globalConfigApi } from "./globalConfig/api";
import { rawNodeSamplesApi } from "./rawNodeSamples";
import { samplingRatesApi } from "./samplingRates/api";
import { orgAlarmIncidentsApi } from "./orgAlarmIncidentsV2/api";
import { nodeConfigApi } from "./nodeConfig/api";
import { singleNodeSitePAQSApi } from "./singleNodeSitePAQS/api";
import { nodePAQSApi } from "./nodePAQS/api";
import { siteAlarmsConfigApi } from "./siteAlarmsConfig/api";
import { nodeReportsApi } from "./nodeReports/api";
import { nodeLocationHistoryApi } from "./nodeLocationHistory/api";
import { gpaqsRollingAverages } from "./gpaqsRollingAverages/api";
import { deviceDataSnapshotApi } from "./deviceDataSnapshot/api";
import { pinewaveZdscadaApi } from "./pinewaveZdscada/api";
import { siteImageApi } from "./siteImage/api";
import { nodeOptimizationImageApi } from "./nodeOptimizationImages/api";
import { backcalculateAlarms } from "./backcalculateAlarms/api";
import { eventConfidenceApi } from "./eventConfidence/api";
import { siteGridsApi } from "./siteGrids/api";
import { firmwareReleaseConfigApi } from "./firmwareReleaseConfig/api";
import { siteBaselinesApi } from "./siteBaselines/api";
import { nodeInstallHistoryApi } from "./nodeInstallHistory/api";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [
    "dailyEmissionsApi",
    "authApi",
    "auth",
    "organizations",
    "organizationsApi",
    "sites",
    "sitesApi",
    "siteMapPutApi",
    "customerAlarmIncidentsApi",
    "requestCsvDownloadApi",
    "nodesPPMApi",
    "timestreamNodePPMApi",
    "nodeSignOffApi",
    "siteNodesAPI",
    "gpaqsAlarmIncidents",
    "statusChecksApi",
    "ppmForSiteMapApi",
    "globalConfigApi",
    "rawNodeSamplesApi",
    "samplingRatesApi",
    "selections",
    "orgAlarmIncidentsApi",
    "siteAlarmsConfigApi",
    "gpaqsRollingAveragesApi",
    "siteImageApi",
    "nodeOptimizationImageApi",
    "backcalculateAlarmsApi",
    "eventConfidenceApi",
    "siteGirdsApi",
    "gpaqsAlarmIncidentsState",
    "firmwareReleaseConfigApi",
    "siteBaselinesApi",
    "nodeInstallHistoryApi",
    "customerSitesApi",
    "deviceDataSnapshotApi",
    "gpaqsAlarmIncidentsApi",
    "siteGridConfigApi",
    "nodeConfigApi",
    "nodeLocationHistoryApi",
    "nodePAQSApi",
    "nodeReportsApi",
    "pinewaveZdscadaApi",
    "singleNodeSitePAQSApi",
    "siteAlarmsApi",
    "siteGridsApi",
    "usersApi",
  ],
};

const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  nodes: nodesReducer,
  organizations: organizationsReducer,
  sites: sitesReducer,
  orgMap: orgMapReducer,
  selections: selectionsRedcer,
  gpaqsAlarmIncidentsState,
  [dailyEmissionsApi.reducerPath]: dailyEmissionsApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
  [sitesApi.reducerPath]: sitesApi.reducer,
  [siteAlarmsApi.reducerPath]: siteAlarmsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [customerSitesApi.reducerPath]: customerSitesApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [customerAlarmIncidentApi.reducerPath]: customerAlarmIncidentApi.reducer,
  [requestCsvDownloadApi.reducerPath]: requestCsvDownloadApi.reducer,
  [nodesPPMApi.reducerPath]: nodesPPMApi.reducer,
  [timestreamNodePPMApi.reducerPath]: timestreamNodePPMApi.reducer,
  [nodeSignOffApi.reducerPath]: nodeSignOffApi.reducer,
  [siteNodesApi.reducerPath]: siteNodesApi.reducer,
  [siteGridConfig.reducerPath]: siteGridConfig.reducer,
  [gpaqsAlarmIncidents.reducerPath]: gpaqsAlarmIncidents.reducer,
  [statusChecksApi.reducerPath]: statusChecksApi.reducer,
  [ppmForSiteMapApi.reducerPath]: ppmForSiteMapApi.reducer,
  [globalConfigApi.reducerPath]: globalConfigApi.reducer,
  [rawNodeSamplesApi.reducerPath]: rawNodeSamplesApi.reducer,
  [samplingRatesApi.reducerPath]: samplingRatesApi.reducer,
  [orgAlarmIncidentsApi.reducerPath]: orgAlarmIncidentsApi.reducer,
  [nodeConfigApi.reducerPath]: nodeConfigApi.reducer,
  [singleNodeSitePAQSApi.reducerPath]: singleNodeSitePAQSApi.reducer,
  [nodePAQSApi.reducerPath]: nodePAQSApi.reducer,
  [siteAlarmsConfigApi.reducerPath]: siteAlarmsConfigApi.reducer,
  [nodeReportsApi.reducerPath]: nodeReportsApi.reducer,
  [nodeLocationHistoryApi.reducerPath]: nodeLocationHistoryApi.reducer,
  [gpaqsRollingAverages.reducerPath]: gpaqsRollingAverages.reducer,
  [deviceDataSnapshotApi.reducerPath]: deviceDataSnapshotApi.reducer,
  [pinewaveZdscadaApi.reducerPath]: pinewaveZdscadaApi.reducer,
  [siteImageApi.reducerPath]: siteImageApi.reducer,
  [nodeOptimizationImageApi.reducerPath]: nodeOptimizationImageApi.reducer,
  [backcalculateAlarms.reducerPath]: backcalculateAlarms.reducer,
  [eventConfidenceApi.reducerPath]: eventConfidenceApi.reducer,
  [siteGridsApi.reducerPath]: siteGridsApi.reducer,
  [firmwareReleaseConfigApi.reducerPath]: firmwareReleaseConfigApi.reducer,
  [siteBaselinesApi.reducerPath]: siteBaselinesApi.reducer,
  [nodeInstallHistoryApi.reducerPath]: nodeInstallHistoryApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      dailyEmissionsApi.middleware,
      organizationsApi.middleware,
      sitesApi.middleware,
      siteAlarmsApi.middleware,
      usersApi.middleware,
      customerSitesApi.middleware,
      authApi.middleware,
      customerAlarmIncidentApi.middleware,
      requestCsvDownloadApi.middleware,
      siteNodesApi.middleware,
      nodesPPMApi.middleware,
      timestreamNodePPMApi.middleware,
      nodeSignOffApi.middleware,
      siteGridConfig.middleware,
      gpaqsAlarmIncidents.middleware,
      statusChecksApi.middleware,
      siteGridConfig.middleware,
      ppmForSiteMapApi.middleware,
      globalConfigApi.middleware,
      rawNodeSamplesApi.middleware,
      samplingRatesApi.middleware,
      orgAlarmIncidentsApi.middleware,
      nodeConfigApi.middleware,
      singleNodeSitePAQSApi.middleware,
      nodePAQSApi.middleware,
      siteAlarmsConfigApi.middleware,
      nodeReportsApi.middleware,
      nodeLocationHistoryApi.middleware,
      gpaqsRollingAverages.middleware,
      deviceDataSnapshotApi.middleware,
      pinewaveZdscadaApi.middleware,
      siteImageApi.middleware,
      nodeOptimizationImageApi.middleware,
      backcalculateAlarms.middleware,
      eventConfidenceApi.middleware,
      siteGridsApi.middleware,
      firmwareReleaseConfigApi.middleware,
      siteBaselinesApi.middleware,
      nodeInstallHistoryApi.middleware
    ),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
