import { FC, useState, useEffect } from "react";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TooltipPositionerFunction,
  Legend,
  TimeScale,
  Plugin,
  ChartType,
} from "chart.js";
import { ActiveElement } from "chart.js/dist/plugins/plugin.tooltip";
import { Line } from "react-chartjs-2";
import {
  NodePPMChartProps,
  TooltipContext,
  emptyTimestreamNodePPMItem,
  DeviceData,
} from "../types";
import styles from "../styles.module.scss";
import { TimeSeriesUnit } from "../../../pages/SiteStats/types";
import zoomPlugin from "chartjs-plugin-zoom";
import { TimestreamNodePPM } from "../../../store/timestreamNodePPM/types";
import {
  adjustTo30sBasis,
  getTimestampFromMilliseconds,
} from "../../../helpers/dayjsHelpers";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin
);

declare module "chart.js" {
  interface TooltipPositionerMap {
    customPositioner: TooltipPositionerFunction<ChartType>;
  }
}

const customPositioner = (
  elements: Readonly<ActiveElement[]>,
  eventPosition: { x: number }
): { x: number; y: number } => {
  let { x } = eventPosition;
  const y = 1e8; // large value pushes to bottom

  x += tooltipOffset;

  return { x, y };
};

Tooltip.positioners.customPositioner = customPositioner;
const BORDER_WIDTH = 1.3; // chartJS default is 2

const adjustHexBrightness = (
  hexColor: string,
  brightnessChange: number
  // 1 = no change
  // 1.1 = 10% brighter
  // 0.9 = 10% darker
): string => {
  // Convert hex to RGB
  const bigint = parseInt(hexColor.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Adjust brightness
  const adjustedR = Math.min(255, Math.round(r * brightnessChange));
  const adjustedG = Math.min(255, Math.round(g * brightnessChange));
  const adjustedB = Math.min(255, Math.round(b * brightnessChange));

  // Convert back to hex
  const newHexColor = `#${(
    (1 << 24) |
    (adjustedR << 16) |
    (adjustedG << 8) |
    adjustedB
  )
    .toString(16)
    .slice(1)}`;

  return newHexColor;
};

const MIN_PPM = 1.5;
const LOWEST_YMAX_PPM = 5.0;
const MIN_VOC = 0.0;
const LOWEST_YMAX_VOC = 1.0;
const MIN_CO2 = 0.0;
const LOWEST_YMAX_CO2 = 1000.0;
const UNIT_HOURS_THRESHOLD = 48;

const canvasElement = document.getElementById("chart-site-stats");
const chartInstance = ChartJS.getChart(canvasElement as HTMLCanvasElement);
const tooltipOffset = chartInstance?.tooltip?.width ?? 200;

const NodePPMChart: FC<NodePPMChartProps> = ({
  doDisplayPPM,
  doDisplayVOC,
  doDisplayPPMVOC,
  doDisplayCO2,
  timezone,
  data,
  selectedNodes,
  nodeColors,
  resetChartKey,
  chartTimerange,
  handleSetChartTimerange,
}) => {
  const chart = ChartJS.getChart("chart-site-stats");
  const title = doDisplayCO2
    ? "CO2 (PPM)"
    : doDisplayPPMVOC
      ? "CH4 & VOC (PPM)"
      : doDisplayVOC
        ? "VOC (PPM)"
        : "CH4 (PPM)";

  const chartElements = selectedNodes.map((node, idx) => {
    const { NodeId: nodeId, name } = node;
    const color = nodeColors[nodeId];
    const border = adjustHexBrightness(nodeColors[node.NodeId], 1.1);
    return {
      label: name,
      key: nodeId,
      color,
      border,
    };
  });
  const orderedNodeIds = selectedNodes.map((node) => node.NodeId);

  const sourceData: TimestreamNodePPM[] = [];
  const labels: string[] = [];
  const chartDatasets: Record<string, DeviceData> = {};
  for (const chartEle of chartElements) {
    chartDatasets[chartEle.key] = { ch4: [], voc: [], co2: [] };
  }

  for (const ele of data) {
    const roundedTimestamp = adjustTo30sBasis(ele.Timestamp);
    sourceData.push({ ...ele, Timestamp: roundedTimestamp });
    labels.push(roundedTimestamp);

    for (const chartEle of chartElements) {
      let ch4Val: number | null = null;
      let vocVal: number | null = null;
      let co2Val: number | null = null;

      if (ele.NodeId === chartEle.key) {
        if (ele.gas1D != null) {
          ch4Val = Number(ele.gas1D) > 0 ? ele.gas1D : null;
        }
        if (ele.gas2D != null) {
          vocVal = Number(ele.gas2D) > 0 ? ele.gas2D : null;
        }
        if (ele.co2 != null) {
          co2Val = Number(ele.co2) > 0 ? ele.co2 : null;
        }
      }

      chartDatasets[chartEle.key].ch4.push(ch4Val);
      chartDatasets[chartEle.key].voc.push(vocVal);
      chartDatasets[chartEle.key].co2.push(co2Val);
    }
  }

  const [xRange, setXRange] = useState({
    min: new Date(chartTimerange.startTimestamp).getTime(),
    max: new Date(chartTimerange.endTimestamp).getTime(),
  });

  const handleZoom = (chart: ChartJS): void => {
    const { min, max } = chart.scales.x;
    setXRange({ min, max });

    const newStartTimestamp = getTimestampFromMilliseconds(min, timezone);
    const newEndTimestamp = getTimestampFromMilliseconds(max, timezone);
    handleSetChartTimerange(newStartTimestamp, newEndTimestamp);
  };

  const ppmData = {
    labels,
    datasets: chartElements.map((element) => {
      return {
        label: `${element.label} CH4 (PPM)`,
        yAxisID: "y",
        data: chartDatasets[element.key].ch4,
        backgroundColor: element.color,
        borderColor: element.border,
        borderWidth: BORDER_WIDTH,
        spanGaps: true,
        radius: 0,
        hoverRadius: 0,
      };
    }),
  };

  const vocData = {
    labels,
    datasets: chartElements.map((element) => {
      return {
        label: `${element.label} VOC (PPM)`,
        yAxisID: "y1",
        data: chartDatasets[element.key].voc,
        backgroundColor: element.color,
        borderColor: element.border,
        borderWidth: BORDER_WIDTH,
        spanGaps: true,
        radius: 0,
        hoverRadius: 0,
        borderDash: doDisplayPPM && doDisplayPPM ? [2, 2] : [0, 0],
      };
    }),
  };

  const co2Data = {
    labels,
    datasets: chartElements.map((element) => {
      return {
        label: `${element.label} CO2 (PPM)`,
        yAxisID: "y2",
        data: chartDatasets[element.key].co2,
        backgroundColor: element.color,
        borderColor: element.border,
        borderWidth: BORDER_WIDTH,
        spanGaps: true,
        radius: 0,
        hoverRadius: 0,
      };
    }),
  };

  const ppmVocData = {
    labels,
    datasets: [...ppmData.datasets, ...vocData.datasets],
  };

  const getToolTipData = (dataIndex: number): TimestreamNodePPM[] => {
    const dataEle = sourceData[dataIndex];
    const nodesData = sourceData.filter(
      (item) => item.Timestamp === dataEle.Timestamp
    );

    const reducedNodesData: TimestreamNodePPM[] = Object.values(
      nodesData.reduce<{ [key: string]: TimestreamNodePPM }>((acc, obj) => {
        const nodeId = obj.NodeId;
        const isNewNode = (acc[nodeId] ?? null) === null;

        const currentCh4Value = obj.gas1D;
        const maxCh4Value = acc[nodeId]?.gas1D ?? "";

        const currentVocValue = obj?.gas2D ?? "";
        const maxVocValue = acc[nodeId]?.gas2D ?? "";

        if (isNewNode || maxCh4Value < currentCh4Value) {
          acc[nodeId] = {
            ...obj,
            gas1D: currentCh4Value,
            gas2D: acc[nodeId]?.gas2D ?? "",
          };
        }

        if (isNewNode || maxVocValue < currentVocValue) {
          acc[nodeId] = {
            ...obj,
            gas1D: acc[nodeId].gas1D,
            gas2D: currentVocValue,
          };
        }

        return acc;
      }, {})
    );

    const nodesDataIds = reducedNodesData.map(
      (item: TimestreamNodePPM) => item.NodeId
    );

    orderedNodeIds.forEach((nodeId) => {
      if (!nodesDataIds.includes(nodeId)) {
        reducedNodesData.push({
          ...emptyTimestreamNodePPMItem,
          NodeId: nodeId,
        });
      }
    });

    const result = reducedNodesData.sort((a, b) => {
      return (
        orderedNodeIds.indexOf(a.NodeId) - orderedNodeIds.indexOf(b.NodeId)
      );
    });

    return result;
  };

  let timeUnit = "day";
  if (chart != null) {
    const min = dayjs(chartTimerange.startTimestamp);
    const max = dayjs(chartTimerange.endTimestamp);
    const timeRangeHours = max.diff(min, "hour");
    timeUnit = timeRangeHours <= UNIT_HOURS_THRESHOLD ? "hour" : "day";
  }

  const tooltipCallback = (context: TooltipContext): string[] => {
    const isSingleQuant = doDisplayPPM || doDisplayVOC || doDisplayCO2;
    const isVoc = context.dataset.label?.includes("VOC") ?? false;

    if ((!doDisplayPPMVOC && isSingleQuant) || (doDisplayPPMVOC && isVoc)) {
      const dataIndex = context.dataIndex;
      const nodesData = getToolTipData(dataIndex);

      const displayTextArr = nodesData.map((item) => {
        let name =
          selectedNodes.find((node) => node.NodeId === item.NodeId)?.name ?? "";
        if (name.length > 10) {
          name = name.slice(0, 10) + "...";
        }

        const ch4Value =
          item.gas1D !== undefined && item.gas1D > 0
            ? Number(item.gas1D).toFixed(1)
            : "--";

        const vocValue =
          item.gas2D !== undefined && item.gas2D > 0
            ? Number(item.gas2D).toFixed(1)
            : "--";

        const co2Value =
          item.co2 !== undefined && item.co2 > 0
            ? Number(item.co2).toFixed(1)
            : "--";

        const ch4Text = ch4Value + " ppm";
        const vocText = vocValue + " ppm";
        const ch4VocText = ch4Value + " CH4 " + vocValue + " VOC ppm";
        const co2Text = co2Value + " ppm";

        if (doDisplayPPMVOC) {
          return name + "   " + ch4VocText;
        } else if (doDisplayPPM) {
          return name + "   " + ch4Text;
        } else if (doDisplayVOC) {
          return name + "   " + vocText;
        } else if (doDisplayCO2) {
          return name + "   " + co2Text;
        } else return "";
      });

      return displayTextArr;
    } else {
      return [];
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false as const,
    spanGaps: true as const,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      tooltip: {
        mode: "index" as const,
        caretSize: 0,
        displayColors: false,
        position: "customPositioner" as const,
        backgroundColor: "rgba(0,0,0)" as const,
        align: "start",
        callbacks: {
          label: tooltipCallback,
        },
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 24,
          weight: "bold" as const,
        },
      },
      datalabels: {
        display: true,
        align: "center",
        color: "black",
      },
      zoom: {
        zoom: {
          mode: "x" as const,
          drag: {
            enabled: true,
          },
          onZoomComplete: ({ chart }: { chart: ChartJS }) => handleZoom(chart),
        },
      },
    },
    scales: {
      x: {
        type: "time" as const,
        time: {
          unit: timeUnit as TimeSeriesUnit,
        },
        title: {
          display: true,
          text: `Timestamp (${timezone})`,
          font: {
            size: 20,
            weight: "bold" as const,
          },
        },
        grid: {
          drawOnChartArea: false,
        },
        min: xRange.min,
        max: xRange.max,
      },
      y: {
        type: "linear" as const,
        display: doDisplayPPM,
        title: {
          display: doDisplayPPM,
          text: "CH4 (PPM)",
          font: {
            size: 20,
            weight: "bold" as const,
          },
        },
        position: "left" as const,
        min: MIN_PPM,
        suggestedMax: LOWEST_YMAX_PPM,
      },
      y1: {
        type: "linear" as const,
        display: doDisplayVOC,
        title: {
          display: doDisplayVOC,
          text: doDisplayPPMVOC ? "VOC (PPM) - - -" : "VOC (PPM)",
          font: {
            size: 20,
            weight: "bold" as const,
          },
        },
        grid: {
          display: !doDisplayPPMVOC,
        },
        position: doDisplayPPMVOC ? ("right" as const) : ("left" as const),
        min: MIN_VOC,
        suggestedMax: LOWEST_YMAX_VOC,
      },
      y2: {
        type: "linear" as const,
        display: doDisplayCO2,
        title: {
          display: doDisplayCO2,
          text: "CO2 (PPM)",
          font: {
            size: 20,
            weight: "bold" as const,
          },
        },
        position: "left" as const,
        min: MIN_CO2,
        suggestedMax: LOWEST_YMAX_CO2,
      },
    },
  };

  const plugins: Plugin[] = [
    {
      id: "tooltipLine",
      afterDraw: (chart: ChartJS) => {
        if (
          chart?.tooltip !== null &&
          chart.tooltip?.caretX !== undefined &&
          chart.scales?.y !== undefined
        ) {
          const { ctx } = chart;
          const { caretX } = chart.tooltip;
          const topY = chart.scales.y.top;
          const bottomY = chart.scales.y.bottom;

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(caretX - tooltipOffset, topY);
          ctx.lineTo(caretX - tooltipOffset, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "#000000";
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ];

  useEffect(() => {
    setXRange({
      min: new Date(chartTimerange.startTimestamp).getTime(),
      max: new Date(chartTimerange.endTimestamp).getTime(),
    });
  }, [resetChartKey, data]);

  const chartData = doDisplayCO2
    ? co2Data
    : doDisplayPPMVOC
      ? ppmVocData
      : doDisplayVOC
        ? vocData
        : ppmData;

  return (
    <div className={styles.ChartWrapper}>
      <Line
        id={"chart-site-stats"}
        options={options}
        data={chartData}
        plugins={plugins}
      />
    </div>
  );
};

export default NodePPMChart;
