import { Modal, Select, Switch, Table, Tooltip } from "antd";
import NodeInfo from "../NodeInfo/NodeInfo";
import { FC } from "react";
import styles from "./styles.module.scss";
import { NodeData } from "../../store/statusChecks/types";
import { ColumnsType } from "antd/es/table";

interface OrganizationOptions {
  key: string | number;
  label: string;
  value: string;
}

interface StatusChecksProps {
  showNodeInfo: boolean;
  selectedNodeId: string;
  onClickCancelNodeInfoModal: () => void;
  onClickOkNodeInfoModal: () => void;
  selectedSiteName: string | null;
  userEmail: string;
  organizationOptions: OrganizationOptions[];
  onChangeOrg: (newValue: string) => void;
  onSwitchShowPassingNodes: (newVal: boolean) => void;
  onSwitchShowInactiveNodes: (newVal: boolean) => void;
  hoursSinceStatusCheckMessage: string;
  columns: ColumnsType<NodeData>;
  filteredDevices: NodeData[];
  isLoading: boolean;
}

const StatusChecks: FC<StatusChecksProps> = ({
  showNodeInfo,
  selectedNodeId,
  onClickCancelNodeInfoModal,
  onClickOkNodeInfoModal,
  selectedSiteName,
  userEmail,
  organizationOptions,
  onChangeOrg,
  onSwitchShowPassingNodes,
  onSwitchShowInactiveNodes,
  hoursSinceStatusCheckMessage,
  columns,
  filteredDevices,
  isLoading,
}) => {
  return (
    <div style={{ width: "99%" }}>
      <Modal
        centered
        open={showNodeInfo}
        title={
          <span
            className={styles.NodeInfoModalTitle}
          >{`Node ${selectedNodeId}`}</span>
        }
        onCancel={onClickCancelNodeInfoModal}
        onOk={onClickOkNodeInfoModal}
        width="90vw"
        footer={null}
      >
        <NodeInfo
          nodeId={selectedNodeId}
          siteName={selectedSiteName}
          userEmail={userEmail}
        />
      </Modal>
      <div className={styles.HeaderWrapper}>
        <div className={styles.OrgsDiv}>
          <Tooltip title="Filter by organizations">
            <Select
              className={styles.OrgsSelect}
              options={organizationOptions}
              allowClear
              onChange={(name: string) => onChangeOrg(name)}
              placeholder="Filter by organizations"
            />
          </Tooltip>
        </div>
        <div className={styles.SwitchWrapper}>
          <p className={styles.SwitchText}>Passing Nodes:</p>
          <Switch onChange={(isOn) => onSwitchShowPassingNodes(isOn)} />
        </div>
        <div className={styles.SwitchWrapper}>
          <p className={styles.SwitchText}>Inactive Nodes:</p>
          <Switch onChange={(isOn) => onSwitchShowInactiveNodes(isOn)} />
        </div>
        <div className={styles.MessageWrapper}>
          <em>{hoursSinceStatusCheckMessage}</em>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={filteredDevices}
        loading={isLoading}
        pagination={{ defaultPageSize: 50 }}
        showSorterTooltip={false}
      />
    </div>
  );
};

export default StatusChecks;
