import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { Grid } from "../../pages/SiteMapv2/types";

interface GetSiteGridQueryPayload {
  siteId: string;
  timestamp?: string;
}

export const siteGridsApi = createApi({
  reducerPath: "siteGridsApi",
  baseQuery,
  tagTypes: ["SiteGrids"],
  endpoints: (builder) => ({
    getSiteGrids: builder.query<Grid, GetSiteGridQueryPayload>({
      query: (payload) => {
        const { siteId, timestamp } = payload;
        if (timestamp !== undefined) {
          const queryParams = new URLSearchParams();
          queryParams.append("timestamp", timestamp);
          const queryString = queryParams.toString();
          return `siteGrids/${siteId}?${queryString}`;
        } else {
          return `siteGrids/${siteId}`;
        }
      },
      providesTags: ["SiteGrids"],
    }),
    createSiteGrids: builder.mutation<null, { grids: Grid[]; siteId: string }>({
      query: (payload) => ({
        url: `siteGrids/${payload.siteId}`,
        method: "POST",
        body: payload.grids,
      }),
      invalidatesTags: ["SiteGrids"],
    }),
  }),
});

export const { useGetSiteGridsQuery, useCreateSiteGridsMutation } =
  siteGridsApi;
