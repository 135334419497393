import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setGoBackRoute, setPageTitle } from "../../../store/ui/slice";
import { Button, Form, Input, Radio } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useNavigate } from "react-router-dom";
import { FormValues } from "./types";
import { currentOrganizationSelector } from "../../../store/organizations/selector";
import { useUpdateOrganizationMutation } from "../../../store/organizations/api";
import { APIResponse } from "../NewOrganization/types";
import { setCurrentOrganization } from "../../../store/organizations/slice";

const RadioGroup = Radio.Group;

const EditOrganization: FC = () => {
  const currentOrg = useAppSelector(currentOrganizationSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [updateOrganization] = useUpdateOrganizationMutation();

  let orgName = "";
  let orgCountry = "";
  let orgId = "";
  let type = "Client";

  if (currentOrg !== null) {
    orgName = currentOrg.name;
    orgCountry = currentOrg.country;
    orgId = currentOrg.OrgId;
    type = currentOrg.type !== undefined ? currentOrg.type : "Client";
  }

  const onFinish = (values: FormValues): void => {
    updateOrganization({
      OrgId: orgId,
      name: values.name,
      country: values.country,
      type: values.type
    })
      .unwrap()
      .then((response: APIResponse | null) => {
        if (response !== null && response.Message === "SUCCESS") {
          window.alert("Organization Saved Successfully");
          dispatch(
            setCurrentOrganization({
              ...values,
              OrgId: orgId,
            })
          );
          dispatch(setPageTitle(`Edit Organization - ${values.name}`));
        }
      })
      .catch((err) => console.log(err));
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity): void => {
    console.log("Failed:", errorInfo);
  };

  const onCancel = (): void => {
    navigate("/organizations");
  };

  useEffect(() => {
    dispatch(setGoBackRoute("/Organizations"));
    dispatch(setPageTitle(`Edit Organization - ${orgName}`));
  }, []);

  return (
    <div>
      <Form
        name="basic"
        initialValues={{ name: orgName, country: orgCountry, type }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        wrapperCol={{ span: 6 }}
      >
        <Form.Item
          label="Organization Name"
          name="name"
          rules={[
            { required: true, message: "Please input the organization name" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Country"
          name="country"
          rules={[
            {
              required: true,
              message: "Please input the organization country",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Type"
          name="type"
          rules={[
            { required: true, message: "Please input the organization type" },
          ]}
        >
          <RadioGroup>
            <Radio value={"Client"}>Client</Radio>
            <Radio value={"Partner"}>Partner</Radio>
          </RadioGroup>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
            Submit
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditOrganization;
