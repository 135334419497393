import { Modal, Checkbox, Input, Row, Col, Form, Space, Button } from "antd";
import { FC } from "react";
import { UserModalProps } from "./types";
import styles from "./styles.module.scss";

const stylesLocal = {
  padding10: {
    padding: 10,
  },
};

const UserModal: FC<UserModalProps> = ({
  organizations,
  edit,
  onCreateUser,
  onClosePanel,
  form,
  open,
  onFinishEdit,
}) => {
  const title = edit ? "Edit User" : "Create User";
  return (
    <Modal
      title={title}
      open={open}
      onCancel={onClosePanel}
      styles={{ body: stylesLocal.padding10 }}
      width={800}
      footer={null}
    >
      <Row>
        <Col span={24} style={stylesLocal.padding10}>
          <Form
            layout="vertical"
            onFinish={edit ? onFinishEdit : onCreateUser}
            form={form}
          >
            <Form.Item name="isAdmin" valuePropName="checked">
              <Checkbox>
                <b>Earthview Admin</b>
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="userName"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Email is required" }]}
            >
              <Input type="email" placeholder="Email" disabled={edit} />
            </Form.Item>
            {edit ? null : (
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Passoword is required" }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            )}
            <h2>Organizations</h2>
            <div className={styles.OrganizationsWrapper}>
              <Form.Item name="organizations">
                <Checkbox.Group
                  options={organizations.map((org) => {
                    return { label: org.name, value: org.OrgId };
                  })}
                />
              </Form.Item>
            </div>
            <br />

            <Space>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
              <Button onClick={onClosePanel}>Cancel</Button>
            </Space>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default UserModal;
