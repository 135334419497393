import { getCellMatrix, removeGrid } from "./mapApi";

export const applyMapLayers = (
  map,
  Maps,
  siteGrid,
  onClickCallback,
  showGridLayer,
  showActiveGridCells,
  additionalHighlightedCells,
  isMultiGrid
) => {
  if (map === null) {
    map = window.map;
  }
  if (Maps === null) {
    Maps = window.Microsoft.Maps;
  }

  const defaultLineColor = new Maps.Color(255, 0, 0, 0);
  const defaultCellColor = new Maps.Color(100, 140, 140, 140);
  const transparentFill = new Maps.Color(0, 0, 0, 0);
  const activeCellFill = new Maps.Color(100, 82, 196, 26);
  const activeCellLineColor = new Maps.Color(255, 255, 255, 255);

  removeGrid(map, Maps);

  if (isMultiGrid) {
    siteGrid.forEach((sg) => {
      let gMatrix = getCellMatrix(sg);
      gMatrix = gMatrix.map((cell) => {
        return {
          ...cell,
          fillColor: transparentFill,
          strokeColor: transparentFill
        };
      });

      if (showGridLayer) {
        gMatrix = gMatrix.map((cell) => {
          return {
            ...cell,
            fillColor: defaultCellColor,
            strokeColor: defaultLineColor
          };
        });
      }

      if (showActiveGridCells) {
        gMatrix = gMatrix.map((cell) => {
          return {
            ...cell,
            fillColor: cell.isActive ? activeCellFill : cell.fillColor
          };
        });
      }
      if (additionalHighlightedCells.length > 0) {
        additionalHighlightedCells.forEach((_cell) => {
          const indexInGMatrix = gMatrix.findIndex(
            (cell) =>
              cell.cellId === _cell.cellId && _cell.gridId === sg.Timestamp
          );
          if (indexInGMatrix !== -1) {
            const gMatrixItem = gMatrix[indexInGMatrix];
            gMatrixItem.fillColor = _cell.fillColor;
            if (gMatrixItem.isActive) {
              gMatrixItem.strokeColor = activeCellLineColor;
            }

            gMatrix.splice(indexInGMatrix, 1);
            gMatrix.push(gMatrixItem);
          }
        });
      }
      gMatrix.sort((a, b) => {
        return a.isActive === b.isActive ? 0 : a.isActive ? 1 : -1;
      });
      drawSiteLayers(
        map,
        Maps,
        gMatrix,
        sg,
        onClickCallback,
        true,
        sg.Timestamp
      );
    });
  } else {
    let gMatrix = getCellMatrix(siteGrid);

    gMatrix = gMatrix.map((cell) => {
      return {
        ...cell,
        fillColor: transparentFill,
        strokeColor: transparentFill
      };
    });

    if (showGridLayer) {
      gMatrix = gMatrix.map((cell) => {
        return {
          ...cell,
          fillColor: defaultCellColor,
          strokeColor: defaultLineColor
        };
      });
    }

    if (showActiveGridCells) {
      gMatrix = gMatrix.map((cell) => {
        return {
          ...cell,
          fillColor: cell.isActive ? activeCellFill : cell.fillColor
        };
      });
    }

    additionalHighlightedCells.forEach((_cell) => {
      const indexInGMatrix = gMatrix.findIndex(
        (cell) => cell.cellId === _cell.cellId
      );
      if (indexInGMatrix !== -1) {
        const gMatrixItem = gMatrix[indexInGMatrix];
        gMatrixItem.fillColor = _cell.fillColor;
        if (gMatrixItem.isActive) {
          gMatrixItem.strokeColor = activeCellLineColor;
        }

        // move GPAQS item to back of cell matrix so it is rendered last
        gMatrix.splice(indexInGMatrix, 1);
        gMatrix.push(gMatrixItem);
      }
    });

    // draw active cells last so borders are rendered properly
    gMatrix.sort((a, b) => {
      return a.isActive === b.isActive ? 0 : a.isActive ? 1 : -1;
    });
    drawSiteLayers(map, Maps, gMatrix, siteGrid, onClickCallback);
  }
};

export const drawSiteLayers = (
  map,
  Maps,
  gMatrix,
  siteGrid,
  onClickCallback,
  isMultiGrid,
  gridId
) => {
  const { minLong, maxLong, minLat, maxLat, gridX, gridY } = siteGrid;
  const cellWidth = (maxLong - minLong) / gridX;
  const cellHeight = (maxLat - minLat) / gridY;
  gMatrix.forEach((cell) => {
    const { x, y, fillColor, strokeColor } = cell;

    const cellMinLong = minLong + (x - 1) * cellWidth;
    const cellMaxLong = minLong + x * cellWidth;
    const cellMinLat = minLat + (y - 1) * cellHeight;
    const cellMaxLat = minLat + y * cellHeight;

    const cellRectangle = new Maps.Polygon(
      [
        new Maps.Location(cellMinLat, cellMinLong),
        new Maps.Location(cellMinLat, cellMaxLong),
        new Maps.Location(cellMaxLat, cellMaxLong),
        new Maps.Location(cellMaxLat, cellMinLong)
      ],
      {
        strokeColor,
        fillColor
      }
    );

    cellRectangle.x = x;
    cellRectangle.y = y;

    cellRectangle.cellId = cell.cellId;
    cellRectangle.isActive = cell.isActive;

    if (isMultiGrid) {
      cellRectangle.gridId = gridId;
    }

    map.entities.push(cellRectangle);
    if (onClickCallback) {
      Maps.Events.addHandler(cellRectangle, "click", onClickCallback);
    }
  });
};
