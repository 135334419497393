import { FC } from "react";
import { Button, Form } from "antd";
import SiteInfo from "./SiteInfo/SiteInfo";
import SiteConfig from "./SiteConfig/SiteConfig";
import styles from "./styles.module.scss";
import { SiteFormProps } from "./types";

const SiteForm: FC<SiteFormProps> = ({
  onFinish,
  onFinishFailed,
  onCancel,
  initialValues,
  edit = false,
  formKey = 0,
}) => {
  return (
    <Form
      name="basic"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 10 }}
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      key={formKey}
    >
      <SiteInfo />
      <SiteConfig />
      <Form.Item>
        <div className={styles.FormButtons}>
          {edit ? null : (
            <Button onClick={onCancel} className={styles.CancelBtn}>
              Cancel
            </Button>
          )}
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default SiteForm;
