import { NodeConfig } from "../../store/nodeConfig/types";
import {
  formatISOString,
  utcTimestampToLocal,
} from "../../helpers/dateTimeHelpers";
import { Node } from "../../store/nodes/types";
import { NodeLocationHistory } from "../../store/nodeLocationHistory/types";
import { NodePPM } from "../../store/nodesPPM/types";
import { NodeReport } from "../../store/nodeReports/types";
import { TableData, ConfigTableData, NodeConfigInputTypes } from "./types";

const NA = "N/A";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const testText = "1/1/2024 00:00:00 America/Denver";

export const getNodeDetailsTableData = (
  nodeObj: Node,
  lastNodeLocation: NodeLocationHistory,
  lastNodePPMRead: NodePPM,
  test: boolean
): TableData[] => {
  const lastReadTimestampUTC = lastNodePPMRead?.Timestamp ?? NA;
  const lastReadLocalDate = utcTimestampToLocal(lastReadTimestampUTC);
  let lastReadTimestampLocal =
    lastReadTimestampUTC !== NA && lastReadLocalDate !== null
      ? lastReadLocalDate + " " + timeZone
      : NA;

  const lastLocationTimestampUTC = lastNodeLocation?.Timestamp ?? NA;
  const lastLocationLocalDate = utcTimestampToLocal(lastLocationTimestampUTC);
  const lastLocationTimestampLocal =
    lastLocationTimestampUTC !== NA && lastLocationLocalDate !== null
      ? lastLocationLocalDate
      : NA;
  const lastLocationLat = lastNodeLocation?.latitude ?? "";
  const lastLocationLon = lastNodeLocation?.longitude ?? "";

  let lastLocationText = NA;
  if (lastLocationLocalDate !== null) {
    lastLocationText =
      lastLocationTimestampLocal +
      " " +
      timeZone +
      "\n@ " +
      `(${lastLocationLat}, ${lastLocationLon})`;
  }

  if (test) {
    lastReadTimestampLocal = testText;
    lastLocationText =
      testText + "\n@ " + `(${lastLocationLat}, ${lastLocationLon})`;
  }

  const nodeDetailsTableData: TableData[] = [
    { label: "Name", value: nodeObj?.name ?? NA },
    { label: "Mac Address", value: nodeObj?.MacAddress ?? NA },
    { label: "Elevation (ft)", value: nodeObj?.elevation ?? NA },
    { label: "Sample Height (ft)", value: nodeObj?.sampleHeight ?? NA },
    { label: "Latitude", value: nodeObj?.latitude ?? NA },
    { label: "Longitude", value: nodeObj?.longitude ?? NA },
    { label: "Signed Off By", value: nodeObj?.signedOffBy ?? NA },
    { label: "Signed Off On", value: nodeObj?.signedOffOn ?? NA },
    { label: "Last Read", value: lastReadTimestampLocal },
    { label: "Last Location", value: lastLocationText },
  ];

  return nodeDetailsTableData;
};

export const getLastReportTableData = (
  lastNodeReport: NodeReport,
  test: boolean
): TableData[] => {
  const lastReportTimestampUTC = lastNodeReport?.Timestamp ?? NA;
  const lastReportLocalDate = utcTimestampToLocal(lastReportTimestampUTC);
  let lastReportTimestampLocal =
    lastReportTimestampUTC !== NA && lastReportLocalDate !== null
      ? lastReportLocalDate + " " + timeZone
      : NA;

  if (test) {
    lastReportTimestampLocal = testText;
  }

  const lastReportTableData: TableData[] = [
    { label: "Last Report", value: lastReportTimestampLocal },
    { label: "ads1", value: lastNodeReport?.ads1 ?? NA },
    { label: "ads2", value: lastNodeReport?.ads2 ?? NA },
    { label: "biv", value: lastNodeReport?.biv ?? NA },
    { label: "but", value: lastNodeReport?.but ?? NA },
    { label: "cel", value: lastNodeReport?.cel ?? NA },
    { label: "dor", value: lastNodeReport?.dor ?? NA },
    { label: "gps", value: lastNodeReport?.gps ?? NA },
    { label: "hs", value: lastNodeReport?.hs ?? NA },
    { label: "mac", value: lastNodeReport?.mac ?? NA },
    { label: "mot", value: lastNodeReport?.mot ?? NA },
    { label: "ms", value: lastNodeReport?.ms ?? NA },
    { label: "neo", value: lastNodeReport?.neo ?? NA },
    { label: "op", value: lastNodeReport?.op ?? NA },
    { label: "rv", value: lastNodeReport?.rv ?? NA },
    { label: "sc3", value: lastNodeReport?.sc3 ?? NA },
    { label: "sd", value: lastNodeReport?.sd ?? NA },
    { label: "svt", value: lastNodeReport?.svt ?? NA },
    { label: "wd", value: lastNodeReport?.wd ?? NA },
    { label: "ws", value: lastNodeReport?.ws ?? NA },
  ];

  return lastReportTableData;
};

export const getNodeConfigTableData = (
  nodeConfig: NodeConfig,
  test: boolean
): ConfigTableData[] => {
  const networkMode =
    nodeConfig?.NetworkModeSelection === 0
      ? "Cellular"
      : nodeConfig?.NetworkModeSelection === 1
        ? "Wifi"
        : NA;

  let lastConfigUpdateTimestampLocal = NA;
  if (nodeConfig?.LastUpdateTimestamp !== undefined) {
    const lastConfigUpdateTimestampUTC = formatISOString(
      new Date(nodeConfig.LastUpdateTimestamp * 1000).toISOString()
    );
    const lastConfigDateLocal = utcTimestampToLocal(
      lastConfigUpdateTimestampUTC
    );
    if (lastConfigDateLocal !== null) {
      lastConfigUpdateTimestampLocal = lastConfigDateLocal + " " + timeZone;
    }
  }

  if (test) {
    lastConfigUpdateTimestampLocal = testText;
  }

  const nodeConfigTableData: ConfigTableData[] = [
    {
      key: "Tier1Interval",
      firmwareKey: "T1I",
      label: "T1 Interval (s)",
      value: nodeConfig?.Tier1Interval ?? NA,
      description: "Tier 1 sampling interval in seconds.",
      updateDetails: {
        inputType: NodeConfigInputTypes.DROPDOWN,
        acceptableValues: [
          { label: "Continuous", value: 0 },
          { label: "15 seconds", value: 15 },
          { label: "30 seconds", value: 30 },
          { label: "1 minute", value: 60 },
          { label: "2 minutes", value: 120 },
          { label: "5 minutes", value: 300 },
          { label: "10 minutes", value: 600 },
        ],
      },
    },
    {
      key: "Tier2Interval",
      firmwareKey: "T2I",
      label: "T2 Interval (s)",
      value: nodeConfig?.Tier2Interval ?? NA,
      description: "Tier 2 sampling interval in seconds.",
      updateDetails: {
        inputType: NodeConfigInputTypes.DROPDOWN,
        acceptableValues: [
          { label: "1 minute", value: 60 },
          { label: "2 minutes", value: 120 },
          { label: "5 minutes", value: 300 },
          { label: "10 minutes", value: 600 },
          { label: "15 minutes", value: 900 },
          { label: "20 minutes", value: 1200 },
          { label: "30 minutes", value: 1800 },
          { label: "1 hour", value: 3600 },
        ],
      },
    },
    {
      key: "Tier1SensorList",
      firmwareKey: "T1L",
      label: "Tier 1 Sensors",
      value: nodeConfig?.Tier1SensorList ?? NA,
      description: `Tier 1 sensor list, separated by comma. 
        Senors not available in this key are Tier 2. 
        See BluBird Protocol Specification for sensor names.`,
    },
    {
      key: "UnderVoltageLockoutVoltage",
      firmwareKey: "UVV",
      label: "UVL Voltage (mV)",
      value: nodeConfig?.UnderVoltageLockoutVoltage ?? NA,
      description:
        "Under voltage lockout mode voltage threshold in millivolts.",
    },
    {
      key: "UnderVoltageLockoutReleaseVoltage",
      firmwareKey: "UVR",
      label: "UVL Release Voltage (mV)",
      value: nodeConfig?.UnderVoltageLockoutReleaseVoltage ?? NA,
      description: `Under voltage lockout mode release voltage threshold in
      millivolts. If device enter UVLO mode, the battery voltage must reach 
      this level to get the system operational.`,
    },
    {
      key: "UnderVoltageLockoutInterval",
      firmwareKey: "UVL",
      label: "UVL Interval (s)",
      value: nodeConfig?.UnderVoltageLockoutInterval ?? NA,
      description: `Under voltage lockout mode timer interval in seconds.
        If in UVLO mode, device will periodically check the battery voltage
        with this interval.`,
    },
    {
      key: "WeatherStationModel",
      firmwareKey: "WSM",
      label: "Weather Station Model",
      value: nodeConfig?.WeatherStationModel ?? NA,
      description: `Weather station model selection. This has to be 0 (zero)
        for Sparkfun wind sensor, and 1 for Davis Instruments wind sensor.`,
      updateDetails: {
        inputType: NodeConfigInputTypes.DROPDOWN,
        acceptableValues: [
          { label: "Sparkfun", value: 0 },
          { label: "Davis", value: 1 },
        ],
      },
    },
    {
      key: "AccessPointName",
      firmwareKey: "APN",
      label: "Access Point Name",
      value: nodeConfig?.AccessPointName ?? NA,
      description: "Access point name for SIM card.",
    },
    {
      key: "SimCardId",
      firmwareKey: "SID",
      label: "SIM Card ID",
      value: nodeConfig?.SimCardId ?? NA,
      description: "SIM card unique ID. ICCID number.",
    },
    {
      key: "SimComImei",
      firmwareKey: "SII",
      label: "SIMCOM IMEI",
      value: nodeConfig?.SimComImei ?? NA,
      description: "SIMCOM module IMEI number.",
    },
    {
      key: "Network",
      firmwareKey: "NWK",
      label: "Network",
      value: nodeConfig?.Network ?? NA,
      description: "Network name of currently connected operator.",
    },
    {
      key: "WifiName",
      label: "Wifi Name",
      value: nodeConfig?.WifiName ?? NA,
      description: "WiFi Name",
      updateDetails: {
        inputType: NodeConfigInputTypes.TEXT,
      },
    },
    {
      key: "WifiPassword",
      label: "Wifi Password",
      value: nodeConfig?.WifiPassword ?? NA,
      description: "WiFi Password",
      updateDetails: {
        inputType: NodeConfigInputTypes.TEXT,
      },
    },
    {
      key: "NetworkModeSelection",
      firmwareKey: "ONW",
      label: "Network Mode",
      value: networkMode,
      description: `Network mode selection. Will be equal to 0 (zero) for
        cellular-only mode, and 1 for WiFi-only mode. If WiFi fails to connect,
        device will fall back to cellular.`,
      updateDetails: {
        inputType: NodeConfigInputTypes.DROPDOWN,
        acceptableValues: [
          { label: "Cellular", value: 0 },
          { label: "WiFi", value: 1 },
        ],
      },
    },
    {
      key: "PumpPowerPercentage",
      firmwareKey: "MPR",
      label: "Pump Motor Power (%)",
      value: nodeConfig?.PumpPowerPercentage ?? NA,
      description: `Sensor chamber pump motor power percentage value.
        Range: [0, 100]. Zero will disable the pump.`,
      updateDetails: {
        inputType: NodeConfigInputTypes.NUMERIC,
        acceptableRange: [0, 100],
      },
    },
    {
      key: "PumpRunningTime",
      firmwareKey: "MTO",
      label: "Pump Motor Running Time (s)",
      value: nodeConfig?.PumpRunningTime ?? NA,
      description: `Sensor chamber pump motor running time before sampling MOS
        sensors. In seconds.`,
      updateDetails: {
        inputType: NodeConfigInputTypes.NUMERIC,
        acceptableRange: [10, 30],
      },
    },
    {
      key: "LastUpdateTimestamp",
      firmwareKey: "",
      label: "Last Update",
      value: lastConfigUpdateTimestampLocal,
      description: "Last time device configuration was upated.",
    },
    {
      key: "Version",
      firmwareKey: "",
      label: "Firmware Version",
      value: nodeConfig?.Version ?? NA,
      description: "Firmware Version",
    },
  ];

  return nodeConfigTableData;
};
