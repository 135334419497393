import { addPushpins } from "../../../pages/OrganizationMap/mapApi";
import {
  applyMapLayersForDrone,
  removeDroneLayersGrid
} from "../../../pages/SiteMapv2/layerControllerForDroneImages";

export const initReferencePointClickCallback = (
  referenceId,
  setPoint,
  label,
  _oppositePushPin,
  oppositePushPinLabel
) => {
  const map = window.map;
  const Maps = window.Microsoft.Maps;
  referenceId.current = Maps.Events.addHandler(map, "click", (event) => {
    const point = new Maps.Point(event.getX(), event.getY());
    const location = map.tryPixelToLocation(point);
    setPoint(location);
    const pushPin = {
      center: {
        latitude: location.latitude,
        longitude: location.longitude
      },
      options: {
        title: label
      }
    };
    let oppositePushPin = null;
    if (_oppositePushPin) {
      oppositePushPin = {
        center: {
          latitude: _oppositePushPin.latitude,
          longitude: _oppositePushPin.longitude
        },
        options: {
          title: oppositePushPinLabel
        }
      };
    }
    addPushpins([oppositePushPin, pushPin]);
  });
};

export const removeClickHandler = (referenceId) => {
  window.Microsoft.Maps.Events.removeHandler(referenceId.current);
};

export const drawSiteGrid = (
  gMatrix,
  minLong,
  maxLong,
  minLat,
  maxLat,
  gridX,
  gridY,
  toggleCellActiveCallback = true,
  gridRef,
  requireDroneImage
) => {
  const map = window.map;
  const Maps = window.Microsoft.Maps;

  const lineColor = new Maps.Color(255, 0, 0, 0); // Black color with full opacity
  const inactiveColor = new Maps.Color(100, 140, 140, 140);
  const activeColor = new Maps.Color(100, 82, 196, 26);
  const transparentFill = new Maps.Color(0, 0, 0, 0);

  const cellWidth = (maxLong - minLong) / gridX;
  const cellHeight = (maxLat - minLat) / gridY;

  const thisGrid = gridRef && gridRef.current ? gridRef.current : gridRef;
  let wasHandlerClicked = false;

  gMatrix.forEach((cell) => {
    const { x, y, isActive } = cell;
    const cellMinLong = minLong + (x - 1) * cellWidth;
    const cellMaxLong = minLong + x * cellWidth;
    const cellMinLat = minLat + (y - 1) * cellHeight;
    const cellMaxLat = minLat + y * cellHeight;

    const cellRectangle = new Maps.Polygon(
      [
        new Maps.Location(cellMinLat, cellMinLong),
        new Maps.Location(cellMinLat, cellMaxLong),
        new Maps.Location(cellMaxLat, cellMaxLong),
        new Maps.Location(cellMaxLat, cellMinLong)
      ],
      {
        strokeColor: requireDroneImage ? transparentFill : lineColor,
        fillColor: requireDroneImage
          ? transparentFill
          : isActive
            ? activeColor
            : inactiveColor
      }
    );

    cellRectangle.x = x;
    cellRectangle.y = y;
    cellRectangle.isActive = isActive;
    cellRectangle.cellId = `(${x},${y})`;

    map.entities.push(cellRectangle);

    if (toggleCellActiveCallback) {
      Maps.Events.addHandler(
        cellRectangle,
        "click",
        (e) => {
          wasHandlerClicked = true;
          const thisGrid = gridRef.current;
          const newValue = !cellRectangle.isActive;
          cellRectangle.isActive = newValue;
          thisGrid[cellRectangle.cellId].isActive = newValue;
          if (newValue) {
            cellRectangle.setOptions({ fillColor: activeColor });
          } else {
            cellRectangle.setOptions({ fillColor: inactiveColor });
          }
          if (requireDroneImage) {
            applyMapLayersForDrone(map, Maps, thisGrid, null, true, true, []);
          }
        },
        { passive: true }
      );
    }
  });
  if (requireDroneImage && !wasHandlerClicked) {
    applyMapLayersForDrone(map, Maps, thisGrid, null, true, true, []);
  }
};

export const removeGrid = () => {
  removeDroneLayersGrid();
  const map = window.map;
  const Maps = window.Microsoft.Maps;
  for (let i = map.entities.getLength() - 1; i >= 0; i--) {
    const mapEntity = map.entities.get(i);
    if (mapEntity instanceof Maps.Polygon) {
      map.entities.removeAt(i);
    }
  }
};

export const getCellMatrix = (g) => {
  const gridObjectToArray = Object.entries(g)
    .filter(([key]) => /^\(\d+,\d+\)$/.test(key))
    .map(([key, value]) => ({
      cellId: key,
      x: parseInt(key.slice(1, key.indexOf(","))),
      y: parseInt(key.slice(key.indexOf(",") + 1, -1)),
      isActive: value.isActive,
      name: value.name ?? "",
      height: value.height ?? "",
      equipment: value.equipment ?? [""]
    }));

  return gridObjectToArray;
};
