import { FC } from "react";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { TestResult } from "../../store/nodeSignOff/types";
import { SignOffProps } from "./types";
import styles from "./styles.module.scss";

const testNameDictionary = {
  nodeExists: "Node Exists",
  locationExists: "Location",
  sendingReads: "Getting Reads",
  sendingReadsRegularly: "Reporting Regularly",
  validReads: "Read Validation",
  passingReport: "Hardware Tests",
  currentFirmware: "Firmware",
};

const descriptionToLocalTimestamp = (inputString: string): string | null => {
  const dateRegex =
    /(\d{1,2}\/\d{1,2}\/\d{4}, \d{1,2}:\d{2}:\d{2} [APMapm]+) \(UTC\)/;
  const match = inputString.match(dateRegex);

  if (match == null) {
    return inputString;
  }

  const dateString = match[1];
  const dateUTC = new Date(dateString);
  const dateLocal = new Date(
    dateUTC.getTime() - dateUTC.getTimezoneOffset() * 60000
  );
  const formattedDate = dateLocal.toLocaleString();

  if (formattedDate.includes("Invalid Date")) {
    return null;
  }

  const resultString = inputString.replace(dateRegex, formattedDate);

  return resultString;
};

const SignOff: FC<SignOffProps> = ({ signOffData }) => {
  const PassIcon = (): JSX.Element => {
    const style = { color: "green", fontSize: "24px" };
    return <CheckCircleFilled style={style} />;
  };

  const FailIcon = (): JSX.Element => {
    const style = { color: "red", fontSize: "24px" };
    return <CloseCircleFilled style={style} />;
  };

  const testData = Object.entries(signOffData);
  const listElements = testData.map(([key, data]: [string, TestResult]) => {
    let { description, array } = data;
    if (description.includes("UTC")) {
      description = descriptionToLocalTimestamp(description) ?? "";
    }
    return (
      <div key={key} className={styles.ListItem}>
        <div>{data.pass ? <PassIcon /> : <FailIcon />}</div>
        <div>
          <span style={{ whiteSpace: "nowrap" }}>
            <b>{testNameDictionary[key as keyof typeof testNameDictionary]}</b>
          </span>
        </div>
        <div>
          <span style={{ whiteSpace: "nowrap" }}>{String(description)}</span>
        </div>
        {(array != null) && array.length > 0 && (
            <ul>
              {array.map((x, index) => (
                <li key={index}>{x}</li>
              ))}
            </ul>
        )}
      </div>
    );
  });

  return <div>{listElements}</div>;
};

export default SignOff;
