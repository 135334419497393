import type { GlobalConfig } from "../store/globalConfig/types";

export enum QuantityUnit {
  MCF = "MCF",
  KG = "kg",
}

export enum RateUnit {
  MCF_DAY = "MCF/day",
  KG_H = "kg/h",
}

const NA = "N/A";

const logNoGlobalConfig = (): void => {
  console.log("error: globalConfig is undefined");
};

// convert MCF/h number to both MCF/day and kg/h string
// e.g. "3.96 MCF/day | 3.35 kg/h" (units optional)
export const mcfHrToMcfDayAndKgHrStr = (
  valueMcfh: number | string,
  globalConfig: GlobalConfig | undefined,
  showUnits = true,
  nDecimals = 2
): string => {
  if (globalConfig === undefined) {
    logNoGlobalConfig();
    return NA;
  }

  const mcfDayStr = mcfHrToMcfDayStr(valueMcfh, showUnits, nDecimals);
  const kgHrStr = mcfHrToKgHrStr(valueMcfh, globalConfig, showUnits, nDecimals);
  const result = `${mcfDayStr} | ${kgHrStr}`;
  return result;
};

// convert MCF/h number to MCF/day string
// e.g. "3.96 MCF/day" (units optional)
export const mcfHrToMcfDayStr = (
  valueMcfh: number | string,
  showUnits = true,
  nDecimals = 2
): string => {
  const value = mcfHrToMcfDay(valueMcfh);
  const valueRounded = value.toFixed(nDecimals);
  const result = showUnits
    ? `${valueRounded} ${RateUnit.MCF_DAY}`
    : valueRounded;
  return result;
};

// convert MCF/h number to kg/h string
// e.g. "3.35 kg/h" (units optional)
export const mcfHrToKgHrStr = (
  valueMcfh: number | string,
  globalConfig: GlobalConfig | undefined,
  showUnits = true,
  nDecimals = 2
): string => {
  if (globalConfig === undefined) {
    logNoGlobalConfig();
    return NA;
  }

  const value = mcfToKg(valueMcfh, globalConfig);
  const valueRounded = value.toFixed(nDecimals);
  const result = showUnits ? `${valueRounded} ${RateUnit.KG_H}` : valueRounded;
  return result;
};

// convert MCF/h number to specified units emission rate string
// e.g. "3.96 MCF/day" for MCF and "3.35 kg/h" for kg (units optional)
export const mcfHrToSiteUnitsStr = (
  valueMcf: number | string,
  siteUnits: QuantityUnit,
  globalConfig: GlobalConfig | undefined,
  showUnits = true
): string => {
  if (globalConfig === undefined) {
    logNoGlobalConfig();
    return NA;
  }

  switch (siteUnits) {
    case QuantityUnit.MCF:
      return mcfHrToMcfDayStr(valueMcf, showUnits);
    case QuantityUnit.KG:
      return mcfHrToKgHrStr(valueMcf, globalConfig, showUnits);
  }
};

// convert MCF/h number to specified units emission rate number
// (no rounding, no units string)
export const mcfHrToSiteUnits = (
  valueMcf: number | string,
  siteUnits: QuantityUnit,
  globalConfig: GlobalConfig | undefined
): number => {
  if (globalConfig === undefined) {
    logNoGlobalConfig();
    return 0;
  }

  switch (siteUnits) {
    case QuantityUnit.MCF:
      return mcfHrToMcfDay(valueMcf);
    case QuantityUnit.KG:
      return mcfToKg(valueMcf, globalConfig);
  }
};

// convert MCF number to specified units emission quantity string
// e.g. "0.38 MCF" for MCF and "7.82 kg" for kg (units optional)
export const mcfToSiteUnitsStr = (
  valueMcf: number | string,
  siteUnits: QuantityUnit,
  globalConfig: GlobalConfig | undefined,
  showUnits = true,
  decimals = 2
): string => {
  if (globalConfig === undefined) {
    logNoGlobalConfig();
    return NA;
  }

  const valueSiteUnits = mcfToSiteUnits(
    valueMcf,
    siteUnits,
    globalConfig
  ).toFixed(decimals);
  switch (siteUnits) {
    case QuantityUnit.MCF: {
      const result = showUnits
        ? `${valueSiteUnits} ${QuantityUnit.MCF}`
        : valueSiteUnits;
      return result;
    }
    case QuantityUnit.KG: {
      const result = showUnits
        ? `${valueSiteUnits} ${QuantityUnit.KG}`
        : valueSiteUnits;
      return result;
    }
  }
};

// convert MCF number to specified units emission quantity number
// (no rounding, no units string)
export const mcfToSiteUnits = (
  valueMcf: number | string,
  siteUnits: QuantityUnit,
  globalConfig: GlobalConfig | undefined
): number => {
  if (globalConfig === undefined) {
    logNoGlobalConfig();
    return 0;
  }

  switch (siteUnits) {
    case QuantityUnit.MCF:
      return Number(valueMcf);
    case QuantityUnit.KG:
      return mcfToKg(valueMcf, globalConfig);
  }
};

// convert MCF number to kg number - can also use for MCF/h to kg/h
// (no rounding, no units string)
export const mcfToKg = (
  valueMcf: number | string,
  globalConfig: GlobalConfig | undefined
): number => {
  if (globalConfig === undefined) {
    logNoGlobalConfig();
    return 0;
  }

  const result = Number(valueMcf) * Number(globalConfig.mcfToKg);
  return result;
};

// convert kg number to MCF number - can also use for kg/h to MCF/h
// (no rounding, no units string)
export const kgToMcf = (
  valueKg: number | string,
  globalConfig: GlobalConfig | undefined
): number => {
  if (globalConfig === undefined) {
    logNoGlobalConfig();
    return 0;
  }

  const result = Number(valueKg) / Number(globalConfig.mcfToKg);
  return result;
};

// convert MCF/h number to MCF/day number
// (no rounding, no units string)
export const mcfHrToMcfDay = (valueMcfh: number | string): number => {
  const result = Number(valueMcfh) * 24;
  return result;
};

// convert MCF/day number to MCF/h number
// (no rounding, no units string)
export const mcfDayToMcfHr = (valueMcfDay: number | string): number => {
  const result = Number(valueMcfDay) / 24;
  return result;
};
