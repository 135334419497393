import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import {
  FirmwareConfigType,
  FirmwareReleaseConfigUpdateResponse,
} from "./types";

const FIRMWARE_RELASE_VERSION_ID = "v1";

export const firmwareReleaseConfigApi = createApi({
  reducerPath: "firmwareReleaseConfigApi",
  baseQuery,
  tagTypes: ["FirmwareReleaseConfig"],
  endpoints: (builder) => ({
    getFirmwareReleaseConfig: builder.query<FirmwareConfigType, null>({
      query: () => `firmwareReleaseConfig/${FIRMWARE_RELASE_VERSION_ID}`,
      providesTags: ["FirmwareReleaseConfig"],
    }),
    updateFirmwareReleaseConfig: builder.mutation<
    FirmwareReleaseConfigUpdateResponse,
    FirmwareConfigType
    >({
      query: (payload: FirmwareConfigType) => {
        const { ConfigId } = payload;
        return {
          url: `firmwareReleaseConfig/${ConfigId}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["FirmwareReleaseConfig"],
    }),
  }),
});

export const {
  useGetFirmwareReleaseConfigQuery,
  useUpdateFirmwareReleaseConfigMutation,
} = firmwareReleaseConfigApi;
