import { Button, Select, Spin, Form } from "antd";
import {
  useGetFirmwareReleaseConfigQuery,
  useUpdateFirmwareReleaseConfigMutation,
} from "../../store/firmwareReleaseConfig/api";
import { FC, useEffect } from "react";
import { cities } from "./utils";
import {
  FirmwareConfigType,
  Location,
} from "../../store/firmwareReleaseConfig/types";
import BetaVersionConfig from "../BetaVersionConfig/BetaVersionConfig";

const { Option } = Select;

interface FormValues {
  releasedVersion: string;
  releaseBy: string;
  betaVersion: string;
  organizationsList: undefined | string[];
  locationsList: undefined | string[];
  nodesList: undefined | string[];
  sitesList: undefined | string[];
  maxRetry: number;
}

const FirmwareConfig: FC = () => {
  const [updateFirmwareReleaseConfig] =
    useUpdateFirmwareReleaseConfigMutation();
  const firmwareReleaseConfig = useGetFirmwareReleaseConfigQuery(null).data;
  const [form] = Form.useForm();

  let availableVersions: number[] = [];
  if (firmwareReleaseConfig?.availableVersions !== undefined) {
    availableVersions = firmwareReleaseConfig.availableVersions
      .map((folder: string) => Number(folder.replace(/\/$/, "")))
      .sort((a: number, b: number) => b - a);
  }

  useEffect(() => {
    if (firmwareReleaseConfig != null) {
      form.setFieldsValue({
        releasedVersion: firmwareReleaseConfig.releasedVersion,
        betaVersion: firmwareReleaseConfig.betaVersion,
        releaseBy: firmwareReleaseConfig.betaRelaseConfig.releaseBy,
        organizationsList:
          firmwareReleaseConfig.betaRelaseConfig.organizationsList,
        locationsList: firmwareReleaseConfig.betaRelaseConfig.locationsList.map(
          (location) => location.id
        ),
        maxRetry: firmwareReleaseConfig.maxRetry,
        nodesList: firmwareReleaseConfig.betaRelaseConfig.nodesList,
        sitesList: firmwareReleaseConfig.betaRelaseConfig.sitesList,
      });
    }
  }, [firmwareReleaseConfig, form]);

  const onSubmit = (): void => {
    form
      .validateFields()
      .then((values: FormValues) => {
        if (firmwareReleaseConfig !== undefined) {
          const locationsList: Location[] = [];
          values.locationsList?.forEach((location: string) => {
            const result: Location | undefined = cities.find(
              (city) => city.id === location
            );
            if (result !== undefined) {
              locationsList.push(result);
            }
          });
          const copyOfFirmwareConfig: FirmwareConfigType = {
            ...firmwareReleaseConfig,
            releasedVersion: values.releasedVersion,
            betaVersion: values.betaVersion,
            betaRelaseConfig: {
              ...firmwareReleaseConfig.betaRelaseConfig,
              releaseBy: values.releaseBy,
              organizationsList:
                values.organizationsList !== undefined
                  ? values.organizationsList
                  : [],
              locationsList,
              nodesList: values.nodesList !== undefined ? values.nodesList : [],
              sitesList: values.sitesList !== undefined ? values.sitesList : [],
            },
            maxRetry: values.maxRetry,
          };
          updateFirmwareReleaseConfig(copyOfFirmwareConfig)
            .unwrap()
            .then((result) => {
              console.log(result);
              window.alert("Release Config Updated");
            })
            .catch((error) => {
              window.alert("Error while updating the firmware config");
              console.log(error);
            });
        }
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
      });
  };

  return (
    <div style={{ marginTop: 20 }}>
      <h2>Manage Firmware</h2>
      {firmwareReleaseConfig === undefined ? (
        <Spin />
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          wrapperCol={{ span: 6 }}
        >
          <Form.Item label="Latest Version (for reference only)">
            <div>{availableVersions[0]}</div>
          </Form.Item>

          <Form.Item label="Released Version" name="releasedVersion">
            <Select>
              {availableVersions.map((version) => (
                <Option key={version} value={version}>
                  {version}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Beta Version" name="betaVersion">
            <Select>
              {availableVersions.map((version) => (
                <Option key={version} value={version}>
                  {version}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <h4>Beta Release Configuration:</h4>
          <BetaVersionConfig
            _releaseBy={firmwareReleaseConfig.betaRelaseConfig.releaseBy}
          />

          <Form.Item label="Maximum Download Retries" name="maxRetry">
            <Select>
              <Option value={10}>10</Option>
              <Option value={20}>20</Option>
              <Option value={30}>30</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default FirmwareConfig;
