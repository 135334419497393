import { FC } from "react";
import { Tag } from "antd";

import {
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

interface StatusTagProps {
  siteStatus: string;
  isActive: boolean;
}

const tagStyle = {
  fontWeight: 500,
  fontSize: "14px",
  opacity: 0.85,
  padding: "2px",
};

const StatusTag: FC<StatusTagProps> = ({ siteStatus, isActive }) => {
  if (isActive) {
    switch (siteStatus) {
      case "Alarm":
        return (
          <Tag style={tagStyle} color="red" icon={<CloseCircleOutlined />}>
            Alarm
          </Tag>
        );
      case "Alert":
        return (
          <Tag
            style={tagStyle}
            color="orange"
            icon={<ExclamationCircleOutlined />}
          >
            Alert
          </Tag>
        );
      case "High PPM":
        return (
          <Tag
            style={tagStyle}
            color="yellow"
            icon={<ExclamationCircleOutlined />}
          >
            High PPM
          </Tag>
        );
      default:
        return (
          <Tag style={tagStyle} color="green" icon={<CheckCircleOutlined />}>
            OK
          </Tag>
        );
    }
  } else {
    return <></>;
  }
};

export default StatusTag;
