import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Site, SitesState } from "./types";
import { PURGE } from "redux-persist";

const initialState: SitesState = {
  currentSite: null,
};

export const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    setCurrentSite: (state: SitesState, action: PayloadAction<Site | null>) => {
      state.currentSite = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState;
    });
  }
});

export const { setCurrentSite } = sitesSlice.actions;

export default sitesSlice.reducer;
