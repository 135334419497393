import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";

interface BackcalculateAlarmPayload {
  siteId: string;
  leakLocation: string;
  startTime: string;
  endTime: string;
}

interface BackcalculateAlarmResult {
  result: number;
}

export const backcalculateAlarms = createApi({
  reducerPath: "backcalculateAlarmsApi",
  baseQuery,
  tagTypes: ["BackcalculateAlarms"],
  endpoints: (builder) => ({
    backcalculateAlarm: builder.mutation<
    BackcalculateAlarmResult,
    BackcalculateAlarmPayload
    >({
      query: (payload) => {
        const { siteId } = payload;
        return {
          url: `backcalculateAlarm/${siteId}`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["BackcalculateAlarms"],
    }),
  }),
});

export const { useBackcalculateAlarmMutation } = backcalculateAlarms;
