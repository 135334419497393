import { applyMapLayersForDrone } from "../../../pages/SiteMapv2/layerControllerForDroneImages";
import { applyMapLayers } from "../../../pages/SiteMapv2/layerController";

export const applyMapLayersGridVisibility = (
  siteGrid,
  additionalHighlightedCells,
  requireDroneImage
) => {
  if (requireDroneImage) {
    applyMapLayersForDrone(
      window.map,
      window.Microsoft.Maps,
      siteGrid,
      null,
      true,
      false,
      additionalHighlightedCells
    );
  } else {
    applyMapLayers(
      null,
      null,
      siteGrid,
      null,
      true,
      false,
      additionalHighlightedCells,
      false
    );
  }
};
