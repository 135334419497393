const MIN_TO_MS = 60 * 1000; // convert minutes to milliseconds

export const timestampFormat = "YYYY-MM-DD HH:mm:ss";

export const formatISOString = (isoTimestamp: string): string => {
  return isoTimestamp.replace(/T|Z|\.\d+/g, " ");
};

export const generateTimestamp = (): string => {
  const timestamp = new Date();
  const isoTimestamp = timestamp
    .toISOString()
    .replace("T", " ")
    .replace("Z", "")
    .replace(/\.\d+/, "");

  return isoTimestamp;
};

export const addMinutes = (timestamp: string, minutes: number): string => {
  const originalDate = new Date(timestamp);
  const newDate = new Date(originalDate.getTime() + minutes * MIN_TO_MS);
  return new Date(newDate.getTime() - newDate.getTimezoneOffset() * MIN_TO_MS)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
};

export const subtractMinutes = (timestamp: string, minutes: number): string => {
  const originalDate = new Date(timestamp);
  const newDate = new Date(originalDate.getTime() - minutes * MIN_TO_MS);
  return new Date(newDate.getTime() - newDate.getTimezoneOffset() * MIN_TO_MS)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
};

export const localTimestampToUTC = (localTimestamp: string): string => {
  const localDate = new Date(localTimestamp);
  const utcDate = new Date(
    Date.UTC(
      localDate.getUTCFullYear(),
      localDate.getUTCMonth(),
      localDate.getUTCDate(),
      localDate.getUTCHours(),
      localDate.getUTCMinutes(),
      localDate.getUTCSeconds()
    )
  );

  return formatISOString(utcDate.toISOString());
};

export const utcTimestampToLocal = (utcTimestamp: string): string | null => {
  const dateUTC = new Date(utcTimestamp);
  const dateLocal = new Date(
    dateUTC.getTime() - dateUTC.getTimezoneOffset() * 60000
  );

  const result = dateLocal.toLocaleString();

  if (result.includes("Invalid Date")) {
    return null;
  }

  return result;
};
