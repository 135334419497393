import { FC, useState, ChangeEvent } from "react";
import {
  NodeConfigFormProps,
  ConfigTableData,
  NodeConfigInputTypes,
  LabeledValue,
} from "../types";
import { Table, Input, Select, InputNumber, Button, Modal } from "antd";
import styles from "../styles.module.scss";
import classNames from "classnames";

import { useUpdateNodeConfigMutation } from "../../../store/nodeConfig/api";
import { NodeConfig } from "../../../store/nodeConfig/types";

const NodeConfigForm: FC<NodeConfigFormProps> = ({
  nodeConfig,
  nodeConfigTableData,
  closeUpdateConfigModal,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [newNodeConfig, setNewNodeConfig] = useState({ ...nodeConfig });
  const updateNewNodeConfig = <K extends keyof NodeConfig>(
    key: K,
    value: NodeConfig[K]
  ): void => {
    const temp = { ...newNodeConfig };
    temp[key] = value;
    setNewNodeConfig(temp as NodeConfig);
  };

  const [updateNodeConfig] = useUpdateNodeConfigMutation();

  const NodeConfigInput = (data: ConfigTableData): JSX.Element => {
    const Default = <span>{data.value}</span>;

    if (data.updateDetails != null) {
      switch (data.updateDetails.inputType) {
        case NodeConfigInputTypes.DROPDOWN: {
          if (data.updateDetails.acceptableValues === undefined) {
            return Default;
          }
          const options = data.updateDetails.acceptableValues.map(
            (item: LabeledValue) => {
              return { label: item.label, value: item.value };
            }
          );
          return (
            <Select
              style={{ width: "100%" }}
              value={newNodeConfig[data.key]}
              options={options}
              onChange={(newValue) => {
                updateNewNodeConfig(data.key, newValue);
              }}
            />
          );
        }
        case NodeConfigInputTypes.NUMERIC: {
          if (
            data.updateDetails.acceptableRange === undefined ||
            data.updateDetails.acceptableRange.length !== 2
          ) {
            return Default;
          }
          const [min, max] = data.updateDetails.acceptableRange;
          return (
            <InputNumber
              value={newNodeConfig[data.key]}
              min={min}
              max={max}
              addonAfter={`[${min}, ${max}]`}
              onChange={(newValue) => {
                if (newValue != null) {
                  updateNewNodeConfig(data.key, newValue);
                }
              }}
            />
          );
        }
        case NodeConfigInputTypes.TEXT:
          return (
            <Input
              value={newNodeConfig[data.key]}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                updateNewNodeConfig(data.key, e.target.value);
              }}
            />
          );
        default:
          return Default;
      }
    }
    return Default;
  };

  const columns = [
    { dataIndex: "label", key: "label", className: styles.LabelColumn },
    {
      key: "value",
      render: (data: ConfigTableData) => NodeConfigInput(data),
    },
    { dataIndex: "description", key: "description" },
  ];

  const getRowClassName = (_: unknown, index: number): string => {
    return index % 2 === 0 ? styles.EvenRow : styles.OddRow;
  };

  const onClickSubmit = (): void => {
    setShowConfirmModal(true);
  };

  const onOkConfirmModal = (): void => {
    updateNodeConfig(newNodeConfig)
      .unwrap()
      .then((res) => {
        if (res.Message === "SUCCESS") {
          window.alert("Node configuration updated");
          closeUpdateConfigModal();
        } else {
          console.log("error:", res);
          window.alert("Error updating node configuration");
        }
      })
      .catch((e) => {
        console.log("error:", e);
        window.alert("Error updating node configuration");
      });
  };

  const onCancelConfirmModal = (): void => {
    setShowConfirmModal(false);
  };

  return (
    <div>
      <Modal
        centered
        title="Confirmation"
        open={showConfirmModal}
        onOk={onOkConfirmModal}
        onCancel={onCancelConfirmModal}
      >
        {"Are you sure you want to update this node's configuration?"}
      </Modal>
      <div className={classNames(styles.TableWrapper, styles.UpdateNodeConfig)}>
        <Table
          title={() => (
            <div className={styles.TableHeader}>Configurable Settings</div>
          )}
          dataSource={nodeConfigTableData.filter((item) => item.updateDetails)}
          columns={columns}
          rowClassName={getRowClassName}
          showHeader={false}
          pagination={false}
        />
        <Table
          title={() => (
            <div className={styles.TableHeader}>Nonconfigurable Settings</div>
          )}
          dataSource={nodeConfigTableData.filter(
            (item) => item.updateDetails == null
          )}
          columns={columns}
          rowClassName={getRowClassName}
          showHeader={false}
          pagination={false}
        />
      </div>
      <div className={styles.UpdateNodeConfigFooter}>
        <Button style={{ marginRight: 10 }} onClick={closeUpdateConfigModal}>
          Cancel
        </Button>
        <Button type="primary" onClick={onClickSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default NodeConfigForm;
