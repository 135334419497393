import { NodeDataType } from "./types";

export const calculateWindVelocity = (
  nodeData: NodeDataType[],
  x: number,
  y: number,
  zoom: number
): number[] => {
  let [xSpeed, ySpeed] = [0, 0];
  let orderedWeights: number[] = [];
  nodeData.forEach((nodeObj) => {
    const inverseDistance = distance(x, y, nodeObj.x, nodeObj.y) ** -1;
    const weight = Math.min(inverseDistance, 0.01);

    orderedWeights.push(weight);
  });

  const weightsSum = orderedWeights.reduce((sum, ele) => sum + ele, 0);
  orderedWeights = orderedWeights.map((weight) => weight / weightsSum);

  nodeData.forEach((nodeObj, index) => {
    const weight = orderedWeights[index];
    xSpeed += weight * nodeObj.xWind;
    ySpeed += weight * nodeObj.yWind;
  });

  let scale = 0.25;

  if (zoom <= 16) {
    scale = 0.15;
  }

  const result = [xSpeed, ySpeed].map((ele) => ele * scale);
  return result;
};

const distance = (x1: number, y1: number, x2: number, y2: number): number => {
  const delX = x2 - x1;
  const delY = y2 - y1;
  return Math.sqrt(delX ** 2 + delY ** 2);
};

export const polarToCartesian = (radius: number, theta: number): number[] => {
  const thetaReverse = theta - 180; // wind dir reported as degrees EAST from NORTH
  const rads = (thetaReverse * Math.PI) / 180;
  const x = radius * Math.cos(rads);
  const y = radius * Math.sin(rads);

  return [y, -x]; // flip from Meteorlogical frame (wind) to image frame (+x: right, +y: down)
};

export const polarToSvgRotation = (polarAngle: number): number => {
  return 360 - (((180 - polarAngle) + 360) % 360);
};
