import { NodeConfig } from "../../store/nodeConfig/types";

export interface NodeInfoParams {
  nodeId: string;
  siteName: string | null;
  userEmail: string;
  test?: boolean;
}

export interface TableData {
  label: string;
  value: string | number;
}

export interface ConfigTableData extends TableData {
  key: keyof NodeConfig;
  description: string;
  firmwareKey?: string;
  updateDetails?: UpdateDetails | undefined;
}

interface UpdateDetails {
  inputType: NodeConfigInputTypes;
  acceptableValues?: LabeledValue[];
  acceptableRange?: number[];
}

export interface LabeledValue {
  label: string;
  value: string | number;
}

export enum NodeConfigInputTypes {
  DROPDOWN = "dropdown",
  NUMERIC = "numeric",
  TEXT = "text",
}

export interface NodeConfigFormProps {
  nodeConfig: NodeConfig;
  nodeConfigTableData: ConfigTableData[];
  closeUpdateConfigModal: () => void;
}
