import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import type { Organization, GetOrganizationsResponse } from "./types";

export const organizationsApi = createApi({
  reducerPath: "organizationsApi",
  baseQuery,
  tagTypes: ["Organizations"],
  endpoints: (builder) => ({
    getOrganizations: builder.query<Organization[], null>({
      query: () => "organizations",
      transformResponse: (returnValue: GetOrganizationsResponse) => {
        return returnValue.organizations;
      },
      providesTags: ["Organizations"],
    }),
    createOrganization: builder.mutation<null, Organization>({
      query: (payload) => ({
        url: "organization",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Organizations"],
    }),
    updateOrganization: builder.mutation<null, Organization>({
      query: (payload) => ({
        url: `organization/${payload.OrgId}`,
        method: "PUT",
        body: {
          name: payload.name,
          country: payload.country,
          type: payload.type,
        },
      }),
      invalidatesTags: ["Organizations"],
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useLazyGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
} = organizationsApi;
