import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { SideBarElement, UIState } from "./types";
import { PURGE } from "redux-persist";

const initialState: UIState = {
  pageTitle: "",
  goBackRoute: "",
  showSideBar: true,
  sideBarElements: [],
};

export const uiSlice: Slice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setPageTitle: (state: UIState, action: PayloadAction<string>) => {
      state.pageTitle = action.payload;
    },
    setGoBackRoute: (state: UIState, action: PayloadAction<string>) => {
      state.goBackRoute = action.payload;
    },
    toggleShowSideBar: (state: UIState, action: PayloadAction<boolean>) => {
      state.showSideBar = action.payload;
    },
    setSideBarElements: (
      state: UIState,
      action: PayloadAction<SideBarElement[]>
    ) => {
      state.sideBarElements = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState;
    });
  }
});

export const { setPageTitle, setGoBackRoute, toggleShowSideBar, setSideBarElements } =
  uiSlice.actions;

export default uiSlice.reducer;
