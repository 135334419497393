import { FC, useEffect, useState } from "react";
import { Button, DatePicker, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { ReloadOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../store/hooks";
import {
  useCreateSiteBaselineMutation,
  siteBaselinesApi,
} from "../../store/siteBaselines/api";
import { currentSiteSelector } from "../../store/sites/selector";
import { SiteBaseline as SiteBaselineType } from "../../store/siteBaselines/types";
import {
  mcfHrToMcfDay,
  mcfToKg,
  RateUnit,
} from "../../helpers/unitsHelpers";
import { formatISOString } from "../../helpers/dateTimeHelpers";
import {
  START_OF_DAY_TIMESTAMP_FORMAT,
  DATE_FORMAT,
} from "../../helpers/dayjsHelpers";
import dayjs, { Dayjs } from "dayjs";
import styles from "./styles.module.scss";
import { SiteBaselinesProps } from "./types";
import { useGetGlobalConfigQuery } from "../../store/globalConfig/api";

const { RangePicker } = DatePicker;

const EPA_REGULATIONS_LINK =
  "https://www.ecfr.gov/current/title-40/chapter-I/subchapter-C/part-60/subpart-OOOOb/section-60.5398b#p-60.5398b(c)(5)";

const TIMERANGE_LENGTH_DAYS = 30; // EPA regulation

const SiteBaseline: FC<SiteBaselinesProps> = ({ testSiteBaselines = null }) => {
  const globalConfig = useGetGlobalConfigQuery(null).data;
  const currentSite = useAppSelector(currentSiteSelector);
  const siteId = currentSite?.SiteId ?? "";
  let initialEndDate = dayjs();
  let initialStartDate = dayjs().subtract(TIMERANGE_LENGTH_DAYS, "day");
  if (testSiteBaselines !== null) {
    initialStartDate = dayjs("2024-09-07 00:00:00");
    initialEndDate = dayjs("2024-10-07 00:00:00");
  }

  const [startDayjs, setStartDayjs] = useState(initialStartDate);
  const [endDayjs, setEndDayjs] = useState(initialEndDate);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [isCreateBaselineSuccess, setIsCreateBaselineSuccess] = useState(false);
  const [createSiteBaseline] = useCreateSiteBaselineMutation();
  const [triggerGetSiteBaselines, siteBaselinesQueryResult] =
    siteBaselinesApi.endpoints.getAllSiteBaselines.useLazyQuery();

  let siteBaselines: SiteBaselineType[] = [];
  if (siteBaselinesQueryResult.data !== undefined) {
    siteBaselines = siteBaselinesQueryResult.data;
  }
  siteBaselines = siteBaselines
    .map((baselineObj, idx) => ({ ...baselineObj, key: idx }))
    .sort((a: SiteBaselineType, b: SiteBaselineType) => {
      const aStartDayjs = dayjs(a.startTimestamp);
      const bEndDayjs = dayjs(b.startTimestamp);

      if (aStartDayjs === bEndDayjs) {
        const aEndDayjs = dayjs(a.endTimestamp);
        const bEndDayjs = dayjs(b.endTimestamp);
        return bEndDayjs.diff(aEndDayjs);
      }
      return bEndDayjs.diff(aStartDayjs);
    });

  if (testSiteBaselines !== null) {
    siteBaselines = testSiteBaselines;
  }

  const Baseline = (props: {
    baselineValue: number; // MCF/day or kg/h
  }): JSX.Element => {
    const { baselineValue } = props;
    const roundedBaseline = Math.round(baselineValue * 100) / 100;
    return <span>{roundedBaseline}</span>;
  };

  const dbTimezoneToDate = (timestamp: string): string => {
    const formattedTimestamp = formatISOString(timestamp);
    const [date] = formattedTimestamp.split(" ");
    return date;
  };

  const columns: ColumnsType<SiteBaselineType> = [
    {
      title: "Start",
      key: "start",
      dataIndex: "startTimestamp",
      render: (timestamp: string) => {
        return <span>{dbTimezoneToDate(timestamp)}</span>;
      },
    },
    {
      title: "End",
      key: "end",
      dataIndex: "endTimestamp",
      render: (timestamp: string) => {
        return <span>{dbTimezoneToDate(timestamp)}</span>;
      },
    },
    {
      title: RateUnit.KG_H,
      key: "kgHr",
      dataIndex: "baselineMcfh",
      render: (baselineMcfh: number | null) => {
        if (baselineMcfh === null || globalConfig === undefined) {
          return <em>Pending</em>;
        }
        return <Baseline baselineValue={mcfToKg(baselineMcfh, globalConfig)} />;
      },
    },
    {
      title: RateUnit.MCF_DAY,
      key: "mcfDay",
      dataIndex: "baselineMcfh",
      render: (baselineMcfh: number) => {
        if (baselineMcfh === null || globalConfig === undefined) {
          return <em>Pending</em>;
        }
        return <Baseline baselineValue={mcfHrToMcfDay(baselineMcfh)} />;
      },
    },
  ];

  const onRangeChange = (
    dates: null | Array<Dayjs | null>,
    datesStrings: string[]
  ): void => {
    if (dates === null) {
      return;
    }

    let [newStartDayjs, newEndDayjs] = dates;
    if (newStartDayjs !== null && newEndDayjs !== null) {
      if (newStartDayjs !== startDayjs) {
        newEndDayjs = newStartDayjs.add(TIMERANGE_LENGTH_DAYS, "day");
      } else if (newEndDayjs !== endDayjs) {
        newStartDayjs = newEndDayjs.subtract(TIMERANGE_LENGTH_DAYS, "day");
      }
      setStartDayjs(newStartDayjs);
      setEndDayjs(newEndDayjs);
      setShowCalendar(false);
    }
  };

  const onClickReload = async (): Promise<void> => {
    await triggerGetSiteBaselines(siteId);
  };

  const getRowClassName = (item: SiteBaselineType, index: number): string => {
    if (siteBaselines.length === 1) {
      return styles.oddRow;
    }

    if (item.isActive) {
      return styles.activeRow;
    }

    return index % 2 === 0 ? styles.evenRow : styles.oddRow;
  };

  const onClickSubmit = (): void => {
    setShowConfirmModal(true);
  };

  const onClickOkConfirmModal = async (): Promise<void> => {
    const payload = {
      siteId,
      startTimestamp: startDayjs.format(START_OF_DAY_TIMESTAMP_FORMAT),
      endTimestamp: endDayjs.format(START_OF_DAY_TIMESTAMP_FORMAT),
    };
    await createSiteBaseline(payload)
      .unwrap()
      .then((response: { Message: string }) => {
        if (response.Message.includes("message sent")) {
          setIsCreateBaselineSuccess(true);
        }
        setShowConfirmModal(false);
        setShowResultModal(true);
      });
  };

  const onClickCancelConfirmModal = (): void => {
    setShowConfirmModal(false);
  };

  const onClickOkResultModal = (): void => {
    setShowResultModal(false);
    setIsCreateBaselineSuccess(false);
  };

  const onClickCancelResultModal = (): void => {
    setShowResultModal(false);
    setIsCreateBaselineSuccess(false);
  };

  const Description = (): JSX.Element => {
    return (
      <span>
        The <em>Site Baseline</em> refers to the typical methane emission rate
        of a facility (site) during normal operation. For example, a pad may
        emit an average of 0.75 {RateUnit.MCF_DAY} of methane.
        If Earthview detects emissions of 1.75{" "}
        {RateUnit.MCF_DAY}, we would report an emission event of
        1.0 {RateUnit.MCF_DAY}.
        <br />
        <br />
        As per{" "}
        <a href={EPA_REGULATIONS_LINK} target="_blank" rel="noreferrer">
          <u>Environmental Protection Agency regulations (§ 60.5398b)</u>
        </a>
        , this baseline must be established over a fixed 30-day period:
      </span>
    );
  };

  useEffect(() => {
    void triggerGetSiteBaselines(siteId);
  }, [siteId]);

  return (
    <div className={styles.form}>
      <Modal
        centered
        open={showConfirmModal}
        title={"Establish Site Baseline"}
        onOk={onClickOkConfirmModal}
        onCancel={onClickCancelConfirmModal}
      >
        Establish new baseline from {startDayjs.format(DATE_FORMAT)} to{" "}
        {endDayjs.format(DATE_FORMAT)}?
      </Modal>

      <Modal
        centered
        open={showResultModal}
        title={"Establish Site Baseline"}
        onOk={onClickOkResultModal}
        onCancel={onClickCancelResultModal}
      >
        {isCreateBaselineSuccess ? (
          <div>
            <span>Click refresh button to see new baseline emission rate.</span>
            <br />
            <span>The baseline calculation may take several seconds.</span>
          </div>
        ) : (
          <span>
            Error creating new site baseline. Please try again or contact
            info@earthview.io
          </span>
        )}
      </Modal>

      <div className={styles.body}>
        <div className={styles.section}>
          <div>
            <Description />
          </div>
          <div className={styles.selection}>
            <div className={styles.timeRangeSelection}>
              <div className={styles.marginTop4px}>
                <span className={styles.marginRight12px}>30-day period:</span>
              </div>
              <div className={styles.rangePicker}>
                <RangePicker
                  open={showCalendar}
                  value={[startDayjs, endDayjs]}
                  onCalendarChange={onRangeChange}
                  onOpenChange={(isOpen) => setShowCalendar(isOpen)}
                />
                <span>
                  <em>Start of first day to start of last day.</em>
                </span>
              </div>
            </div>
            <div className={styles.submitButton}>
              <Button type="primary" onClick={onClickSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.historyHeader}>
            <h2>Baseline History</h2>
            <Button
              type="primary"
              className={styles.refreshButton}
              icon={<ReloadOutlined />}
              onClick={onClickReload}
            />
          </div>
          <div>
            <Table
              columns={columns}
              dataSource={siteBaselines}
              rowClassName={getRowClassName}
              pagination={false}
              rowHoverable={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteBaseline;
