import { FC, useState } from "react";
import styles from "./styles.module.scss";
import { AlarmsTableProps } from "./types";
import { useGetGlobalConfigQuery } from "../../../store/globalConfig/api";
import { useAppSelector } from "../../../store/hooks";
import { currentSiteSelector } from "../../../store/sites/selector";
import { AlarmIncidentEnum } from "../../../store/orgAlarmIncidentsV2/types";
import { convertTimezone } from "../../../helpers/dayjsHelpers";
import {
  mcfHrToSiteUnitsStr,
  QuantityUnit,
} from "../../../helpers/unitsHelpers";
import { Pagination } from "antd";

const ANTD_BLUE = "#1677ff";

const PAGE_SIZE = 3;

const AlarmsTable: FC<AlarmsTableProps> = ({
  onSelectAlarmIncident,
  selectedAlarmId,
  alarmIncidents,
  timezone,
}) => {
  const globalConfig = useGetGlobalConfigQuery(null).data;
  const currentSite = useAppSelector(currentSiteSelector);
  const siteEmissionUnits =
    currentSite?.config.emissionUnits ?? QuantityUnit.MCF;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const incidentsOnCurrentPage = alarmIncidents.slice(startIndex, endIndex);

  const handleChangePage = (page: number): void => {
    setCurrentPage(page);
  };

  return (
    <div className={styles.TableWrapper}>
      <table className={styles.AlarmsTable}>
        <tbody>
          <tr>
            <th>Start Time</th>
            <th>Type</th>
            <th>Measure</th>
            <th>Alarm Status</th>
          </tr>

          {incidentsOnCurrentPage.map((incident, index) => {
            const start =
              incident.type === "highConcentrationAlarm"
                ? incident.Timestamp
                : incident?.most_likely_event?.Timestamp != null &&
                  incident?.most_likely_event?.Timestamp !== ""
                  ? incident.most_likely_event.Timestamp
                  : incident.Timestamp;
            const utcStartTime = convertTimezone(start, "UTC", timezone);
            const timeParts = utcStartTime.split(" ");
            const startTime = timeParts[1];
            let measureText = "N/A";
            if (
              incident.type === AlarmIncidentEnum.CONCENTRATION &&
              incident.ppm !== undefined
            ) {
              measureText = `${Math.round(incident.ppm)} ppm`;
            }
            if (
              incident.type === AlarmIncidentEnum.GPAQS &&
              incident.most_likely_event !== undefined
            ) {
              const rateMcfHr = incident.most_likely_event["avgRate(MCF/h)"];
              measureText = mcfHrToSiteUnitsStr(
                rateMcfHr,
                siteEmissionUnits,
                globalConfig
              );
            }
            return (
              <tr
                key={index}
                onClick={() => onSelectAlarmIncident(incident)}
                style={
                  selectedAlarmId === `${incident.Timestamp}-${incident.type}`
                    ? { border: `solid 2px ${ANTD_BLUE}` }
                    : {}
                }
              >
                <td>
                  {startTime} {timezone}
                </td>
                <td>
                  {incident.type === "highConcentrationAlarm"
                    ? "High PPM"
                    : incident.should_alarm
                      ? "Alarm"
                      : "Alert"}
                </td>
                <td>{measureText}</td>
                <td>
                  {incident.status !== undefined ? (
                    incident.status
                  ) : (
                    <div className={styles.Flex}>
                      Triage <div className={styles.blinkNotification}>!</div>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {alarmIncidents.length > 3 && (
        <Pagination
          current={currentPage}
          total={alarmIncidents.length}
          pageSize={PAGE_SIZE}
          onChange={handleChangePage}
          size="small"
          style={{ marginTop: 5 }}
        />
      )}
    </div>
  );
};

export default AlarmsTable;
