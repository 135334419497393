import { RootState } from "..";

export const currentUserSelector = (state: RootState): string | null =>
  state.auth.currentUser;

export const userIdSelector = (state: RootState): string => state.auth.userId;

export const tokenSelector = (state: RootState): string | null =>
  state.auth.currentUser;

export const isUserAuthenticatedSelector = (state: RootState): boolean =>
  state.auth.currentUser !== null && state.auth.token !== null;
