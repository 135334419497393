import { FC } from "react";
import { AlarmEvent } from "../../types";
import styles from "../styles.module.scss";
import classNames from "classnames";
import { convertTimezone } from "../../../../helpers/dayjsHelpers";
import {
  mcfHrToKgHrStr,
  mcfHrToMcfDayAndKgHrStr,
  mcfHrToMcfDayStr,
  RateUnit,
} from "../../../../helpers/unitsHelpers";
import { useGetGlobalConfigQuery } from "../../../../store/globalConfig/api";
import { Grid } from "../../../../pages/SiteMapv2/types";
import { Spin } from "antd";
import { AlarmAlertTableProps } from "../types";

export const leakLocationName = (
  grid: Grid,
  cellCoordinates: string
): string => {
  const cellDict = grid[cellCoordinates];
  if (typeof cellDict === "object" && cellDict !== null && "name" in cellDict) {
    return String(cellDict.name);
  } else {
    return `Cell: ${cellCoordinates}`;
  }
};

const AlarmAlertTable: FC<AlarmAlertTableProps> = ({
  alarmType,
  hits,
  stdvs,
  avg,
  recipients,
  mostLikelyLeakEvent,
  mostLikelyLeakEventII,
  timestamp,
  timezone,
  averageGpaqsEmissionRates,
  endTimeText,
  rollingAveragesConfig,
  siteGrid,
}) => {
  const globalConfig = useGetGlobalConfigQuery(null).data;

  const LeakRateWithError = (props: {
    leakEvent: AlarmEvent | undefined;
  }): JSX.Element => {
    const { leakEvent } = props;

    if (leakEvent === undefined) {
      return <></>;
    }

    const leakRateMcfHr = leakEvent["avgRate(MCF/h)"];
    const upperErrorMcfHr = parseFloat(leakEvent?.UpperErrorMcfh ?? "0");
    const lowerErrorMcfHr = parseFloat(leakEvent?.LowerErrorMcfh ?? "0");

    const leakRateMcfDayText = mcfHrToMcfDayStr(leakRateMcfHr, false);
    const upperErrorMcfDayText = mcfHrToMcfDayStr(upperErrorMcfHr, false);
    const lowerErrorMcfDayText = mcfHrToMcfDayStr(lowerErrorMcfHr, false);

    const leakRateKgHrText = mcfHrToKgHrStr(leakRateMcfHr, globalConfig, false);
    const upperErrorKgHrText = mcfHrToKgHrStr(
      upperErrorMcfHr,
      globalConfig,
      false
    );
    const lowerErrorKgHrText = mcfHrToKgHrStr(
      lowerErrorMcfHr,
      globalConfig,
      false
    );

    return (
      <div>
        <div className={styles.leakRate}>
          {leakRateMcfDayText}
          <div className={styles.uncertainty}>
            <div>+ {upperErrorMcfDayText}</div>
            <div>- {lowerErrorMcfDayText}</div>
          </div>
          {RateUnit.MCF_DAY}
        </div>
        <hr />
        <div className={styles.leakRate}>
          {leakRateKgHrText}
          <div className={styles.uncertainty}>
            <div>+ {upperErrorKgHrText}</div>
            <div>- {lowerErrorKgHrText}</div>
          </div>
          {RateUnit.KG_H}
        </div>
      </div>
    );
  };

  const AvgEmissionRate = (props: {
    rateMcfh: string | number;
  }): JSX.Element => {
    const { rateMcfh } = props;
    const rateMcfDayText = mcfHrToMcfDayStr(rateMcfh);
    const rateKgHrText = mcfHrToKgHrStr(rateMcfh, globalConfig);

    return (
      <div className={styles.noWrap}>
        {rateMcfDayText}
        <hr />
        {rateKgHrText}
      </div>
    );
  };

  return siteGrid === undefined ? (
    <Spin />
  ) : (
    <div className="alarm-alert-table">
      <h1>{alarmType}</h1>
      <p>
        * At least <b>{hits}</b> measurements, <b>{stdvs}</b> standard
        deviation(s) above <b>{mcfHrToMcfDayAndKgHrStr(avg, globalConfig)}</b>{" "}
        site average
      </p>
      <h2>Recipients</h2>
      <p>
        {recipients !== undefined &&
        recipients.length !== 0 &&
        typeof recipients !== "string"
          ? recipients.map((recipient) => recipient)
          : "N/A"}
      </p>
      <h2>Event Statistics</h2>
      {mostLikelyLeakEvent !== undefined ? (
        <table className="alarm-details-container-table ">
          <tr>
            <td>
              <div className={styles.LikelyLeakLocationWrapper}>
                1.
                {leakLocationName(
                  siteGrid,
                  mostLikelyLeakEvent.likelyLeakLocation
                )}
                <div
                  className={classNames(
                    styles.LeakLocationBox,
                    styles.LikelyLeakLocation1
                  )}
                />
              </div>
            </td>
            <td>
              <LeakRateWithError leakEvent={mostLikelyLeakEvent} />
            </td>
          </tr>
          {mostLikelyLeakEventII !== undefined ? (
            <tr>
              <td>
                <div className={styles.LikelyLeakLocationWrapper}>
                  2.
                  {leakLocationName(
                    siteGrid,
                    mostLikelyLeakEventII.likelyLeakLocation
                  )}
                  <div
                    className={classNames(
                      styles.LeakLocationBox,
                      styles.LikelyLeakLocation2
                    )}
                  />
                </div>
              </td>
              <td>
                <LeakRateWithError leakEvent={mostLikelyLeakEventII} />
              </td>
            </tr>
          ) : null}
          <tr>
            <td>
              <b>Start Time</b>
            </td>
            <td>
              {convertTimezone(timestamp, "UTC", timezone)} {timezone}
            </td>
          </tr>
          <tr>
            <td>
              <div className={styles.noWrap}>
                <b>End Time</b>
              </div>
            </td>
            <td>{endTimeText}</td>
          </tr>
        </table>
      ) : null}
      <table className="alarm-details-container-table "></table>
      <h2>Site Wide Rolling Averages</h2>
      <table className="alarm-details-container-table ">
        <tr>
          <th>Duration</th>
          <th>Average</th>
          <th>Standard Deviation</th>
        </tr>
        <tr
          className={classNames({ highlighted: rollingAveragesConfig === 1 })}
        >
          <td>7-Day</td>
          <td>
            {averageGpaqsEmissionRates !== undefined ? (
              <AvgEmissionRate
                rateMcfh={
                  averageGpaqsEmissionRates["7_days_average_emission_rates"]
                }
              />
            ) : null}
          </td>
          <td>
            {averageGpaqsEmissionRates !== undefined ? (
              <AvgEmissionRate
                rateMcfh={
                  averageGpaqsEmissionRates["7_days_average_standard_deviation"]
                }
              />
            ) : null}
          </td>
        </tr>
        <tr
          className={classNames({ highlighted: rollingAveragesConfig === 2 })}
        >
          <td>30-Day</td>
          <td>
            {averageGpaqsEmissionRates !== undefined ? (
              <AvgEmissionRate
                rateMcfh={
                  averageGpaqsEmissionRates["30_days_average_emission_rates"]
                }
              />
            ) : null}
          </td>
          <td>
            {averageGpaqsEmissionRates !== undefined ? (
              <AvgEmissionRate
                rateMcfh={
                  averageGpaqsEmissionRates[
                    "30_days_average_standard_deviation"
                  ]
                }
              />
            ) : null}
          </td>
        </tr>
        <tr
          className={classNames({ highlighted: rollingAveragesConfig === 3 })}
        >
          <td>90-Day</td>
          <td>
            {averageGpaqsEmissionRates !== undefined ? (
              <AvgEmissionRate
                rateMcfh={
                  averageGpaqsEmissionRates["90_days_average_emission_rates"]
                }
              />
            ) : null}
          </td>
          <td>
            {averageGpaqsEmissionRates !== undefined ? (
              <AvgEmissionRate
                rateMcfh={
                  averageGpaqsEmissionRates[
                    "90_days_average_standard_deviation"
                  ]
                }
              />
            ) : null}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default AlarmAlertTable;
