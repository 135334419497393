import { FC, useState, useEffect } from "react";
import { Form } from "antd";
import { useAppSelector } from "../../store/hooks";
import { currentSiteSelector } from "../../store/sites/selector";
import { currentOrganizationSelector } from "../../store/organizations/selector";
import {
  startTimestampSelector,
  endTimestampSelector,
} from "../../store/selections/selector";
import { dailyEmissionsApi } from "../../store/dailyEmissions/api";
import { pinewaveZdscadaApi } from "../../store/pinewaveZdscada/api";
import { DailyEmission } from "../../store/dailyEmissions/types";
import { PinewaveZdscada } from "../../store/pinewaveZdscada/types";
import { QuantityUnit } from "../../helpers/unitsHelpers";
import { getDurationHours } from "../../components/TimeRangePicker/helpers";
import { getDateString } from "../../helpers/dayjsHelpers";
import { subtractMinutes } from "../../helpers/dateTimeHelpers";
import DailyEmissions from "../../components/DailyEmissions/DailyEmissions";
import { PinewaveOilProduction } from "../../components/DailyEmissions/types";

const pineWaveOrgId = process.env.REACT_APP_PINEWAVE_ORG_API_ID;

// Set minimum number of days of data to display
// regardless of global time range selection
const minDisplayDays = 7;

const Emissions: FC = () => {
  let startTimestamp = useAppSelector(startTimestampSelector);
  const endTimestamp = useAppSelector(endTimestampSelector);
  const currentSite = useAppSelector(currentSiteSelector);
  const currentOrg = useAppSelector(currentOrganizationSelector);

  let isPinewave = false;
  if (currentOrg?.OrgId === pineWaveOrgId) isPinewave = true;

  const siteId = currentSite?.SiteId ?? "";
  const siteEmissionUnits =
    currentSite?.config?.emissionUnits ?? QuantityUnit.MCF;
  const timezone = currentSite?.timezone ?? "";
  const durationHours = getDurationHours(startTimestamp, endTimestamp);

  const [units, setEmissionUnits] = useState(siteEmissionUnits);
  const [form] = Form.useForm();

  const minDisplayHours = minDisplayDays * 24;
  const minDisplayMinutes = minDisplayHours * 60;
  if (durationHours < minDisplayHours) {
    startTimestamp = subtractMinutes(startTimestamp, minDisplayMinutes);
  }

  const [triggerGetDailyEmissions, dailyEmissionsQueryResult] =
    dailyEmissionsApi.endpoints.getDailyEmissions.useLazyQuery();
  const [triggerGetPinewaveZdscadaData, pinewaveZdscadaDataQueryReslt] =
    pinewaveZdscadaApi.useLazyGetPinewaveZdscadaQuery();

  const dailyEmissions = dailyEmissionsQueryResult?.data?.result ?? [];
  const dailyEmissionsMetaData = dailyEmissionsQueryResult?.data?.metaData ?? {
    current7DayAvgMcf: 0,
    current90DayAvgMcf: 0,
  };

  let pinewaveOilProduction: PinewaveOilProduction = [];
  if (isPinewave) {
    const pinewaveZdscadaData =
      pinewaveZdscadaDataQueryReslt?.data?.result ?? [];

    pinewaveOilProduction = dailyEmissions.map((item: DailyEmission) => {
      const dailyEmissionTimestamp = item.Timestamp;

      const zdscadaData = pinewaveZdscadaData.filter(
        (item: PinewaveZdscada) => {
          const [date] = item.Timestamp.split(" ");
          return date === dailyEmissionTimestamp;
        }
      );

      const oilProduction = zdscadaData.reduce(
        (sum, ele) => sum + Number(ele.oilProd),
        0
      );

      return { Timestamp: dailyEmissionTimestamp, oilProd: oilProduction };
    });
  }

  const refreshData = async (): Promise<void> => {
    const dailyEmissionsPayload = {
      id: siteId,
      startDate: getDateString(startTimestamp),
      endDate: getDateString(endTimestamp),
    };
    await triggerGetDailyEmissions(dailyEmissionsPayload);

    if (isPinewave) {
      const pinewaveZdscadaPayload = {
        siteId,
        startDate: startTimestamp,
        endDate: endTimestamp,
        timezone,
      };
      await triggerGetPinewaveZdscadaData(pinewaveZdscadaPayload);
    }
  };

  const handleChangeUnits = (newUnit: QuantityUnit): void => {
    setEmissionUnits(newUnit);
  };

  useEffect(() => {
    refreshData().catch((e) =>
      console.log("Error fetching daily emissions data:", e)
    );
  }, [startTimestamp, endTimestamp]);

  return (
    <div>
      <DailyEmissions
        siteId={siteId}
        onChangeUnits={handleChangeUnits}
        units={units}
        form={form}
        dailyEmissions={dailyEmissions}
        dailyEmissionsMetaData={dailyEmissionsMetaData}
        minDisplayDays={minDisplayDays}
        isPinewave={isPinewave}
        pinewaveOilProduction={pinewaveOilProduction}
      />
    </div>
  );
};

export default Emissions;
