import { FC, useState, useEffect, ReactNode } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Button,
  Switch,
  Checkbox,
  Tooltip,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../../store/hooks";
import { useGetCurrentUserQuery } from "../../../store/auth/api";
import { currentSiteSelector } from "../../../store/sites/selector";
import {
  startTimestampSelector,
  endTimestampSelector,
} from "../../../store/selections/selector";
import TimeRangePicker from "../../../components/TimeRangePicker/TimeRangePicker";
import styles from "../styles.module.scss";
import {
  NodeOption,
  SiteStatsSelectionProps,
  STATS_TYPES,
  epaCheckboxOptions,
} from "../types";

const MAX_NUM_NODES = 6;

const { Option } = Select;

const SiteStatsSelection: FC<SiteStatsSelectionProps> = ({
  selectedStat,
  statTypeOptions,
  handleStatChange,
  onNodeChange,
  siteNodeOptions,
  selectedNodeIds,
  unitOptions,
  selectedUnitOption,
  onUnitOptionChange,
  refreshData,
  disabled,
  showLocalTime,
  handleChangeShowLocalTime,
  handleResetChartKey,
  handleSetChartTimerange,
  doEnableEpasiteBaseline,
  setEpaBaselineDisplay,
}) => {
  const startTimestamp = useAppSelector(startTimestampSelector);
  const endTimestamp = useAppSelector(endTimestampSelector);
  const [tempSelectedNodeIds, setTempSelectedNodeIds] =
    useState(selectedNodeIds);
  const userData = useGetCurrentUserQuery(null);
  const isAdmin = userData?.data?.isAdmin ?? false;
  const currentSite = useAppSelector(currentSiteSelector);
  const siteTimezone = currentSite?.timezone ?? "";
  const [form] = Form.useForm();
  const formInitialValues = {
    statType: selectedStat,
    nodes: selectedNodeIds,
  };
  const doDisableNodes = selectedStat === STATS_TYPES.AvgEmissionRates;
  const doHideUnits = selectedStat === STATS_TYPES.AvgEmissionRates;
  const doShowEpaThresholds = selectedStat === STATS_TYPES.AvgEmissionRates;
  const CheckboxGroup = Checkbox.Group;
  const handleEpaDisplayCheckbox = (selectedValues: string[]): void => {
    setEpaBaselineDisplay({
      doShow90Day: selectedValues.includes("90-day"),
      doShow7Day: selectedValues.includes("7-day"),
      doShowSiteBaseline: selectedValues.includes("Baseline"),
    });
  };
  const isMaxNumNodes = (id: string): boolean => {
    return tempSelectedNodeIds.length >= MAX_NUM_NODES
      ? !tempSelectedNodeIds.includes(id)
      : false;
  };

  const onClickRefresh = async (): Promise<void> => {
    handleSetChartTimerange(startTimestamp, endTimestamp);
    await Promise.resolve(refreshData());
  };

  const tagRender = (props: {
    label: ReactNode;
    value: string;
  }): JSX.Element => {
    // Display text in nodes selection:
    // first node renders its tag, rest render nothing
    const { label, value } = props;
    const numSelections = selectedNodeIds.length;

    if (String(value) !== selectedNodeIds[0]) return <></>;

    return (
      <div style={{ whiteSpace: "nowrap" }}>
        {label} {numSelections > 1 ? "..." : ""}
      </div>
    );
  };

  const onChangeNodes = (nodeIds: string[]): void => {
    setTempSelectedNodeIds(nodeIds);
  };

  const onBlur = (): void => {
    onNodeChange(tempSelectedNodeIds);
  };

  useEffect(() => {
    if (formInitialValues.nodes.length > 0) {
      form.setFieldsValue(formInitialValues);
    }
    setTempSelectedNodeIds(selectedNodeIds);
  }, [selectedNodeIds]);

  useEffect(() => {
    handleSetChartTimerange(startTimestamp, endTimestamp);
  }, [startTimestamp, endTimestamp]);

  return (
    <Form
      form={form}
      layout="vertical"
      wrapperCol={{ span: 12 }}
      disabled={disabled}
      initialValues={formInitialValues}
    >
      <Row justify="center">
        <Col span={5}>
          <Form.Item name="statType" label="Parameter Type">
            <Select
              value={selectedStat}
              options={statTypeOptions}
              onChange={handleStatChange}
              allowClear={false}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="nodes"
            label="Nodes"
            rules={[{ required: true, message: "Select 1-6 Node(s)" }]}
          >
            <Select
              mode="multiple"
              onChange={onChangeNodes}
              onBlur={onBlur}
              allowClear={false}
              tagRender={tagRender}
              disabled={doDisableNodes}
            >
              {siteNodeOptions.map((item: NodeOption) => (
                <Option disabled={isMaxNumNodes(item.id)} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <div className={styles.DateSelectionWrapper}>
            <TimeRangePicker />
            <Button
              className={styles.RefreshButton}
              style={{ borderRadius: "50%" }}
              icon={<ReloadOutlined />}
              onClick={async () => await onClickRefresh()}
              type="primary"
            />
          </div>
        </Col>
        {doHideUnits ? (
          <Col span={2}>
            <div className={styles.UnitSelectionWrapper}>
              <span>Units</span>
              <Select
                style={{ width: "75%" }}
                defaultValue={selectedUnitOption}
                options={unitOptions}
                onChange={onUnitOptionChange}
              />
            </div>
          </Col>
        ) : null}
        {isAdmin ? (
          <Col span={2}>
            <div className={styles.TimezoneSelectionWrapper}>
              <span>Timezone: {showLocalTime ? siteTimezone : "UTC"}</span>
              <Switch
                onClick={() => handleChangeShowLocalTime(!showLocalTime)}
                className={styles.TimezoneSwitch}
              />
            </div>
          </Col>
        ) : null}
      </Row>
      {doShowEpaThresholds ? (
        <Row>
          <Col>
            <Tooltip title="Thersholds defined by Baseline(s) tab. A 30-day baseline must be established to begin monitoring this site for compliance.">
              <span className={styles.EpaCheckboxLabel}>EPA Thresholds</span>
              <div className={styles.EpaCheckboxContainer}>
                <CheckboxGroup
                  disabled={!doEnableEpasiteBaseline}
                  options={epaCheckboxOptions}
                  onChange={(values) => handleEpaDisplayCheckbox(values)}
                />
              </div>
            </Tooltip>
          </Col>
        </Row>
      ) : null}
    </Form>
  );
};

export default SiteStatsSelection;
