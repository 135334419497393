import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import type {
  User,
  GetUsersResponse,
  DeleteUserParams,
  UpdateUserPayload,
} from "./types";

interface OrgUsersResult {
  users: User[];
}

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery,
  tagTypes: ["Users", "Auth"],
  endpoints: (builder) => ({
    getUsers: builder.query<User[], null>({
      query: () => "users",
      transformResponse: (returnValue: GetUsersResponse) => {
        return returnValue.users;
      },
      providesTags: ["Users"],
    }),
    createUser: builder.mutation<null, User>({
      query: (payload: User) => ({
        url: "users",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation<null, DeleteUserParams>({
      query: (payload: DeleteUserParams) => ({
        url: `users/${payload.userId}`,
        method: "DELETE",
        params: {
          email: payload.email,
        },
      }),
      invalidatesTags: ["Users"],
    }),
    updateUser: builder.mutation<null, UpdateUserPayload>({
      query: (payload: UpdateUserPayload) => ({
        url: `user/${payload.id}`,
        method: "PUT",
        body: payload.values,
      }),
      invalidatesTags: ["Users"],
    }),
    getOrgUsers: builder.query<User[], string>({
      query: (orgId: string) => ({
        url: `orgUsers/${orgId}`,
        method: "get",
      }),
      transformResponse: (result: OrgUsersResult) => {
        return result.users;
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetOrgUsersQuery,
} = usersApi;
