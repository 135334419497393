import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import type { Site } from "./types";

interface GetSitesResponse {
  sites: Site[];
}

interface ToggleSiteInterface {
  SiteId: string;
  OrgId: string;
  newValue: boolean;
}

export const sitesApi = createApi({
  reducerPath: "sitesApi",
  baseQuery,
  tagTypes: ["Sites", "CustomerAlarmIncidents"],
  endpoints: (builder) => ({
    getSites: builder.query<Site[], string>({
      query: (orgId: string) => `organization/${orgId}/sites`,
      transformResponse: (returnValue: GetSitesResponse) => {
        return returnValue.sites;
      },
      providesTags: ["Sites"],
    }),
    createSite: builder.mutation<null, Site>({
      query: (payload) => ({
        url: `organization/${payload.OrgId}/site`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Sites", "CustomerAlarmIncidents"],
    }),
    updateSite: builder.mutation<null, Site>({
      query: (payload) => ({
        url: `site/${payload.SiteId}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Sites"],
    }),
    toggleSite: builder.mutation<null, ToggleSiteInterface>({
      query: (payload) => ({
        url: `toggleSite/${payload.SiteId}`,
        method: "PUT",
        params: {
          active: payload.newValue,
          OrgId: payload.OrgId,
        },
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          sitesApi.util.updateQueryData("getSites", payload.OrgId, (draft) => {
            const index = draft.findIndex((el) => el.SiteId === payload.SiteId);
            draft[index].active = payload.newValue;
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetSitesQuery,
  useCreateSiteMutation,
  useToggleSiteMutation,
  useUpdateSiteMutation,
} = sitesApi;
