import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import type {
  SiteAlarm,
  GetSiteAlarmsResponse,
  CreateSiteAlarmsPayload,
} from "./types";

export const siteAlarmsApi = createApi({
  reducerPath: "siteAlarmsApi",
  baseQuery,
  tagTypes: ["SiteAlarms"],
  endpoints: (builder) => ({
    getSiteAlarms: builder.query<SiteAlarm[], string>({
      query: (siteId: string) => `siteAlarms/${siteId}`,
      transformResponse: (returnValue: GetSiteAlarmsResponse) => {
        return returnValue.alarms;
      },
      providesTags: ["SiteAlarms"],
    }),
    createSiteAlarms: builder.mutation<null, CreateSiteAlarmsPayload>({
      query: (payload: CreateSiteAlarmsPayload) => ({
        url: `siteAlarms/${payload.siteId}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["SiteAlarms"],
    }),
  }),
});

export const { useGetSiteAlarmsQuery, useCreateSiteAlarmsMutation } =
  siteAlarmsApi;
