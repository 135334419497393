import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { GetPinewaveZdscadaParams, GetPinewaveZdscadaResponse } from "./types";

export const pinewaveZdscadaApi = createApi({
  reducerPath: "pinewaveZdscadaApi",
  baseQuery,
  endpoints: (builder) => ({
    getPinewaveZdscada: builder.query<
    GetPinewaveZdscadaResponse,
    GetPinewaveZdscadaParams
    >({
      query: (payload) => {
        const { siteId, startDate, endDate } = payload;
        const params = {
          startDate,
          endDate,
        };
        return {
          url: `pinewaveZdscada/${siteId}`,
          method: "GET",
          params,
        };
      },
    }),
  }),
});
