import { Organization } from "../../store/organizations/types";
import { Site } from "../../store/sites/types";
import { Node } from "../../store/nodes/types";
import { NodeStatusCheck } from "../../store/statusChecks/types";
import { RawNodeSample } from "../../store/rawNodeSamples/types";
import { NodePPM } from "../../store/nodesPPM/types";

export enum DATA_TYPES {
  statusChecks = "Statuses",
  rawNodeSamples = "Raw Data",
  nodePPM = "NodePPM",
}

export interface MenuSelections {
  dataType: string;
  org: Organization | null;
  site: Site | null;
  nodes: Node[] | [];
  columns: string[];
  startTimestamp: string;
  durationHours: number;
  durationDays: number;
  doFilterInvalidRawData: boolean;
}

export interface AnalyticsSelectionProps {
  orgs: Organization[];
  handleChangeOrg: (newOrgName: string) => void;
  sites: Site[];
  handleChangeSite: (newSiteName: string) => void;
  nodes: Node[];
  handleChangeNodes: (newNodeNames: string[]) => void;
  menuSelections: MenuSelections;
  startDateTime: string;
  durationHours: number;
  handleChangeDurationHours: (newVal: number) => void;
  durationDays: number;
  handleChangeDurationDays: (newVal: number) => void;
  handleChangeColumns: (newCols: string[]) => void;
  handleChangeDataType: (newDataType: string) => void;
}

export interface StatusChartProps {
  menuSelections: MenuSelections;
  timeSeriesData: Array<NodeStatusCheck | RawNodeSample | NodePPM>;
}
