const statusCheckColumns = [
  { key: "online", name: "Online Status" },
  { key: "validData", name: "Valid Data" },
  { key: "validWindSpeed", name: "Valid Wind Speed" },
  { key: "validWindDirection", name: "Valid Wind Direction" },
  { key: "tier1Frequency", name: "Tier 1 Frequency" },
  { key: "tier2Frequency", name: "Tier 2 Frequency" },
  { key: "batteryVoltage", name: "Battery Voltage" },
  { key: "signalQuality", name: "Signal Quality" },
];

const rawNodeSamplesColumns = [
  { key: "C2", name: "CO2" }, // CO2 sensor PPM value. If sensor does not exist, value 32767
  { key: "CS", name: "Charging Status" }, // Charging Status
  { key: "DN", name: "Door Opened" }, // Door opened or not
  { key: "H1", name: "Humidity (Onboard)" }, // Humidity from Onboard Temperature/Humidity Sensor
  { key: "H2", name: "Humidity (External)" }, // Humidity from External Temperature/Humidity Sensor
  { key: "H3", name: "Humidity (Chamber)" }, // Humidity from MOS Temperature/Humidity Sensor
  { key: "IB", name: "Battery Current" }, // Battery current
  { key: "IM", name: "MOS Current" }, // MOS current
  { key: "M0", name: "M0 Resistance" }, // Resistance of TGS2600 MOS
  { key: "M1", name: "M1 Resistance" }, // Resistance of TGS2611 MOS
  { key: "M2", name: "M2 Resistance" }, // Resistance of TGS2602 MOS
  { key: "MC", name: "Pump Motor Current" }, // Pump motor current consumption
  { key: "P1", name: "Pressure" }, // Pressure from Internal Pressure/Temperature Sensor
  { key: "SC", name: "Shock" }, // Shock sensor
  { key: "SQ", name: "Signal Quality" }, // Signal quality received from SIMCOM module
  { key: "T1", name: "Temperature (Internal)" }, // Temperature from Internal Pressure/Temperature Sensor
  { key: "T2", name: "Temperature (Onboard)" }, // Temperature from Onboard Temperature/Humidity Sensor
  { key: "T3", name: "Temperature (External)" }, // Temperature from External Temperature/Humidity Sensor
  { key: "T4", name: "Temperature (Chamber)" }, // Temperature from MOS Temperature/Humidity Sensor
  { key: "UV", name: "Ultraviolet" }, // UV sensor
  { key: "VB", name: "Battery Voltage" }, // Battery voltage
  { key: "VM", name: "MOS Voltage" }, // MOS 5V rail voltage
  { key: "VS", name: "Solar Voltage" }, // Solar voltage
  { key: "WD", name: "Wind Direction" }, // Wind direction. 0 = North, 90 = East, 180 = South, 270 = West
  { key: "WS", name: "Wind Speed" }, // Wind speed
  { key: "WA", name: "Wind Speed (average)" }, // Wind speed (average)
  { key: "WG", name: "Wind Speed (gust)" }, // Wind speed (gust)
  { key: "H2SUA", name: "H2S Current" }, // H2S sensor 4-20mA current in microamps
  { key: "H2SMV", name: "H2S Voltage" }, // H2S sensor supply voltage in millivolts
  { key: "H2ST", name: "H2S Temperature" }, // H2S sensor temperature value in degrees Celsius
  { key: "H2SH", name: "H2S Humidity" }, // H2S sensor humidity value in RH%
  { key: "PM", name: "MOS Power" }, // MOS power [COMPUTED VALUE => PM = IM * VM]
  { key: "PB", name: "Battery (System) Power" }, // System power [COMPUTED VALUE => PS = IB * VB]
];

const nodePPMColumns = [
  { key: "bmeBP", name: "Pressure" }, // pressure
  { key: "bmeRH", name: "Relative Humidity" }, // relative humidity
  { key: "bmeSH", name: "Absolute Humidity" }, // absolute humidity
  { key: "bmeTemp", name: "Temperature" }, // temperature
  { key: "elevation", name: "Elevation" }, // elevation
  { key: "figaroV", name: "MOS Voltage" }, // MOS voltage
  { key: "gas1", name: "M0 Resistance" }, // M0 resistance
  { key: "gas1D", name: "M0 CH4 Estimate" }, // gas 1 CH4 estimate
  { key: "gas1R", name: "M0 Predicted Resistance" }, // predicted M0 resistance
  { key: "gas2", name: "M2 Resistance" }, // M2 resistance
  { key: "gas2D", name: "M2 VOC Estimate" }, // VOC estimate
  { key: "gas2R", name: "M2 Predicted Resistance" }, // predicted M2 resistance
  { key: "gas3", name: "M1 Resistance" }, // M1 resistance
  { key: "gas3D", name: "M1 CH4 Estimate" }, // gas 3 CH4 estimate
  { key: "gas3R", name: "M1 Predicted Resistance" }, // predicted M1 resistance
  { key: "latitude", name: "Latitude" }, // latitude
  { key: "longitude", name: "Longitude" }, // longitude
  { key: "outRH", name: "API Relative Humidity" }, // API relative humidity
  { key: "outSH", name: "API Absolute Humidity" }, // API absolute humidity
  { key: "outTemp", name: "API Temperature" }, // API temperature
  { key: "sampleHeight", name: "Sample Height" }, // sample height
  { key: "sourceV", name: "Battery Voltage" }, // battery voltage
  { key: "windDIR", name: "Wind Direction" }, // wind direction
  { key: "windMPH", name: "Wind Speed" }, // wind speed
  { key: "regresCoeffA", name: "Regression AH Coefficient" },
  { key: "regresCoeffR", name: "Regression RH Coefficient" },
  { key: "regresCoeffT", name: "Regression T Coefficient" },
  { key: "regresCoeffV", name: "Regression V Coefficient" },
  { key: "regresCoeffA2", name: "Regression AH^2 Coefficient" },
  { key: "regresCoeffR2", name: "Regression RH^2 Coefficient" },
  { key: "regresCoeffT2", name: "Regression T^2 Coefficient" },
  { key: "regresCoeffV2", name: "Regression V^2 Coefficient" },
  { key: "regresCoeffRA", name: "Regression R*A Coefficient" },
  { key: "regresCoeffTA", name: "Regression T*A Coefficient" },
  { key: "regresCoeffAV", name: "Regression A*V Coefficient" },
  { key: "regresCoeffTR", name: "Regression T*R Coefficient" },
  { key: "regresCoeffRV", name: "Regression R*V Coefficient" },
  { key: "regresCoeffTV", name: "Regression T*V Coefficient" },
  { key: "regressionModel", name: "Regression Model" },
];

const dataColumns = {
  statusChecks: statusCheckColumns,
  rawNodeSamples: rawNodeSamplesColumns,
  nodePPM: nodePPMColumns,
};

export const columnToAxisName = (col: string): string => {
  switch (col) {
    case "bmeSH":
    case "outSH":
      return "Absolute Humidity [g/m3]";
    case "CS":
    case "DN":
    case "SC":
      return "Binary [0 or 1]";
    case "gas1D":
    case "gas2D":
    case "gas3D":
      return "CH4 Concentration [ppm]";
    case "C2":
      return "CO2 Concentration [ppm]";
    case "IB":
    case "IM":
    case "MC":
      return "Current [mA]";
    case "H2SUA":
      return "Current [µA]";
    case "elevation":
      return "Elevation [ft]";
    case "latitude":
      return "Latitude [decimal]";
    case "longitude":
      return "Longitude [decimal]";
    case "P1":
    case "bmeBP":
      return "Pressure [psi]";
    case "H1":
    case "H2":
    case "H3":
    case "bmeRH":
    case "outRH":
    case "H2SH":
      return "Relative Humidity [%]";
    case "M0":
    case "M1":
    case "M2":
    case "gas1":
    case "gas1R":
    case "gas2":
    case "gas2R":
    case "gas3":
    case "gas3R":
      return "Resistance [Ω]";
    case "sampleHeight":
      return "Sample Height [ft]";
    case "SQ":
      return "Signal Quality [0 - 99]";
    case "T1":
    case "T2":
    case "T3":
    case "T4":
    case "bmeTemp":
    case "outTemp":
    case "H2ST":
      return "Temperature [°C]";
    case "UV":
      return "UV [0 - 4095]";
    case "VB":
    case "VS":
    case "figaroV":
    case "sourceV":
      return "Voltage [V]";
    case "VM":
    case "H2SMV":
      return "Voltage [mV]";
    case "WD":
    case "windDIR":
      return "Wind Direction [0 - 360]";
    case "WS":
    case "WA":
    case "WG":
    case "windMPH":
      return "Wind Speed [km/h]";
    case "PB":
    case "PM":
      return "milliwatts [mW]";
    case "regresCoeffA":
      return "Regress AH";
    case "regresCoeffR":
      return "Regress RH";
    case "regresCoeffT":
      return "Regress T";
    case "regresCoeffV":
      return "Regress V";
    case "regresCoeffA2":
      return "Regress A^2";
    case "regresCoeffR2":
      return "Regress R^2";
    case "regresCoeffT2":
      return "Regress T^2";
    case "regresCoeffV2":
      return "Regress V^2";
    case "regresCoeffRA":
      return "Regress R*AH";
    case "regresCoeffTA":
      return "Regress T*AH";
    case "regresCoeffAV":
      return "Regress AH*V";
    case "regresCoeffTR":
      return "Regress T*RH";
    case "regresCoeffRV":
      return "Regress RH*V";
    case "regresCoeffTV":
      return "Regress T*V";
    case "regressionModel":
      return "Regress Model";
    default:
      return "y";
  }
};

export default dataColumns;
