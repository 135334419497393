import { FC, useEffect } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { setPageTitle } from "../../../store/ui/slice";
import { Button, Form, Input, Radio } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { Organization } from "../../../store/organizations/types";
import * as uuid from "uuid";
import { useCreateOrganizationMutation } from "../../../store/organizations/api";
import { useNavigate } from "react-router-dom";
import { FormValues, APIResponse } from "./types";
import styles from "./styles.module.scss";

const RadioGroup = Radio.Group;

const NewOrganization: FC = () => {
  const dispatch = useAppDispatch();
  const [createOrganization] = useCreateOrganizationMutation();
  const navigate = useNavigate();

  const onFinish = (values: FormValues): void => {
    const newOrg: Organization = { ...values, OrgId: uuid.v4() };
    createOrganization(newOrg)
      .unwrap()
      .then((response: APIResponse | null) => {
        if (response !== null && response.Message === "SUCCESS") {
          window.alert("Organization created successfully");
          navigate("/organizations");
        }
      })
      .catch((err: string) => {
        console.log(err);
        window.alert(
          "error while creating organization, please try again later"
        );
      });
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity): void => {
    console.log("Failed:", errorInfo);
  };

  const onCancel = (): void => {
    navigate("/organizations/");
  };

  useEffect(() => {
    dispatch(setPageTitle("New Organization"));
  }, []);

  const orgForm = (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      wrapperCol={{ span: 6 }}
    >
      <Form.Item
        label="Organization Name"
        name="name"
        rules={[
          { required: true, message: "Please input the organization name" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Country"
        name="country"
        rules={[
          { required: true, message: "Please input the organization country" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Type"
        name="type"
        rules={[
          { required: true, message: "Please input the organization type" },
        ]}
      >
        <RadioGroup>
          <Radio value={"Client"}>Client</Radio>
          <Radio value={"Partner"}>Partner</Radio>
        </RadioGroup>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className={styles.SubmitBtn}>
          Submit
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Form.Item>
    </Form>
  );

  return <div>{orgForm}</div>;
};

export default NewOrganization;
