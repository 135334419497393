import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { AuthState } from "./types";

const initialState: AuthState = {
  currentUser: "",
  token: "",
  userId: "",
};

export const authSlice: Slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser: (state: AuthState, action: PayloadAction<AuthState>) => {
      state.currentUser = action.payload.currentUser;
      state.token = action.payload.token;
      state.userId = action.payload.userId;
    },
    onSignOut: (state: AuthState) => {
      state.currentUser = "";
      state.token = "";
    },
  },
});

export const { setCurrentUser, setToken, onSignOut } = authSlice.actions;

export default authSlice.reducer;
