import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import {
  GetStatusChecksParams,
  GetStatusChecksResponse,
  GetLatestStatusChecksResponse,
} from "./types";

export const statusChecksApi = createApi({
  reducerPath: "statusChecksApi",
  baseQuery,
  endpoints: (builder) => ({
    getLatestStatusChecks: builder.query<GetLatestStatusChecksResponse, null>({
      query: () => "nodeStatuses",
    }),
    getStatusChecks: builder.query<
    GetStatusChecksResponse,
    GetStatusChecksParams
    >({
      query: (payload) => {
        const { id, nodeIds, startDate, endDate, timezone } = payload;
        const params = { startDate, endDate, nodeIds, timezone };
        return {
          url: `node/${id}/status`,
          method: "GET",
          params,
        };
      },
    }),
  }),
});

export const { useGetLatestStatusChecksQuery } = statusChecksApi;
