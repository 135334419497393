import { RootState } from "..";

export const startTimestampSelector = (state: RootState): string =>
  state.selections.startTimestamp;

export const endTimestampSelector = (state: RootState): string =>
  state.selections.endTimestamp;

export const currentTabSelector = (state: RootState): string =>
  state.selections.currentTab;
