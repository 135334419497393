import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { GpaqsAlarmIncident } from "../../components/MapController/types";
import { convertTimezone } from "../../helpers/dayjsHelpers";
import { AlarmIncidentType } from "../orgAlarmIncidentsV2/types";

export interface GpaqsAlarmIncidentsParams {
  siteId: string;
  startDate: string;
  endDate: string;
  timezone: string;
}

interface GpaqsAlarmIncidentsResponse {
  gpaqsAlarms: GpaqsAlarmIncident[];
}

export const gpaqsAlarmIncidents = createApi({
  reducerPath: "gpaqsAlarmIncidentsApi",
  baseQuery,
  tagTypes: ["gpaqsAlarmIncidents"],
  endpoints: (builder) => ({
    getGpaqsAlarmIncidents: builder.query<
    GpaqsAlarmIncidentsResponse,
    GpaqsAlarmIncidentsParams
    >({
      query: (payload) => {
        const { siteId, startDate, endDate, timezone } = payload;
        const _startDate = convertTimezone(startDate, timezone, "UTC");
        const _endDate = convertTimezone(endDate, timezone, "UTC");
        const params = {
          startDate: _startDate,
          endDate: _endDate,
        };
        return {
          url: `gpaqsAlarmIncidentsV2/${siteId}`,
          params,
          method: "GET",
        };
      },
      providesTags: ["gpaqsAlarmIncidents"],
    }),
    updateGpaqsAlarmIncident: builder.mutation<null, AlarmIncidentType>({
      query: (payload) => ({
        url: `gpaqsAlarmIncidentsV2/${payload.SiteId}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["gpaqsAlarmIncidents"],
    }),
  }),
});

export const {
  useGetGpaqsAlarmIncidentsQuery,
  useUpdateGpaqsAlarmIncidentMutation,
} = gpaqsAlarmIncidents;
