import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { NodeOptimization } from "./types";

export const nodeOptimizationImageApi = createApi({
  reducerPath: "nodeOptimizationImageApi",
  baseQuery,
  tagTypes: ["NodeOptimizationImageApi"],
  endpoints: (builder) => ({
    getNodeOptimizationImage: builder.query<NodeOptimization, string>({
      query: (siteId: string) => `site/${siteId}/nodeOptimizationImages`,
      providesTags: ["NodeOptimizationImageApi"],
    }),
  }),
});

export const { useGetNodeOptimizationImageQuery } = nodeOptimizationImageApi;
