import { DailyEmissionsMetaData } from "../../../store/dailyEmissions/types";
import {
  QuantityUnit,
  RateUnit,
} from "../../../helpers/unitsHelpers";
import { FC } from "react";
import styles from "./styles.module.scss";

interface DisplayBoxProps {
  dailyEmissionsMetaData: DailyEmissionsMetaData;
  units: QuantityUnit;
}
const DisplayBox: FC<DisplayBoxProps> = ({ dailyEmissionsMetaData, units }) => {
  const unitText =
    units === QuantityUnit.MCF
      ? RateUnit.MCF_DAY
      : RateUnit.KG_H;

  return (
    <div className={styles.Container}>
      <div>
        <p
          className={styles.SevenDay}
        >{`7-Day Avg: ${dailyEmissionsMetaData.current7DayAvgMcf} ${unitText}`}</p>
        <p
          className={styles.NinetyDay}
        >{`90-Day Avg: ${dailyEmissionsMetaData.current90DayAvgMcf} ${unitText}`}</p>
      </div>
    </div>
  );
};

export default DisplayBox;
