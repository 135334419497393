import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { Grid } from "../../pages/SiteMapv2/helpers";

interface GetSiteGridQueryPayload {
  siteId: string;
  timestamp?: string;
}

interface CreateSiteGridResponse {
  Message: string;
}

export const siteGridConfig = createApi({
  reducerPath: "siteGridConfigApi",
  baseQuery,
  tagTypes: ["SiteGridConfig"],
  endpoints: (builder) => ({
    getSiteGridConfig: builder.query<Grid, GetSiteGridQueryPayload>({
      query: (payload) => {
        const { siteId, timestamp } = payload;
        if (timestamp !== undefined) {
          const queryParams = new URLSearchParams();
          queryParams.append("timestamp", timestamp);
          const queryString = queryParams.toString();
          return `siteGridConfig/${siteId}?${queryString}`;
        } else {
          return `siteGridConfig/${siteId}`;
        }
      },
      providesTags: ["SiteGridConfig"],
    }),
    createSiteGridConfig: builder.mutation<CreateSiteGridResponse, Grid>({
      query: (payload) => ({
        url: "siteGridConfig",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["SiteGridConfig"],
    }),
  }),
});

export const { useGetSiteGridConfigQuery, useCreateSiteGridConfigMutation } =
  siteGridConfig;
