import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { GlobalConfig } from "./types";

const GLOBAL_CONFIG_VERSION = "v1";

export const globalConfigApi = createApi({
  reducerPath: "globalConfigApi",
  baseQuery,
  tagTypes: ["GlobalConfig"],
  endpoints: (builder) => ({
    getGlobalConfig: builder.query<GlobalConfig, null>({
      query: () => `globalConfig/${GLOBAL_CONFIG_VERSION}`,
      providesTags: ["GlobalConfig"],
    }),
  }),
});

export const { useGetGlobalConfigQuery } = globalConfigApi;
