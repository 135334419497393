import { FC, CSSProperties } from "react";
import { DatePicker } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import styles from "./styles.module.scss";
import { SizeType } from "antd/lib/config-provider/SizeContext";

interface DatePickerWithArrowsProps {
  onDateChange: (date: Dayjs, dateString: string | string[]) => void;
  selectedDate: string;
  size?: SizeType | undefined;
  style?: CSSProperties;
}

const DatePickerWithArrows: FC<DatePickerWithArrowsProps> = ({
  onDateChange,
  selectedDate,
  size,
  style = {},
}) => {
  return (
    <div className={styles.Wrapper}>
      <LeftOutlined
        onClick={() => {
          onDateChange(
            dayjs(selectedDate).subtract(1, "day"),
            dayjs(selectedDate).subtract(1, "day").format("YYYY-MM-DD")
          );
        }}
      />
      <DatePicker
        onChange={onDateChange}
        value={dayjs(selectedDate)}
        allowClear={false}
        size={size}
        style={{ ...style }}
      />
      <RightOutlined
        onClick={() => {
          onDateChange(
            dayjs(selectedDate).add(1, "day"),
            dayjs(selectedDate).add(1, "day").format("YYYY-MM-DD")
          );
        }}
      />
    </div>
  );
};

export default DatePickerWithArrows;
