import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Node, NodesState } from "./types";
import { PURGE } from "redux-persist";

const initialState: NodesState = {
  currentNode: null,
};

export const nodeSlice = createSlice({
  name: "nodes",
  initialState,
  reducers: {
    setCurrentNode: (
      state: NodesState,
      action: PayloadAction<Node>
    ) => {
      state.currentNode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState;
    });
  }
});

export const { setCurrentNode } = nodeSlice.actions;

export default nodeSlice.reducer;
