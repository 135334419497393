import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import {
  RequestCsvDownload,
  RequestCsvDownloadResponse
} from "./types";

export const requestCsvDownloadApi = createApi({
  reducerPath: "requestCsvDownloadApi",
  baseQuery,
  tagTypes: ["RequestCsvDownload"],
  endpoints: (builder) => ({
    getRequestCsvDownload: builder.query<RequestCsvDownloadResponse, RequestCsvDownload>({
      query: (payload) => {
        const {
          id,
          nodeIds,
          startDate,
          endDate,
          emailAddress,
          fileType,
          projectionExpression
        } = payload;
        const params = {
          nodeIds,
          startDate,
          endDate,
          emailAddress,
          fileType,
          projectionExpression
        };
        return {
          url: `site/${id}/requestCsvDownload`,
          method: "GET",
          params,
        };
      },
      providesTags: ["RequestCsvDownload"],
    }),
  }),
});

export const { useGetRequestCsvDownloadQuery } = requestCsvDownloadApi;
