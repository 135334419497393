import React, { useState } from "react";
import { Form, Button, Upload, Slider } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import {
  drawOverlayOnly,
  finishMapLocationSelection,
  getOverlayBounds,
  movableOverlay,
  removeMovableOverlay,
  topographicOverlay,
  validateLayer,
} from "../../../../pages/OrganizationMap/imageOverlayHelper";
import { usePostSiteImageMutation } from "../../../../store/siteImage/api";
import { removePushPins } from "../../../../pages/OrganizationMap/mapApi";
import { UploadChangeParam } from "antd/es/upload";

const { Item: FormItem } = Form;

interface FormValues {
  image: UploadFile[];
}

type NormFileEvent = UploadChangeParam<UploadFile> | UploadFile[] | null;

interface UploadFormProps {
  latitude: number;
  longitude: number;
  siteId: string;
  locationHandlerId: null | (() => void);
}

const UploadForm: React.FC<UploadFormProps> = ({
  latitude,
  longitude,
  siteId,
  locationHandlerId,
}) => {
  const [form] = Form.useForm<FormValues>();
  const [postSiteImage] = usePostSiteImageMutation();
  const [objectUrl, setObjectUrl] = useState("");

  const handleFinish = (values: FormValues): void => {
    const file = values.image[0].originFileObj;
    const imageUrl = file != null ? URL.createObjectURL(file) : "";

    setObjectUrl(imageUrl);
    movableOverlay(latitude, longitude, imageUrl, 0.5, siteId, 0);
  };

  const handleRotate = (newRotation: number): void => {
    if (objectUrl !== "") {
      drawOverlayOnly(siteId, newRotation, false, objectUrl);
    }
  };

  const handleChangeOpacity = (value: number): void => {
    const newOpacity = value / 100;
    if (objectUrl !== "") {
      drawOverlayOnly(siteId, false, newOpacity, objectUrl);
    }
  };

  const normFile = (e: NormFileEvent): UploadFile[] | undefined => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const convertToBase64 = async (file: RcFile): Promise<string> => {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result;
        if (typeof result === "string") {
          resolve(result.split(",")[1]);
        } else {
          reject(new Error("FileReader result is not a string."));
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  const onSave = async (): Promise<void> => {
    if (objectUrl !== "") {
      const isValidLayer: boolean = validateLayer(siteId);
      if (!isValidLayer) {
        window.alert(
          "Please select a point in the map to load the image and click apply before continuing"
        );
      }
      const layerDetails = getOverlayBounds(siteId);
      setIsLoading(true);
      const files: UploadFile[] = form.getFieldValue("image") as UploadFile[];
      if (files.length > 0) {
        const file: RcFile = files[0].originFileObj as RcFile;
        const base64 = await convertToBase64(file);
        const _extension = file.name.split(".").pop();
        const extension = _extension !== undefined ? _extension : "";
        const imageDetails = {
          SiteId: siteId,
          ...layerDetails,
          extension,
        };
        await postSiteImage({
          file: base64,
          id: siteId,
          imageDetails,
        })
          .unwrap()
          .then((res) => {
            setIsLoading(false);
            window.alert("Image Saved");
            if (res.Message === "OK") {
              const imageUrl = URL.createObjectURL(file);
              removeMovableOverlay();
              removePushPins();
              topographicOverlay([{ imageDetails, url: imageUrl }]);
              finishMapLocationSelection(locationHandlerId);
            }
          })
          .catch((e) => {
            console.log(e);
            setIsLoading(false);
          });
      }
    }
  };

  const beforeUpload = (file: File): boolean => {
    const isLt4M = file.size / 1024 / 1024 < 4;
    if (!isLt4M) {
      window.alert("Image must be smaller than 4MB");
      return true;
    } else return false;
  };

  return (
    <div>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <div style={{ marginTop: 10, marginBottom: 10, fontWeight: "bold" }}>
          Click in the map the center where the initial image will be placed
        </div>

        <FormItem
          name="image"
          label="Select Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: "Please select an image" }]}
        >
          <Upload name="image" listType="picture" beforeUpload={beforeUpload}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </FormItem>

        <FormItem>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </FormItem>
      </Form>
      <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
        Opacity
        <Slider
          min={0}
          max={100}
          defaultValue={50}
          onChange={handleChangeOpacity}
          style={{ width: "300px", margin: "0 auto" }}
        />
      </div>
      <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
        Rotation
        <Slider
          min={-180}
          max={180}
          defaultValue={0}
          onChange={handleRotate}
          style={{ width: "300px", margin: "0 auto" }}
        />
      </div>
      <Button onClick={onSave} loading={isLoading}>
        Save
      </Button>
    </div>
  );
};

export default UploadForm;
