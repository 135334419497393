import { md5 } from "js-md5";

export const statCheckToNumber = (input: {
  message?: string;
  result: string;
}): number => {
  if (input?.result === "pass") return 100;
  if (input?.message === undefined) return 100;
  const [resultString] = input.message.split("%");
  return Number(resultString);
};

export const stringToColor = (s: string): string => {
  const md5String = md5(s);

  let hash = 0;
  for (let i = 0; i < md5String.length; i++) {
    hash = md5String.charCodeAt(i) + ((hash << 5) - hash);
  }

  const color = (hash & 0x00ffffff).toString(16).toUpperCase();
  return `#${"00000".substring(0, 6 - color.length)}${color}`;
};

const regressionModelMap: { [key: string]: number } = {
  unknown: 0,
  mos_1_ppm_100: 1,
  mos_1_ppm_1500: 2,
  mos_3_ppm_100: 3,
  mos_3_ppm_1500: 4,
  mos_1_ppm_long_range: 5,
  mos_1_ppm_increasing_rh: 6,
  mos_1_ppm_decreasing_rh: 7,
  mos_3_ppm_long_range: 8,
  mos_3_ppm_increasing_rh: 9,
  mos_3_ppm_decreasing_rh: 10,
};

export const regressionModelToInt = (regressionModel: string): number => {
  return regressionModelMap[regressionModel] ?? 0;
};

export const intToRegressionModel = (value: number): string => {
  const intToRegressionModelMap = Object.fromEntries(
    Object.entries(regressionModelMap).map(([key, value]) => [value, key])
  );
  return intToRegressionModelMap[value] ?? "unknown";
};
