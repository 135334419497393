import { Form, Select } from "antd";
import { FC, useEffect } from "react";
import { DailyEmissionSelectionProps } from "../types";
import TimeRangePicker from "../../TimeRangePicker/TimeRangePicker";
import { QuantityUnit } from "../../../helpers/unitsHelpers";

const DailyEmissionSelection: FC<DailyEmissionSelectionProps> = ({
  form,
  onChangeUnits,
  units,
  minDisplayDays,
}) => {
  const unitOptions = [
    {
      value: QuantityUnit.MCF,
      label: QuantityUnit.MCF,
    },
    {
      value: QuantityUnit.KG,
      label: QuantityUnit.KG,
    },
  ];

  const commonDurationOptions = [
    { label: "1 Month", durationDays: 28 },
    { label: "3 Months", durationDays: 90 },
    { label: "6 Months", durationDays: 180 },
    { label: "12 Months", durationDays: 365 },
  ];

  useEffect(() => {
    form.setFieldValue("emissionUnits", units);
  }, [units]);

  return (
    <Form
      form={form}
      layout="vertical"
      wrapperCol={{ span: 12 }}
      style={{ display: "flex", justifyContent: "center", gap: "100px" }}
    >
      <div>
        <TimeRangePicker commonDurationOptions={commonDurationOptions} />
      </div>
      <div style={{ width: "150px" }}>
        <Form.Item name="emissionUnits" label="Units" initialValue={units}>
          <Select options={unitOptions} onChange={onChangeUnits} />
        </Form.Item>
      </div>
    </Form>
  );
};

export default DailyEmissionSelection;
