import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import {
  SiteImage,
  SiteImageMutationParams,
  ImageDetails,
  DeleteImageParams,
} from "./types";

export const siteImageApi = createApi({
  reducerPath: "siteImageApi",
  baseQuery,
  tagTypes: ["SiteImage"],
  endpoints: (builder) => ({
    getSiteImage: builder.query<SiteImage, string>({
      query: (siteId: string) => `siteImage/${siteId}`,
      providesTags: ["SiteImage"],
    }),
    postSiteImage: builder.mutation<
    { Message: string },
    SiteImageMutationParams
    >({
      query: ({ file, id, imageDetails }) => {
        return {
          url: `siteImage/${id}`,
          method: "POST",
          body: {
            file,
            imageDetails,
          },
        };
      },
      invalidatesTags: ["SiteImage"],
    }),
    updateSiteImage: builder.mutation<{ Message: string }, ImageDetails>({
      query: (imageDetails) => {
        return {
          url: `siteImage/${imageDetails.SiteId}`,
          method: "PUT",
          body: imageDetails,
        };
      },
      invalidatesTags: ["SiteImage"],
    }),
    deleteSiteImage: builder.mutation<{ Message: string }, DeleteImageParams>({
      query: (params) => {
        return {
          url: `siteImage/${params.siteId}?extension=${params.extension}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["SiteImage"],
    }),
  }),
});

export const {
  useLazyGetSiteImageQuery,
  usePostSiteImageMutation,
  useGetSiteImageQuery,
  useUpdateSiteImageMutation,
  useDeleteSiteImageMutation,
} = siteImageApi;
