import { FC } from "react";
import { Node } from "../../../../store/nodes/types";

interface ConcentrationAlarmTableProps {
  ppm: number;
  timestamp: string;
  siteNodes: Node[];
  node: string;
  timezone: string;
}

const ConcentrationAlarmTable: FC<ConcentrationAlarmTableProps> = ({
  ppm,
  timestamp,
  siteNodes,
  node,
  timezone,
}) => {
  const nodeWithHighConcentration = siteNodes.find((sn) => sn.NodeId === node);
  const nodeName =
    nodeWithHighConcentration !== undefined
      ? nodeWithHighConcentration.name
      : "";
  return (
    <div>
      <h1>High PPM Concentration Alert</h1>
      <p style={{ fontSize: 13 }}>
        There was a measured high ppm concentration of <b>{Math.round(ppm)}</b>{" "}
        ppm at{" "}
        <b>
          {timestamp} {timezone}
        </b>
        . This was observed by the node named <b>{nodeName}</b>.
      </p>
    </div>
  );
};

export default ConcentrationAlarmTable;
