import { FC } from "react";
import { useGetNodeInstallHistoryQuery } from "../../store/nodeInstallHistory/api";
import styles from "./styles.module.scss";
import { Table } from "antd";

interface NodeInstallHistoryProps {
  nodeId: string;
}

const NodeInstallHistory: FC<NodeInstallHistoryProps> = ({ nodeId }) => {
  const columns = [
    {
      title: "Timestamp UTC",
      dataIndex: "Timestamp",
      key: "Timestamp",
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      width: 250,
    },
    {
      title: "Old Site Name",
      dataIndex: "oldSiteName",
      key: "oldSiteName",
      width: 200,
    },
    {
      title: "New Site Name",
      dataIndex: "newSiteName",
      key: "newSiteName",
      width: 200,
    },
  ];
  const nodeInstallHistoryResult = useGetNodeInstallHistoryQuery({
    id: nodeId,
  });

  const nodeInstallHistoryTableData =
    nodeInstallHistoryResult.data !== undefined
      ? nodeInstallHistoryResult.data.result
      : [];
  const tableData = nodeInstallHistoryTableData.map((el, idx) => {
    return { ...el, key: idx };
  });
  return (
    <div className={styles.NodeInstallHistory}>
      <div>
        <div className={styles.TableHeader}>
          <b>Install History</b>
        </div>

        <Table
          dataSource={tableData}
          columns={columns}
          loading={nodeInstallHistoryResult.isLoading}
        />
      </div>
    </div>
  );
};

export default NodeInstallHistory;
