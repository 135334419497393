import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import {
  Node,
  NodeParams,
  NodeResponse,
  UpdateNodeResponse,
  UnassignedNodesResponse,
  AssignNodesResponse,
} from "./types";

export const siteNodesApi = createApi({
  reducerPath: "siteNodesAPI",
  baseQuery,
  tagTypes: ["SiteNodes", "UnassignedNodes"],
  endpoints: (builder) => ({
    getNode: builder.query<NodeResponse, NodeParams>({
      query: (payload) => {
        const { id } = payload;
        return {
          url: `nodes/${id}`,
          method: "GET",
        };
      },
    }),
    getSiteNodes: builder.query<NodeResponse, NodeParams>({
      query: (payload) => {
        const { id } = payload;
        return {
          url: `site/${id}/nodes`,
          method: "GET",
        };
      },
      providesTags: ["SiteNodes"],
    }),
    getUnassignedNodes: builder.query<UnassignedNodesResponse, null>({
      query: () => "unassignedNodes",
      providesTags: ["UnassignedNodes"],
    }),
    updateSiteNode: builder.mutation<UpdateNodeResponse, Node>({
      query: (payload) => {
        return {
          url: `site/${payload.SiteId}/node`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["SiteNodes"],
    }),
    assignNode: builder.mutation<
    AssignNodesResponse,
    { node: Node; uninstallReason?: string }
    >({
      query: ({ node, uninstallReason = "" }) => {
        const { SiteId } = node;
        return {
          url: `site/${SiteId}/assignNode?uninstallReason=${uninstallReason}`,
          method: "POST",
          body: node,
        };
      },
      invalidatesTags: ["SiteNodes", "UnassignedNodes"],
    }),
  }),
});

export const {
  useGetNodeQuery,
  useGetSiteNodesQuery,
  useLazyGetSiteNodesQuery,
  useGetUnassignedNodesQuery,
  useUpdateSiteNodeMutation,
  useAssignNodeMutation,
} = siteNodesApi;
