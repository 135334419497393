import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import {
  SiteBaseline,
  GetAllSiteBaselinesResponse,
  CreateSiteBaselineProps,
  CreateSiteBaselineResponse,
  GetActiveSiteBaselineResponse,
} from "./types";

export const siteBaselinesApi = createApi({
  reducerPath: "siteBaselinesApi",
  baseQuery,
  tagTypes: ["SiteBaselines"],
  endpoints: (builder) => ({
    getAllSiteBaselines: builder.query<SiteBaseline[], string>({
      query: (siteId: string) => `site/${siteId}/baselines`,
      transformResponse: (returnValue: GetAllSiteBaselinesResponse) => {
        return returnValue.result;
      },
      providesTags: ["SiteBaselines"],
    }),
    createSiteBaseline: builder.mutation<
    CreateSiteBaselineResponse,
    CreateSiteBaselineProps
    >({
      query: (payload) => ({
        url: `site/${payload.siteId}/baselines`,
        method: "POST",
        params: payload,
      }),
      invalidatesTags: ["SiteBaselines"],
    }),
    getActiveSiteBaseline: builder.query<GetActiveSiteBaselineResponse, string>({
      query: (siteId: string) => {
        return `site/${siteId}/activeBaseline`;
      },
      providesTags: ["SiteBaselines"],
    })
  }),
});

export const { useGetAllSiteBaselinesQuery, useGetActiveSiteBaselineQuery, useCreateSiteBaselineMutation } =
  siteBaselinesApi;
