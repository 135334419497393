import { Button, Modal } from "antd";
import { FC, useEffect, useState } from "react";
import {
  removeMovableOverlay,
  topographicOverlay,
} from "../../../../pages/OrganizationMap/imageOverlayHelper";
import {
  useDeleteSiteImageMutation,
  useGetSiteImageQuery,
} from "../../../../store/siteImage/api";
import { removePushPins } from "../../../../pages/OrganizationMap/mapApi";

interface DeleteDroneImageProps {
  siteId: string;
}

const DeleteDroneImage: FC<DeleteDroneImageProps> = ({ siteId }) => {
  const [hasImageDetails, setHasImageDetails] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data } = useGetSiteImageQuery(siteId);
  const [deleteImageTrigger] = useDeleteSiteImageMutation();

  const initDroneImageDelete = (): void => {
    setIsModalOpen(true);
  };

  const handleOk = async (): Promise<void> => {
    if (
      data?.imageDetails !== undefined &&
      Object.keys(data.imageDetails).length > 0
    ) {
      const extension = data.imageDetails.extension;
      await deleteImageTrigger({ siteId, extension })
        .unwrap()
        .then((res) => {
          if (res.Message === "OK") {
            window.alert("Site Image successfully deleted");
            removeMovableOverlay();
            removePushPins();
            setIsModalOpen(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleCancel = (): void => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (data !== undefined && Object.keys(data.imageDetails).length > 0) {
      topographicOverlay([data]);
    } else {
      setHasImageDetails(false);
    }
  }, []);

  return hasImageDetails ? (
    <div style={{ marginTop: 25 }}>
      <Button danger onClick={initDroneImageDelete}>
        Delete Drone Image
      </Button>
      <Modal
        title="Confirm Delete Site Image"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to delete the drone image?</p>
      </Modal>
    </div>
  ) : (
    <div style={{ marginTop: 10 }}>No Image Available</div>
  );
};

export default DeleteDroneImage;
