import { FC, useState } from "react";
import { DatePicker, Tag } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { addMinutes, subtractMinutes } from "../../helpers/dateTimeHelpers";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  startTimestampSelector,
  endTimestampSelector,
} from "../../store/selections/selector";
import {
  setStartTimestamp,
  setEndTimestamp,
} from "../../store/selections/slice";
import { CommonDurationType, TimeRangePickerProps } from "./types";
import styles from "./styles.module.scss";
import { getDurationHours } from "./helpers";
import {
  getStartOfTodayString,
  getStartOfTomorrowString,
  getUnixSeconds,
} from "../../helpers/dayjsHelpers";
import dayjs, { Dayjs } from "dayjs";

const { RangePicker } = DatePicker;

const BLUE = "#1890ff";
const WHITE = "#ffffff";

const defaultCommonDurationOptions = [
  { label: "24 Hours", durationDays: 1 },
  { label: "7 Days", durationDays: 7 },
  { label: "14 Days", durationDays: 14 },
  { label: "1 Month", durationDays: 30 },
  { label: "3 Months", durationDays: 90 },
];

const TimeRangePicker: FC<TimeRangePickerProps> = ({
  commonDurationOptions = defaultCommonDurationOptions,
}) => {
  const dispatch = useAppDispatch();
  const startTimestamp = useAppSelector(startTimestampSelector);
  const endTimestamp = useAppSelector(endTimestampSelector);

  const daysOptions = commonDurationOptions.map(
    (ele: CommonDurationType) => ele.durationDays
  );
  const maxDays = Math.max(...daysOptions);
  const minDays = Math.min(...daysOptions);
  const minMinutes = minDays * 24 * 60;

  const startOfTodayTimestamp = getStartOfTodayString();
  const startOfTomorrowTimestamp = getStartOfTomorrowString();

  const tomorrowUnixMs = getUnixSeconds(startOfTomorrowTimestamp) * 1000;

  let initialDuration = Math.ceil(
    getDurationHours(startTimestamp, endTimestamp) / 24
  );

  // ensure selected time range does not exceed/deceed allowed duration
  if (initialDuration > maxDays) {
    const maxMinutes = maxDays * 24 * 60;
    const newStartTimestamp = subtractMinutes(endTimestamp, maxMinutes);
    dispatch(setStartTimestamp(newStartTimestamp));
    initialDuration = maxDays;
  }

  if (initialDuration < minDays) {
    const newStartTimestamp = subtractMinutes(endTimestamp, minMinutes);
    dispatch(setStartTimestamp(newStartTimestamp));
    initialDuration = minDays;
  }

  const [selectedDurationDays, setSelectedDurationDays] =
    useState(initialDuration);

  const onRangeChange = (
    dates: null | Array<Dayjs | null>,
    datesStrings: string[]
  ): void => {
    if (dates === null) return;

    const dateDayjsToString = (dateDayjs: Dayjs): string => {
      const year = dateDayjs.year();
      const month = String(dateDayjs.month() + 1).padStart(2, "0");
      const day = String(dateDayjs.date()).padStart(2, "0");

      return `${year}-${month}-${day} 00:00:00`;
    };

    const [startDate, endDate] = dates;
    if (startDate !== null && endDate !== null) {
      const startDateString = dateDayjsToString(startDate);
      let endDateString = dateDayjsToString(endDate);

      // ensure endDate is no later than today
      if (endDate.isAfter(dayjs().startOf("day"))) {
        endDateString = startOfTodayTimestamp;
      }

      dispatch(setStartTimestamp(startDateString));
      dispatch(setEndTimestamp(endDateString));

      const durationDays = Math.ceil(
        getDurationHours(startDateString, endDateString) / 24
      );
      setSelectedDurationDays(durationDays);
    }
  };

  const DurationTag = (durationDays: number, label: string): JSX.Element => {
    const durationMinutes = durationDays * 24 * 60;

    const onClickTag = (): void => {
      setSelectedDurationDays(durationDays);

      const newStartTimestamp = subtractMinutes(endTimestamp, durationMinutes);
      dispatch(setStartTimestamp(newStartTimestamp));
    };

    const tagStyle =
      selectedDurationDays === durationDays
        ? { color: WHITE, backgroundColor: BLUE }
        : {};

    return (
      <Tag
        key={label}
        style={tagStyle}
        className={styles.DurationTag}
        onClick={onClickTag}
      >
        {label}
      </Tag>
    );
  };

  const onClickArrow = (
    sign: 1 | -1 // +1 for forward (right), -1 for backward (left)
  ): void => {
    if (selectedDurationDays === null) return;

    const durationMins = selectedDurationDays * 24 * 60;

    if (sign > 0) {
      let newStartTimestamp = addMinutes(startTimestamp, durationMins);
      let newEndTimestamp = addMinutes(endTimestamp, durationMins);

      // ensure clicking arrow does not go into the future
      if (new Date(newEndTimestamp).getTime() >= tomorrowUnixMs) {
        newEndTimestamp = startOfTomorrowTimestamp;
      }

      if (new Date(newStartTimestamp).getTime() >= tomorrowUnixMs) {
        newStartTimestamp = startOfTodayTimestamp;
      }

      dispatch(setStartTimestamp(newStartTimestamp));
      dispatch(setEndTimestamp(newEndTimestamp));

      const durationDays = Math.ceil(
        getDurationHours(newStartTimestamp, newEndTimestamp) / 24
      );
      setSelectedDurationDays(durationDays);
    } else {
      const newStartTimestamp = subtractMinutes(startTimestamp, durationMins);
      const newEndTimestamp = subtractMinutes(endTimestamp, durationMins);
      dispatch(setStartTimestamp(newStartTimestamp));
      dispatch(setEndTimestamp(newEndTimestamp));
    }
  };

  const onClickToday = (): void => {
    dispatch(setStartTimestamp(startOfTodayTimestamp));
    dispatch(setEndTimestamp(startOfTomorrowTimestamp));
    setSelectedDurationDays(minDays);
  };

  return (
    <div className={styles.ComponentWrapper}>
      <div className={styles.DurationTagsWrapper}>
        <Tag className={styles.DurationTag} onClick={onClickToday}>
          Today
        </Tag>
        {commonDurationOptions.map((ele: CommonDurationType) =>
          DurationTag(ele.durationDays, ele.label)
        )}
      </div>
      <div>
        <LeftOutlined
          className={styles.Arrow}
          onClick={() => onClickArrow(-1)}
        />
        <RangePicker
          value={[dayjs(startTimestamp), dayjs(endTimestamp)]}
          onChange={onRangeChange}
        />
        <RightOutlined
          className={styles.Arrow}
          onClick={() => onClickArrow(1)}
        />
      </div>
    </div>
  );
};

export default TimeRangePicker;
