import { FC } from "react";
import BingMapPPM from "../../../components/MapPPM/BingMapPPM/BingMapPPM";
import styles from "./styles.module.scss";
import { PPMPanelProps } from "./types";

const PPMPanel: FC<PPMPanelProps> = ({
  ppmForSiteMap,
  startDate,
  onDateChange,
  startTime,
  onTimeChange,
  onHoverOverPpm,
  onPlayPPM,
  isPPMPlaying,
  onStopPPM,
  showPPMLayer,
  showLocalTimeForMapPPM,
  handleChangeShowLocalTimeForMapPPM,
  timezone,
  singleNodeSitePAQS,
  showAvgEmissions,
  showVOCLevels,
  ppmEndDateHours,
  setPPMEndDateHours,
}) => {
  return showPPMLayer || showAvgEmissions || showVOCLevels ? (
    <div className={styles.PPMPanel}>
      <BingMapPPM
        ppmEndDateHours={ppmEndDateHours}
        setPPMEndDateHours={setPPMEndDateHours}
        ppmForSiteMap={ppmForSiteMap}
        singleNodeSitePAQS={singleNodeSitePAQS}
        onDateChange={onDateChange}
        startDate={startDate}
        onTimeChange={onTimeChange}
        startTime={startTime}
        onHoverOverPpm={onHoverOverPpm}
        onPlayPPM={onPlayPPM}
        onStopPPM={onStopPPM}
        isPPMPlaying={isPPMPlaying}
        showLocalTimeForMapPPM={showLocalTimeForMapPPM}
        handleChangeShowLocalTimeForMapPPM={handleChangeShowLocalTimeForMapPPM}
        timezone={timezone}
        showAvgEmissions={showAvgEmissions}
        showPPMLayer={showPPMLayer}
        showVOCLevels={showVOCLevels}
      />
    </div>
  ) : null;
};

export default PPMPanel;
