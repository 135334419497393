import { FC, useState, useEffect, ReactNode } from "react";
import { Form, Select, Radio, RadioChangeEvent, Checkbox, Switch } from "antd";
import { Organization } from "../../../store/organizations/types";
import { Node } from "../../../store/nodes/types";
import { Site } from "../../../store/sites/types";
import dataColumns from "../../../pages/Analytics/columns";
import { DATA_TYPES } from "../../../pages/Analytics/types";
import TimeRangePicker from "../../TimeRangePicker/TimeRangePicker";
import { MenuProps, DataType } from "../types";
import styles from "../styles.module.scss";
import { EARTHVIEW_WAREHOUSE } from "../../../pages/Analytics/Analytics";

const LIMITS = {
  NODES: 6,
  COLUMNS_1_NODE: 5,
  COLUMNS_MULTI_NODE: 3,
};

const commonDurationOptions = [
  { label: "1 Day", durationDays: 1 },
  { label: "2 Days", durationDays: 2 },
  { label: "3 Days", durationDays: 3 },
  { label: "7 Days", durationDays: 7 },
];

const { Option } = Select;

const AnalyticsMenu: FC<MenuProps> = ({
  menuSelections,
  orgs,
  handleChangeOrg,
  sites,
  handleChangeSite,
  nodes,
  handleChangeNodes,
  handleChangeColumns,
  handleChangeDataType,
  handleDoFilterInvalidRawDataChange,
  showLocalTime,
  handleChangeShowLocalTime,
  timezone,
}) => {
  const [tempSelectedNodeIds, setTempSelectedNodeIds] = useState(
    menuSelections.nodes.map((node: Node) => node.NodeId)
  );
  const [tempSelectedColumns, setTempSelectedColumns] = useState(
    menuSelections.columns
  );
  const [form] = Form.useForm();
  const formSelections = {
    org: menuSelections?.org?.name ?? "",
    site: menuSelections?.site?.name ?? "",
    nodeIds: menuSelections?.nodes?.map((node: Node) => node?.NodeId),
    columns: menuSelections?.columns ?? [],
    filterInvalidRawData: true,
  };

  const sortedNodes = [...nodes];
  sortedNodes.sort((a: Node, b: Node) => {
    const aName = a.name ?? a.NodeId;
    const bName = b.name ?? b.NodeId;
    return aName.localeCompare(bName);
  });
  sortedNodes.sort((a: Node, b: Node) => {
    if (a.active && !b.active) {
      return -1;
    } else if (!a.active && b.active) {
      return 1;
    }
    return 0;
  });

  const sortedSites = [...sites];
  sortedSites.sort((a: Site, b: Site) => {
    return a.name.localeCompare(b.name);
  });
  sortedSites.sort((a: Site, b: Site) => {
    if (a.active && !b.active) {
      return -1;
    } else if (!a.active && b.active) {
      return 1;
    }
    return 0;
  });

  const orgOptions = orgs.map((org: Organization) => {
    return {
      label: org.name,
      value: org.name,
    };
  });
  // EARTHVIEW_WAREHOUSE on top, the rest alphabetical
  orgOptions.sort(
    (
      a: { label: string; value: string },
      b: { label: string; value: string }
    ) => {
      if (a.value === EARTHVIEW_WAREHOUSE) {
        return -1;
      } else if (b.value === EARTHVIEW_WAREHOUSE) {
        return 1;
      } else {
        return a.label.localeCompare(b.label);
      }
    }
  );

  let columnOptionNames: DataType[] = [];
  switch (menuSelections.dataType) {
    case DATA_TYPES.statusChecks:
      columnOptionNames = dataColumns.statusChecks;
      break;
    case DATA_TYPES.rawNodeSamples:
      columnOptionNames = dataColumns.rawNodeSamples;
      break;
    case DATA_TYPES.nodePPM:
      columnOptionNames = dataColumns.nodePPM;
      break;
  }
  columnOptionNames.sort((a, b) => {
    // regression coefficent options at the bottom
    if (a.key.startsWith("regres")) {
      return 1;
    }
    if (b.key.startsWith("regres")) {
      return -1;
    }
    // alphabetical
    return a.name.localeCompare(b.name);
  });

  const nodeTagRender = (props: {
    label: ReactNode;
    value: string;
  }): JSX.Element => {
    const { label, value } = props;
    const numSelections = tempSelectedNodeIds.length;

    if (String(value) !== tempSelectedNodeIds[0]) return <></>;

    return (
      <div style={{ whiteSpace: "nowrap" }}>
        {label} {numSelections > 1 ? "..." : ""}
      </div>
    );
  };

  const columnTagRender = (props: {
    label: ReactNode;
    value: string;
  }): JSX.Element => {
    const { value } = props;
    return (
      <div style={{ marginRight: 5 }}>
        {value}
        {value === menuSelections.columns.slice(-1)[0] ? "" : ","}
      </div>
    );
  };

  const handleChangeRadio = (event: RadioChangeEvent): void => {
    const newDataType = event.target.value as string;
    handleChangeDataType(newDataType);
  };

  const isMaxNumNodes = (nodeId: string): boolean => {
    return tempSelectedNodeIds.length >= LIMITS.NODES
      ? !tempSelectedNodeIds.includes(nodeId)
      : false;
  };

  const isMaxNumColumns = (colName: string): boolean => {
    const MAX =
      menuSelections.nodes.length > 1
        ? LIMITS.COLUMNS_MULTI_NODE
        : LIMITS.COLUMNS_1_NODE;
    return tempSelectedColumns.length >= MAX
      ? !tempSelectedColumns.includes(colName)
      : false;
  };

  const onChangeNodes = (nodeIds: string[]): void => {
    setTempSelectedNodeIds(nodeIds);
  };

  const onNodesBlur = (): void => {
    const currSelectedNodeIds = menuSelections.nodes.map(
      (node: Node) => node.NodeId
    );
    if (tempSelectedNodeIds === currSelectedNodeIds) {
      return;
    }
    handleChangeNodes(tempSelectedNodeIds);
  };

  const onChangeColumns = (columns: string[]): void => {
    setTempSelectedColumns(columns);
  };

  const onColumnsBlur = (): void => {
    handleChangeColumns(tempSelectedColumns);
  };

  useEffect(() => {
    form.setFieldsValue(formSelections);
    setTempSelectedColumns(menuSelections.columns);
  }, [menuSelections]);

  return (
    <Form form={form} layout="vertical" initialValues={formSelections}>
      <div className={styles.DataTypeWrapper}>
        <Radio.Group
          defaultValue="Raw Data"
          onChange={(event) => handleChangeRadio(event)}
          size="small"
        >
          <Radio.Button value="Raw Data">Raw Data</Radio.Button>
          <Radio.Button value="NodePPM">Node PPM</Radio.Button>
          <Radio.Button value="Statuses">Statuses</Radio.Button>
        </Radio.Group>
      </div>
      <Form.Item name="org" label="Organization">
        <Select options={orgOptions} onChange={handleChangeOrg} />
      </Form.Item>
      <Form.Item name="site" label="Site">
        <Select
          onChange={handleChangeSite}
          optionLabelProp="label"
          disabled={menuSelections?.org?.name === EARTHVIEW_WAREHOUSE}
        >
          {sortedSites.map((site: Site) => (
            <Option key={site.SiteId} label={site.name}>
              {site.name}
              {" - "}
              {site.active ? (
                <span style={{ color: "green" }}>Active</span>
              ) : (
                <span style={{ color: "red" }}>Inactive</span>
              )}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="nodeIds" label="Nodes">
        <Select
          mode="multiple"
          onChange={onChangeNodes}
          onBlur={onNodesBlur}
          tagRender={nodeTagRender}
          dropdownRender={(menu) => (
            <div style={{ display: "block" }}>{menu}</div>
          )}
          optionLabelProp="label"
          style={{ maxWidth: "300px" }}
        >
          {sortedNodes.map((node: Node) => (
            <Option
              key={node.NodeId}
              label={node.name !== "UNASSIGNED" ? node.name : node.NodeId}
              disabled={isMaxNumNodes(node.NodeId)}
            >
              {node.name !== "UNASSIGNED" ? node.name : node.NodeId}
              {" - "}
              {node.active ? (
                <span style={{ color: "green" }}>Active</span>
              ) : (
                <span style={{ color: "red" }}>Inactive</span>
              )}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <div className={styles.OnOffSelectionWrapper}>
        <div className={styles.TimezoneSelectionWrapper}>
          <span>Timezone: {showLocalTime ? timezone : "UTC"}</span>
          <Switch onClick={() => handleChangeShowLocalTime(!showLocalTime)} />
        </div>
        {menuSelections.dataType === DATA_TYPES.rawNodeSamples ? (
          <Form.Item name="filterInvalidRawData">
            <Checkbox
              checked={menuSelections.doFilterInvalidRawData}
              onChange={handleDoFilterInvalidRawDataChange}
            >
              Filter Invalid Reads
            </Checkbox>
          </Form.Item>
        ) : (
          <></>
        )}
      </div>
      <Form.Item name="columns" label="Columns">
        <Select
          mode="multiple"
          onChange={onChangeColumns}
          onBlur={onColumnsBlur}
          tagRender={columnTagRender}
          value={menuSelections.columns}
        >
          {columnOptionNames.map((colName: DataType) => {
            return (
              <Option key={colName.key} disabled={isMaxNumColumns(colName.key)}>
                {`${colName.key}: ${colName.name}`}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <TimeRangePicker commonDurationOptions={commonDurationOptions} />
      {/* DOWNLOAD DATA BUTTON TO BE IMPLEMENTED */}
      {/* <Form.Item style={{ marginBottom: 0 }}>
        <div style={{ marginTop: "15px" }} >
          <Button type="primary" htmlType="submit" size="large">
            Request Download
          </Button>
        </div>
      </Form.Item> */}
    </Form>
  );
};

export default AnalyticsMenu;
