import React, { useEffect } from "react";

const BingMapRenderer = ({ mapContainerRef, makeMap }) => {
  useEffect(() => {
    if (window.initialLoad === undefined) {
      window.makeMap = () => {
        makeMap();
        window.initialLoad = true;
      };
    } else {
      makeMap();
    }
  }, []);
  return (
    <div ref={mapContainerRef} style={{ width: "100%" }} id="_bing_map"></div>
  );
};

// this validation prevents the component from rendering everytime state updates on other components
const shouldReRender = (currentProps, newProps) => {
  return currentProps.id === newProps.id;
};

export default React.memo(BingMapRenderer, shouldReRender);
