import { FC, useEffect } from "react";
import { movableOverlay, removeOverlayById } from "../../../pages/OrganizationMap/imageOverlayHelper";
import { Table } from "antd";
import styles from "./styles.module.scss";
import { useGetNodeOptimizationImageQuery } from "../../../store/nodeOptimizationImages/api";
import { currentSiteSelector } from "../../../store/sites/selector";
import { useAppSelector } from "../../../store/hooks";
import { LayerSwitch } from "../../LayersSwitch/LayersSwitch";
import { removePushPins } from "../../../pages/OrganizationMap/mapApi";

interface NodeOptimizationProps {
  requireDroneImage: boolean;
  setShowDroneImageLayer: () => void;
  showDroneImageLayer: boolean;
}

const NodeOptimization: FC<NodeOptimizationProps> = ({
  requireDroneImage,
  setShowDroneImageLayer,
  showDroneImageLayer,
}) => {
  const site = useAppSelector(currentSiteSelector);
  const siteId = site?.SiteId ?? "";
  const lat = ((site?.details) != null) ? site.details.latAPI : "";
  const lon = ((site?.details) != null) ? site.details.longAPI : "";
  const result = useGetNodeOptimizationImageQuery(siteId);
  const url = (result.data != null) ? result.data.url : "";
  const nodeCoverage = (result.data != null) ? result.data.nodeInformation.node_coverage : null;
  const data = (nodeCoverage != null) ? nodeCoverage.map((x) => { return { node: x.node, nodeCoverage: `${x.node_coverage}%` }; }) : [];
  const getRowClassName = (_: unknown, index: number): string => {
    return index % 2 === 0 ? styles.EvenRow : styles.OddRow;
  };
  const movableOverlayId = "node-optimization";

  useEffect(() => {
    if (url !== "") {
      movableOverlay(lat, lon, url, 1, movableOverlayId);
    }
  }, [url, showDroneImageLayer]);

  useEffect(() => {
    return () => {
      removeOverlayById(movableOverlayId);
      removePushPins();
    };
  }, []);

  const columns = [
    {
      title: "Node",
      dataIndex: "node",
      key: "node",
    },
    {
      title: "Node Coverage (%)",
      dataIndex: "nodeCoverage",
      key: "nodeCoverage",
    },
  ];
  return (
    <div>
      {requireDroneImage && (
        <div>
          {LayerSwitch(
            "Drone Image",
            setShowDroneImageLayer,
            showDroneImageLayer
          )}
        </div>
      )}
      <>
        Wind Roses are generated on site creation from the nearest weather
        station.
      </>
      <br />
      <br />
      <>As a reminder winds are marked from where they are coming from.</>
      <br />
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowClassName={getRowClassName}
      />
    </div>
  );
};

export default NodeOptimization;
