import { FC, CSSProperties } from "react";
import { Select } from "antd";

interface IncidentTypeSelectionProps {
  incidentType: string | undefined;
  onChange: (x: string) => void;
  style?: CSSProperties;
}
interface IncidentTypes {
  label: string;
  value: string;
}
const incidentTypes: IncidentTypes[] = [
  { label: "Fugitive", value: "fugitive" },
  { label: "Operational", value: "operational" },
  { label: "Maintenance", value: "maintenance" },
  { label: "Unknown", value: "unknown" }
];

const IncidentTypeSelection: FC<IncidentTypeSelectionProps> = ({
  incidentType,
  onChange,
  style,
}) => {
  return (
    <Select
      value={incidentType}
      onChange={onChange}
      style={style}
    >
        {
            incidentTypes.map((x) => <Select.Option value={x.value} key={x.value}>{x.label}</Select.Option>)
        }
    </Select>
  );
};

export default IncidentTypeSelection;
