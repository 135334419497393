import {
  NodePPMForSiteMap,
  PPMForSiteMap,
} from "../../store/ppmForSiteMap/types";
import { TimestreamNodePPM } from "../../store/timestreamNodePPM/types";

export const collapseTimestreamNodeData = (
  rawPPM: undefined | TimestreamNodePPM[]
): PPMForSiteMap[] => {
  if (rawPPM !== undefined && rawPPM.length > 0) {
    const uniqueTimestamps = Array.from(
      new Set(rawPPM.map((item) => item.Timestamp))
    );
    const groupedItems = uniqueTimestamps.map((timestamp) => {
      const reads: NodePPMForSiteMap[] = rawPPM
        .filter((item) => item.Timestamp === timestamp)
        .map((element) => {
          const windDirection =
            element.windDIR === undefined ? 0 : element.windDIR;
          const windSpeed = element.windMPH === undefined ? 0 : element.windMPH;
          return {
            windDIR: windDirection,
            windMPH: windSpeed,
            nodeId: element.NodeId,
            ch4: element.gas1D,
            voc: element.gas2D,
          };
        });

      const maxGas1DItem = reads.reduce(
        (max, item) => (item.ch4 > max.ch4 ? item : max),
        reads[0]
      );
      const maxGas2DItem = reads.reduce(
        (max, item) => (item.voc > max.voc ? item : max),
        reads[0]
      );

      return {
        Timestamp: timestamp,
        reads,
        maxCh4: maxGas1DItem.ch4,
        maxNodeId: maxGas1DItem.nodeId,
        maxVOC: maxGas2DItem.voc,
        maxVOCNodeId: maxGas2DItem.nodeId,
      };
    });
    return groupedItems;
  } else {
    return [];
  }
};
