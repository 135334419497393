import { RootState } from "..";
import { Site } from "./types";

export const currentSiteSelector = (state: RootState): Site | null =>
  state.sites.currentSite;

export const currentSiteRequireDroneImage = (state: RootState): boolean => {
  return state.sites.currentSite !== null
    ? state.sites.currentSite.requireDroneImage
    : false;
};
