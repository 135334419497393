import { Button, Input, List, Modal, Radio, RadioChangeEvent, Select } from "antd";
import { TableOutlined } from "@ant-design/icons";
import { FC, useEffect, useRef, useState } from "react";
import {
  calculateGridHelper,
  defaultGridSizeOptions,
  defaultPointObject,
} from "../../../pages/SiteMapv2/helpers";
import {
  drawSiteGrid,
  getCellMatrix,
  initReferencePointClickCallback,
  removeClickHandler,
  removeGrid,
} from "./multiGridHelper";
import { Grid } from "../../../pages/SiteMapv2/types";
import { removePushPins } from "../../../pages/OrganizationMap/mapApi";
import styles from "../GridControls/styles.module.scss";
import { useCreateSiteGridConfigMutation } from "../../../store/gridConfig/api";
import { useCreateSiteGridsMutation } from "../../../store/siteGrids/api";
import { generateTimestamp } from "../../../helpers/dateTimeHelpers";
import { useAppSelector } from "../../../store/hooks";
import { currentSiteSelector } from "../../../store/sites/selector";
import { LayerSwitch } from "../../LayersSwitch/LayersSwitch";

const errorDialog =
  "Please ensure that you've selected the bottom left pin as the South West point and the top right pin as the North East point on the map.";
const successCreateDialog = "Site Grid Successfully Updated";
const errorCreateDialog =
  "Site Grid Update Failed, please contact Earthview team or try again";

interface MultipleGridContollerProps {
  isMultipleGrid: boolean;
  requireDroneImage: boolean;
  showDroneImageLayer: boolean;
  setShowDroneImageLayer: () => void;
}

const MultiGridController: FC<MultipleGridContollerProps> = ({
  isMultipleGrid,
  requireDroneImage,
  showDroneImageLayer,
  setShowDroneImageLayer,
}) => {
  const currentSite = useAppSelector(currentSiteSelector);
  const [cellSize, setCellSize] = useState(10);
  const [point1, setPoint1] = useState(defaultPointObject);
  const [point2, setPoint2] = useState(defaultPointObject);
  const callbackId = useRef(null);
  const [currentSelectedPoint, setCurrentSelectedPoint] = useState(1);
  const gridRef = useRef<Grid>();
  const [grids, setGrids] = useState<Grid[]>([]);
  const [gridName, setGridName] = useState("");
  const [createSiteGridsTrigger] = useCreateSiteGridsMutation(); // multi grid
  const [createSiteGridConfTrigger] = useCreateSiteGridConfigMutation(); // single grid
  const [setupId, setSetupId] = useState(+new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (): void => {
    setIsModalOpen(true);
  };

  const handleOk = (): void => {
    onSaveConfig();
    setIsModalOpen(false);
  };

  const handleCancel = (): void => {
    setIsModalOpen(false);
  };

  const siteId = currentSite?.SiteId ?? "";

  const onChangeCellSize = (newSize: number): void => {
    setCellSize(newSize);
  };

  const drawGrids = (_grids: Grid[]): void => {
    _grids.forEach((g) => {
      const { minLong, maxLong, minLat, maxLat, gridX, gridY } = g;
      const _cellMatrix = getCellMatrix(g);
      drawSiteGrid(
        _cellMatrix,
        minLong,
        maxLong,
        minLat,
        maxLat,
        gridX,
        gridY,
        false,
      );
    });
  };

  const onGenerateGrid = (): void => {
    removeGrid();
    drawGrids(grids);
    const _grid = calculateGridHelper(
      "GRID",
      point1.latitude,
      point1.longitude,
      point2.latitude,
      point2.longitude,
      cellSize
    );
    const { minLong, maxLong, minLat, maxLat, gridX, gridY } = _grid;
    const _cellMatrix = getCellMatrix(_grid);
    gridRef.current = _grid;
    drawSiteGrid(
      _cellMatrix,
      minLong,
      maxLong,
      minLat,
      maxLat,
      gridX,
      gridY,
      true,
      gridRef,
      requireDroneImage
    );
  };

  const onChangeSelectedPoint = (newValue: number): void => {
    setCurrentSelectedPoint(newValue);
    if (newValue !== currentSelectedPoint) {
      removeClickHandler(callbackId);
      if (newValue === 1) {
        initReferencePointClickCallback(
          callbackId,
          setPoint1,
          "bottom left corner",
          point2,
          "top right corner"
        );
      } else {
        initReferencePointClickCallback(
          callbackId,
          setPoint2,
          "top right corner",
          point1,
          "bottom left corner"
        );
      }
    }
  };

  const onAddCurrentGrid = (): void => {
    if (gridRef.current !== undefined) {
      gridRef.current.gridName = gridName;
      gridRef.current.Timestamp = generateTimestamp();
      gridRef.current.SiteId = siteId;
      gridRef.current.SetupId = String(setupId);
      const _grids = [...grids, gridRef.current];
      gridRef.current = undefined;
      removePushPins();
      removeGrid();
      removeClickHandler(callbackId);
      drawGrids(_grids);
      setPoint1(defaultPointObject);
      setPoint2(defaultPointObject);
      setCurrentSelectedPoint(1);
      setGrids(_grids);
      setGridName("");
      initReferencePointClickCallback(
        callbackId,
        setPoint1,
        "bottom left corner",
        null,
        ""
      );
    }
  };

  const onRemoveGrid = (index: number): void => {
    removeGrid();
    const _grids = [...grids];
    _grids.splice(index, 1);
    setGrids(_grids);
    drawGrids(_grids);
    if (gridRef.current !== null && gridRef.current !== undefined) {
      const { minLong, maxLong, minLat, maxLat, gridX, gridY } =
        gridRef.current;
      const _cellMatrix = getCellMatrix(gridRef.current);
      drawSiteGrid(
        _cellMatrix,
        minLong,
        maxLong,
        minLat,
        maxLat,
        gridX,
        gridY,
        true,
        gridRef,
        requireDroneImage
      );
    }
  };

  const onSaveConfig = (): void => {
    if (isMultipleGrid) {
      createSiteGridsTrigger({ grids, siteId })
        .unwrap()
        .then((result) => {
          console.log(result);
          setSetupId(+new Date());
          window.alert(successCreateDialog);
        })
        .catch((e) => {
          console.log(e);
          window.alert(errorCreateDialog);
        });
    } else {
      if (gridRef.current !== undefined) {
        const timestamp = generateTimestamp();
        createSiteGridConfTrigger({
          ...gridRef.current,
          SiteId: siteId,
          Timestamp: timestamp,
        })
          .unwrap()
          .then((result) => {
            if (result !== null && result.Message === "SUCCESS") {
              window.alert(successCreateDialog);
            } else {
              window.alert(errorCreateDialog);
            }
          })
          .catch((e) => {
            console.log(e);
            window.alert(errorCreateDialog);
          });
      }
    }
  };

  useEffect(() => {
    if (callbackId.current === null) {
      initReferencePointClickCallback(
        callbackId,
        setPoint1,
        "bottom left corner",
        null,
        ""
      );
    }
  }, []);

  return (
    <div>
      {requireDroneImage && (
        <div>
          {LayerSwitch(
            "Drone Image",
            setShowDroneImageLayer,
            showDroneImageLayer
          )}
        </div>
      )}
      <Modal
        title="Confirm Grid Changes"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to save this grid configuration?</p>
      </Modal>
      <h3>Set Grid Reference Points</h3>
      <Radio.Group
        onChange={(e: RadioChangeEvent) => {
          onChangeSelectedPoint(e.target.value as number);
        }}
        value={currentSelectedPoint}
      >
        <Radio value={1}>Bottom Left Corner Reference Point</Radio>
        <Radio value={2}>Top Right Corner Reference Point</Radio>
      </Radio.Group>
      <div className={styles.PointCoordinates}>
        <h4>Bottom Left Corner</h4>
        <div>
          <b>Latitude:</b> {point1.latitude}
          <div />
          <b>Longitude:</b> {point1.longitude}
        </div>
      </div>
      <div className={styles.PointCoordinates}>
        <h4>Top Right Corner</h4>
        <div>
          <b>Latitude:</b> {point2.latitude}
          <div />
          <b>Longitude:</b> {point2.longitude}
        </div>
      </div>
      <div>
        <h4>Define Cell Size</h4>
        <Select
          defaultValue={cellSize}
          onChange={(e) => onChangeCellSize(e)}
          options={defaultGridSizeOptions}
        />
      </div>
      {isMultipleGrid && (
        <div className={styles.MarginTop15}>
          <div>Grid Name</div>
          <Input
            value={gridName}
            onChange={(e) => setGridName(e.target.value)}
          />
        </div>
      )}
      <div className={styles.MarginTop15} style={{ display: "flex" }}>
        <Button
          onClick={() => {
            point1 !== defaultPointObject && point2 !== defaultPointObject
              ? point1.latitude < point2.latitude &&
                point1.longitude < point2.longitude
                ? onGenerateGrid()
                : window.alert(errorDialog)
              : window.alert("Please select two points on the map.");
          }}
          type="primary"
          icon={<TableOutlined />}
          style={{ color: "white", background: "#52c41a", border: "none" }}
        >
          Generate Grid
        </Button>{" "}
        {isMultipleGrid && (
          <Button
            onClick={onAddCurrentGrid}
            style={{ marginLeft: 10 }}
            type="link"
          >
            Add Grid
          </Button>
        )}
      </div>
      {isMultipleGrid && (
        <List
          className={styles.MarginTop15}
          dataSource={grids}
          renderItem={(g, idx) => (
            <List.Item
              key={idx}
              style={{
                display: "flex",
                backgroundColor: idx % 2 === 0 ? "#f9f9f9" : "#fff",
                padding: "10px",
              }}
            >
              {g.gridName}
              <div style={{ flex: 1 }} />
              <Button
                size="small"
                type="link"
                danger
                style={{ marginLeft: 10 }}
                onClick={() => onRemoveGrid(idx)}
              >
                Remove
              </Button>
            </List.Item>
          )}
        />
      )}
      <div style={{ marginTop: isMultipleGrid ? 100 : 10 }}>
        <Button type="primary" onClick={showModal}>
          Save Configuration
        </Button>
      </div>
    </div>
  );
};

export default MultiGridController;
