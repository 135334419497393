import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { NodeInstallHistoryParams, NodeInstallHistoryResponse } from "./types";

export const nodeInstallHistoryApi = createApi({
  reducerPath: "nodeInstallHistoryApi",
  baseQuery,
  endpoints: (builder) => ({
    getNodeInstallHistory: builder.query<
    NodeInstallHistoryResponse,
    NodeInstallHistoryParams
    >({
      query: (params) => {
        const { id } = params;
        return {
          url: `nodes/${id}/installHistory`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetNodeInstallHistoryQuery } = nodeInstallHistoryApi;
