export const NodePushPin = (
  color: string,
  borderColor: string,
  radius: number,
  degree: number,
  ppm: string,
  windMPH: string,
  windDIR: string,
  voc: string,
  vocRatio: string,
  showVOC: boolean,
  useWind: boolean
): string => {
  const vocRatioNum = parseFloat(vocRatio);
  let vocFillCollor = "green";

  if (vocRatioNum > 0.7) {
    vocFillCollor = "red";
  } else if (vocRatioNum > 0.3) {
    vocFillCollor = "yellow";
  }

  const barHeight = 20;
  const barWidth = 15;
  const filledHeight = barHeight * vocRatioNum;

  let xVOCPosition = 75;

  if (radius === 30) {
    xVOCPosition = 85;
  }

  return `<svg width="100px" height="100px" xmlns="http://www.w3.org/2000/svg">
    <!-- Outer circle  -->
    <circle cx="50" cy="70" r="${radius}" fill="${color}" stroke="${borderColor}" stroke-width="3" opacity="0.7"/>
    <!-- Conditional display of arrow or inner circle -->
    <g>
      ${
        degree > 0
          ? `<polygon points="45,65 50,55 55,65" fill="white" transform="rotate(${degree}, 50, 70)" stroke="black" stroke-width="1"/>`
          : ""
      }
    </g>
    ${
      useWind
        ? "<circle cx=\"50\" cy=\"70\" r=\"3\" fill=\"white\" />"
        : "<line x1=\"45\" y1=\"70\" x2=\"55\" y2=\"70\" stroke=\"black\" stroke-width=\"2\"/>"
    }

    <!-- transparent background box -->
    <rect x="0" y="0" width="75" height="55" fill="rgba(0,0,0,0.5)" rx="10" ry="10" />

    <!-- Text for stats -->
    <text x="2" y="14" font-family="Arial" font-size="14" fill="white" font-weight="bold">
      ${ppm} ppm
    </text>
    <text x="2" y="26" font-family="Arial" font-size="14" fill="white" font-weight="bold">
      ${windMPH} mph
    </text>
    <text x="2" y="38" font-family="Arial" font-size="14" fill="white" font-weight="bold">
      ${windDIR} deg
    </text>
    ${
      showVOC
        ? `
      <text x="2" y="50" font-family="Arial" font-size="14" fill="white" font-weight="bold">
        ${voc} voc ppm
      </text>
      <!-- VOC ratio bar background -->
      <rect x="${xVOCPosition}" y="65" width="${barWidth}" height="${barHeight}" fill="white" opacity="0.7" />
      <!-- VOC ratio bar fill -->
      <rect x="${xVOCPosition}" y="${
            65 + barHeight
          }" width="${barWidth}" height="${filledHeight}" fill="${vocFillCollor}"  opacity="0.5"/>
    `
        : ""
    }
  </svg>`;
};
