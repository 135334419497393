import React, { useEffect, useState } from "react";
import { Button, Slider } from "antd";

import {
  drawOverlayOnly,
  getOverlayBounds,
  movableOverlay,
  removeMovableOverlay,
  topographicOverlay,
} from "../../../../pages/OrganizationMap/imageOverlayHelper";
import {
  useGetSiteImageQuery,
  useUpdateSiteImageMutation,
} from "../../../../store/siteImage/api";
import { removePushPins } from "../../../../pages/OrganizationMap/mapApi";

interface UploadFormProps {
  siteId: string;
}

const EditImageForm: React.FC<UploadFormProps> = ({ siteId }) => {
  const [hasImageDetails, setHasImageDetails] = useState(true);
  const { data } = useGetSiteImageQuery(siteId);
  const [updateImageTrigger] = useUpdateSiteImageMutation();

  const handleRotate = (newRotation: number): void => {
    if (data !== undefined) {
      drawOverlayOnly(siteId, newRotation, false, data.url);
    }
  };

  const handleChangeOpacity = (value: number): void => {
    const newOpacity = value / 100;
    if (data !== undefined) {
      drawOverlayOnly(siteId, false, newOpacity, data.url);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const onSave = async (): Promise<void> => {
    setIsLoading(true);
    const extension =
      data?.imageDetails.extension !== undefined
        ? data.imageDetails.extension
        : "";
    const imageDetails = {
      ...getOverlayBounds(siteId),
      SiteId: siteId,
      extension,
    };
    await updateImageTrigger(imageDetails)
      .unwrap()
      .then((res) => {
        if (res.Message === "OK") {
          window.alert("Site Image successfully updated");
          removeMovableOverlay();
          removePushPins();
          topographicOverlay([{ imageDetails, url: data?.url }]);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (data !== undefined && Object.keys(data.imageDetails).length > 0) {
      const centerLatitude =
        (data.imageDetails.topLeft.latitude +
          data.imageDetails.bottomRight.latitude) /
        2;
      const centerLongitude =
        (data.imageDetails.topLeft.longitude +
          data.imageDetails.bottomRight.longitude) /
        2;
      movableOverlay(centerLatitude, centerLongitude, data.url, 0.5, siteId, 0);
    } else {
      setHasImageDetails(false);
    }
  }, []);

  return hasImageDetails ? (
    <div style={{ marginTop: 10 }}>
      <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
        Opacity
        <Slider
          min={0}
          max={100}
          defaultValue={50}
          onChange={handleChangeOpacity}
          style={{ width: "300px", margin: "0 auto" }}
        />
      </div>
      <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
        Rotation
        <Slider
          min={-180}
          max={180}
          defaultValue={0}
          onChange={handleRotate}
          style={{ width: "300px", margin: "0 auto" }}
        />
      </div>
      <Button onClick={onSave} loading={isLoading}>
        Save
      </Button>
    </div>
  ) : (
    <div style={{ marginTop: 10 }}>No Image Available</div>
  );
};

export default EditImageForm;
