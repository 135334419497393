import { FC, useState, useEffect, ChangeEvent } from "react";
import { useGetOrganizationsQuery } from "../../store/organizations/api";
import { Space, Table, Button, Input, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Organization } from "../../store/organizations/types";
import { useAppDispatch } from "../../store/hooks";
import { setGoBackRoute, setPageTitle } from "../../store/ui/slice";
import { useNavigate } from "react-router-dom";
import { setCurrentOrganization } from "../../store/organizations/slice";
import styles from "./styles.module.scss";

const Organizations: FC = () => {
  const { data, isError, isLoading } = useGetOrganizationsQuery(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [filteredTableData, setFilteredTableData] = useState<Organization[]>([]);

  const navigateAndLinkOrg = (record: Organization): void => {
    dispatch(setCurrentOrganization(record));
    navigate(`/organization/${record.OrgId}/sites`);
  };

  const startEditOrg = (record: Organization): void => {
    dispatch(setCurrentOrganization(record));
    navigate(`/organizations/${record.OrgId}/edit`);
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const search = e.target.value.toLowerCase();
    setFilteredTableData(tableData.filter((orgObj) => {
      const name = orgObj.name.toLowerCase();
      return name.includes(search);
    }));
  };

  const columns: ColumnsType<Organization> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: Organization) => (
        <a onClick={() => navigateAndLinkOrg(record)}>{text}</a>
      ),
      sorter: (a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Action",
      key: "OrgId",
      render: (record: Organization) => (
        <Space size="middle">
          <a onClick={() => startEditOrg(record)}>Edit</a>
        </Space>
      ),
    },
  ];

  let tableData: Organization[] = [];

  if (!isError && !isLoading && data !== undefined) {
    tableData = data?.map((el) => {
      return { ...el, key: el.OrgId };
    });
  }

  useEffect(() => {
    dispatch(setPageTitle("Organizations"));
    dispatch(setGoBackRoute(""));
  }, []);

  useEffect(() => {
    setFilteredTableData(tableData);
  }, [isLoading]);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "17.5rem" }}>
          <Tooltip>
            <Input
              placeholder="Search..."
              allowClear
              onChange={(event) => onChangeSearch(event)}
            />
          </Tooltip>
        </div>
        <div className={styles.ButtonWrapper}>
          <Button type="primary" onClick={() => navigate("new")}>
            Create Organization
          </Button>
        </div>
      </div>
      <Table columns={columns} dataSource={filteredTableData} loading={isLoading} />
    </div>
  );
};

export default Organizations;
