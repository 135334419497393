import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "..";

export const baseUrl = process.env.REACT_APP_NODE_BLU2_API;
export const baseUrlPython = process.env.REACT_APP_PYTHON_BLU2_API;

export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const state: RootState = getState() as RootState;

    if (state.auth !== null && state.auth.token !== "") {
      headers.set("Authorization", state.auth.token as string);
    }

    return headers;
  },
});

export const baseQueryPython = fetchBaseQuery({
  baseUrl: baseUrlPython,
  prepareHeaders: (headers, { getState }) => {
    const state: RootState = getState() as RootState;

    if (state.auth !== null && state.auth.token !== "") {
      headers.set("Authorization", state.auth.token as string);
    }

    return headers;
  },
});
