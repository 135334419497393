import { FC, useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";
import { Line } from "react-chartjs-2";
import { useGetGlobalConfigQuery } from "../../../store/globalConfig/api";
import { TimeSeriesUnit } from "../../../pages/SiteStats/types";
import { getTimestampFromMilliseconds } from "../../../helpers/dayjsHelpers";
import {
  mcfHrToSiteUnits,
  QuantityUnit,
  RateUnit,
} from "../../../helpers/unitsHelpers";
import { AverageEmissionRatesChartProps } from "./types";
import styles from "../styles.module.scss";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin,
  annotationPlugin
);

const UNIT_HOURS_THRESHOLD = 48;

const AverageEmissonRatesChart: FC<AverageEmissionRatesChartProps> = ({
  siteName,
  units,
  data,
  timezone,
  resetChartKey,
  chartTimerange,
  handleSetChartTimerange,
  siteBaseline,
  epaBaselineDisplay,
  rollingAverages,
}) => {
  const globalConfig = useGetGlobalConfigQuery(null).data;
  const timezoneDisplay = timezone ?? "UTC";
  const unitsDisplay =
    units === QuantityUnit.MCF
      ? RateUnit.MCF_DAY
      : RateUnit.KG_H;
  const doRenderEpaThresholds = siteBaseline !== null;

  const [xRange, setXRange] = useState({
    min: new Date(chartTimerange.startTimestamp).getTime(),
    max: new Date(chartTimerange.endTimestamp).getTime(),
  });

  const chart = ChartJS.getChart("chart-average-emission-rates");
  const title = `Average Emission Rates (${unitsDisplay})`;
  const chartData = {
    datasets: [
      {
        label: `${siteName}`,
        data: data.map((el) => {
          return {
            x: el.Timestamp,
            y: String(
              mcfHrToSiteUnits(el.average_emission_rates, units, globalConfig)
            ),
          };
        }),
        borderColor: "rgba(219, 179, 177, 0.7)",
        radius: 0,
        spanGaps: true,
        yAxisID: "y",
      },
      {
        label: "Site 7-Day Average",
        data: rollingAverages?.map((el) => {
          return {
            x: el.Timestamp,
            y: String(
              mcfHrToSiteUnits(
                el["7_days_average_emission_rates"],
                units,
                globalConfig
              )
            ),
          };
        }),
        borderColor: "orange",
        backgroundColor: "orange",
        radius: 0,
        spanGaps: true,
        yAxisID: "y",
      },
      {
        label: "Site 90-Day Average",
        data: rollingAverages?.map((el) => {
          return {
            x: el.Timestamp,
            y: String(
              mcfHrToSiteUnits(
                el["90_days_average_emission_rates"],
                units,
                globalConfig
              )
            ),
          };
        }),
        borderColor: "red",
        backgroundColor: "red",
        radius: 0,
        spanGaps: true,
        yAxisID: "y",
      },
    ],
  };

  let timeUnit = "day";
  if (chart != null) {
    const min = dayjs(chartTimerange.startTimestamp);
    const max = dayjs(chartTimerange.endTimestamp);
    const timeRangeHours = max.diff(min, "hour");
    timeUnit = timeRangeHours <= UNIT_HOURS_THRESHOLD ? "hour" : "day";
  }

  const handleZoom = (chart: ChartJS): void => {
    const { min, max } = chart.scales.x;
    setXRange({ min, max });

    const newStartTimestamp = getTimestampFromMilliseconds(min, timezone);
    const newEndTimestamp = getTimestampFromMilliseconds(max, timezone);
    handleSetChartTimerange(newStartTimestamp, newEndTimestamp);
  };
  const annotations = [
    {
      type: "line" as const,
      mode: "horizontal" as const,
      value:
        siteBaseline?.epa7DayThresholdMCFH !== undefined
          ? mcfHrToSiteUnits(
            siteBaseline.epa7DayThresholdMCFH,
            units,
            globalConfig
          )
          : 0,
      scaleID: "y",
      borderColor: "orange",
      borderDash: [5, 5],
      display: doRenderEpaThresholds && epaBaselineDisplay.doShow7Day,
      label: {
        display: doRenderEpaThresholds && epaBaselineDisplay.doShow7Day,
        content: "7-Day EPA Threshold",
        position: "center" as const,
        backgroundColor: "orange",
        color: "white",
        padding: 4,
      },
    },
    {
      type: "line" as const,
      mode: "horizontal" as const,
      value:
        siteBaseline?.epa90DayThresholdMCFH !== undefined
          ? mcfHrToSiteUnits(
            siteBaseline.epa90DayThresholdMCFH,
            units,
            globalConfig
          )
          : 0,
      scaleID: "y",
      borderColor: "red",
      borderDash: [5, 5],
      display: doRenderEpaThresholds && epaBaselineDisplay.doShow90Day,
      label: {
        display: doRenderEpaThresholds && epaBaselineDisplay.doShow90Day,
        content: "90-Day EPA Threshold",
        position: "center" as const,
        backgroundColor: "red",
        color: "white",
        padding: 4,
      },
    },
    {
      type: "line" as const,
      mode: "horizontal" as const,
      value:
        siteBaseline?.baselineMcfh !== null &&
        siteBaseline?.baselineMcfh !== undefined
          ? mcfHrToSiteUnits(siteBaseline.baselineMcfh, units, globalConfig)
          : 0,
      scaleID: "y",
      borderColor: "gray",
      borderDash: [5, 5],
      display: doRenderEpaThresholds && epaBaselineDisplay.doShowSiteBaseline,
      label: {
        display: doRenderEpaThresholds && epaBaselineDisplay.doShowSiteBaseline,
        content: "Site 30-Day Baseline",
        position: "center" as const,
        backgroundColor: "gray",
        color: "white",
        padding: 4,
      },
    },
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false as const,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: title,
        font: {
          size: 24,
          weight: "bold" as const,
        },
      },
      datalabels: {
        display: true,
        align: "center",
        color: "black",
      },
      zoom: {
        zoom: {
          mode: "x" as const,
          drag: {
            enabled: true,
          },
          onZoomComplete: ({ chart }: { chart: ChartJS }) => handleZoom(chart),
        },
      },
      annotation: { annotations },
    },
    scales: {
      x: {
        type: "time" as const,
        time: {
          unit: timeUnit as TimeSeriesUnit,
        },
        title: {
          display: true,
          text: `Timestamp (${timezoneDisplay})`,
          font: {
            size: 20,
            weight: "bold" as const,
          },
        },
        grid: {
          drawOnChartArea: false,
        },
        min: xRange.min,
        max: xRange.max,
      },
      y: {
        type: "linear" as const,
        title: {
          display: true,
          text: `Avg. Emission Rate (${unitsDisplay})`,
          font: {
            size: 20,
            weight: "bold" as const,
          },
        },
        position: "left" as const,
        min: 0,
      },
    },
  };

  useEffect(() => {
    setXRange({
      min: new Date(chartTimerange.startTimestamp).getTime(),
      max: new Date(chartTimerange.endTimestamp).getTime(),
    });
  }, [resetChartKey, data]);

  return (
    <div className={styles.ChartWrapper}>
      <Line
        id={"chart-average-emission-rates"}
        options={options}
        data={chartData}
      ></Line>
    </div>
  );
};

export default AverageEmissonRatesChart;
