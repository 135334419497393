import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { TimestreamNodePPMParams, TimestreamNodePPMResponse } from "./types";

export const timestreamNodePPMApi = createApi({
  reducerPath: "timestreamNodePPMApi",
  baseQuery,
  tagTypes: ["TimestreamNodePPM"],
  endpoints: (builder) => ({
    getTimestreamNodePPM: builder.query<
    TimestreamNodePPMResponse,
    TimestreamNodePPMParams
    >({
      query: (payload) => {
        const { siteId: id, startDate, endDate, nodeIds, timezone } = payload;
        const params = {
          startDate,
          endDate,
          nodeIds,
          timezone,
        };
        return {
          url: `site/${id}/timestreamNodePPM`,
          method: "GET",
          params,
        };
      },
    }),
  }),
});
