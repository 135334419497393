import { RootState } from "..";
import { SideBarElement } from "./types";

export const pageTitleSelector = (state: RootState): string | "" =>
  state.ui.pageTitle;

export const goBackRouteSelector = (state: RootState): string =>
  state.ui.goBackRoute;

export const showSideBarSelector = (state: RootState): boolean =>
  state.ui.showSideBar;

export const sideBarItemsSelector = (state: RootState): SideBarElement[] =>
  state.ui.sideBarElements;
