import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import {
  DailyEmissionParams,
  DailyEmissionsResponse,
} from "./types";

export const dailyEmissionsApi = createApi({
  reducerPath: "dailyEmissionsApi",
  baseQuery,
  tagTypes: ["DailyEmissions"],
  endpoints: (builder) => ({
    getDailyEmissions: builder.query<DailyEmissionsResponse, DailyEmissionParams>({
      query: (payload) => {
        const { id, startDate, endDate } = payload;
        const params = {
          startDate,
          endDate,
        };
        return {
          url: `site/${id}/dailyEmissions`,
          method: "GET",
          params,
        };
      },
      providesTags: ["DailyEmissions"],
    }),
  }),
});

export const { useGetDailyEmissionsQuery } = dailyEmissionsApi;
