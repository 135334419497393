export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedTime;
};

export const formatEpochTime = (epochMilliseconds: number): string => {
  const date = new Date(epochMilliseconds);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const getDurationHours = (
  startTimestamp: string,
  endTimestamp: string,
): number => {
  const startTimestampDate = new Date(startTimestamp);
  const endTimestampDate = new Date(endTimestamp);

  const durationHours = (
    (endTimestampDate.getTime() - startTimestampDate.getTime()) / 1000 / 60 / 60
  );

  return durationHours;
};

export const getNewStartTimestamp = (
  endTimestamp: string,
  durationDays: number,
  durationHours: number
): string => {
  const durationDaysInMilliseconds = durationDays * 24 * 60 * 60 * 1000;
  const durationHoursInMilliseconds = durationHours * 60 * 60 * 1000;
  const durationMilliseconds = durationDaysInMilliseconds + durationHoursInMilliseconds;

  const endTimestampDate = new Date(endTimestamp);
  const endTimestampEpoch = endTimestampDate.getTime();
  const startTimestampEpoch = endTimestampEpoch - durationMilliseconds;
  const startTimestamp = formatEpochTime(startTimestampEpoch);
  return startTimestamp;
};

export const getNewEndTimestamp = (
  startTimestamp: string,
  durationDays: number,
  durationHours: number
): string => {
  const durationDaysInMilliseconds = durationDays * 24 * 60 * 60 * 1000;
  const durationHoursInMilliseconds = durationHours * 60 * 60 * 1000;
  const durationMilliseconds = durationDaysInMilliseconds + durationHoursInMilliseconds;

  const startTimestampObj = new Date(startTimestamp);
  const startTimestampEpoch = startTimestampObj.getTime();
  const endTimestampEpoch = startTimestampEpoch + durationMilliseconds;
  const endTimestamp = formatEpochTime(endTimestampEpoch);
  return endTimestamp;
};
