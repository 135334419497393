import { FC } from "react";
import { mapControllers } from "../OrgMapController";
import LayersController from "../../../../components/MapController/LayersController/LayersController";
import EditGridControls from "../../../../components/MapController/EditGridContorls/EditGridControls";
import NodeOptimization from "../../../../components/MapController/NodeOptmization/NodeOptimization";

import { ControllersProps } from "./types";
import GridVisibilityControls from "../../../../components/MapController/GridVisibilityControls/GridVisibilityControls";
import DroneImage from "../../../../components/MapController/DroneImage/DroneImage";
import MultiGridController from "../../../../components/MapController/MultiGridController/MultiGridController";

const Controllers: FC<ControllersProps> = ({
  selectedController,
  onToggleNodesLayer,
  onToggleGridLayer,
  onSelectAlarmIncident,
  onClearSelectedAlarm,
  selectedAlarmId,
  onDateChange,
  selectedDate,
  alarmIncidents,
  fetchingAlarms,
  onToggleActiveGridCells,
  timezone,
  onTogglePPMLayer,
  setShowWinds,
  showWinds,
  showPPMLayer,
  onSaveGridConfig,
  selectedEditType,
  handleEditTypeChange,
  selectedCellToEdit,
  formKey,
  onSaveEditCell,
  showNodesLayer,
  showGridLayer,
  showActiveGridCellsLayer,
  siteNodes,
  siteGrid,
  showGPAQSLayer,
  onToggleGPAQSLayer,
  isAdmin,
  selectedGPAQSLargestValue,
  onStartCreateNewEvent,
  toggleCaughtEventsLayer,
  showCaughtEvents,
  isSiteGridLoading,
  showAvgEmissions,
  onToggleAvgEmissions,
  showVOCLevels,
  setShowVOCLevels,
  requireDroneImage,
  showDroneImageLayer,
  setShowDroneImageLayer,
  isMultipleGrid,
}) => {
  let currentController = null;
  switch (selectedController) {
    case mapControllers.LAYERS:
      currentController = (
        <LayersController
          onToggleNodesLayer={onToggleNodesLayer}
          onToggleGridLayer={onToggleGridLayer}
          onSelectAlarmIncident={onSelectAlarmIncident}
          onClearSelectedAlarm={onClearSelectedAlarm}
          selectedAlarmId={selectedAlarmId}
          onDateChange={onDateChange}
          selectedDate={selectedDate}
          alarmIncidents={alarmIncidents}
          fetchingAlarms={fetchingAlarms}
          onToggleActiveGridCells={onToggleActiveGridCells}
          timezone={timezone}
          onTogglePPMLayer={onTogglePPMLayer}
          setShowWinds={setShowWinds}
          showWinds={showWinds}
          showPPMLayer={showPPMLayer}
          showNodesLayer={showNodesLayer}
          showGridLayer={showGridLayer}
          showActiveGridCellsLayer={showActiveGridCellsLayer}
          showGPAQSLayer={showGPAQSLayer}
          onToggleGPAQSLayer={onToggleGPAQSLayer}
          isAdmin={isAdmin}
          selectedGPAQSLargestValue={selectedGPAQSLargestValue}
          onStartCreateNewEvent={onStartCreateNewEvent}
          toggleCaughtEventsLayer={toggleCaughtEventsLayer}
          showCaughtEvents={showCaughtEvents}
          isSiteGridLoading={isSiteGridLoading}
          showAvgEmissions={showAvgEmissions}
          onToggleAvgEmissions={onToggleAvgEmissions}
          showVOCLevels={showVOCLevels}
          setShowVOCLevels={setShowVOCLevels}
          showDroneImageLayer={showDroneImageLayer}
          setShowDroneImageLayer={setShowDroneImageLayer}
        />
      );
      break;
    case mapControllers.DRAW_GRID:
      currentController = (
        <MultiGridController
          isMultipleGrid={isMultipleGrid}
          requireDroneImage={requireDroneImage}
          setShowDroneImageLayer={setShowDroneImageLayer}
          showDroneImageLayer={showDroneImageLayer}
        />
      );
      break;
    case mapControllers.EDIT_GRID:
      currentController = (
        <EditGridControls
          onSaveGridConfig={onSaveGridConfig}
          selectedEditType={selectedEditType}
          handleEditTypeChange={handleEditTypeChange}
          selectedCellToEdit={selectedCellToEdit}
          formKey={formKey}
          onSaveEditCell={onSaveEditCell}
          requireDroneImage={requireDroneImage}
          setShowDroneImageLayer={setShowDroneImageLayer}
          showDroneImageLayer={showDroneImageLayer}
        />
      );
      break;
    case mapControllers.GRID_VISIBILITY:
      currentController = (
        <GridVisibilityControls
          requireDroneImage={requireDroneImage}
          showDroneImage={showDroneImageLayer}
          setShowDroneImageLayer={setShowDroneImageLayer}
          siteNodes={siteNodes}
          siteGrid={siteGrid}
        />
      );
      break;
    case mapControllers.NODE_OPTIMIZATION:
      currentController = (
        <NodeOptimization
          requireDroneImage={requireDroneImage}
          setShowDroneImageLayer={setShowDroneImageLayer}
          showDroneImageLayer={showDroneImageLayer}
        />
      );
      break;
    case mapControllers.DRONE_IMAGE:
      currentController = <DroneImage />;
      break;
    default:
      currentController = null;
  }
  return currentController;
};

export default Controllers;
