import React, { useEffect, useRef, useState } from "react";
import { Radio, RadioChangeEvent } from "antd";
import UploadForm from "./DroneImageForm/DroneImageForm";
import {
  finishMapLocationSelection,
  initMapLocationSelection,
  removeMovableOverlay,
} from "../../../pages/OrganizationMap/imageOverlayHelper";
import { useAppSelector } from "../../../store/hooks";
import { currentSiteSelector } from "../../../store/sites/selector";
import EditImageForm from "./EditImageForm/EditImageForm";
import DeleteDroneImage from "./DeleteDroneImage/DeleteDroneImage";
import { removePushPins } from "../../../pages/OrganizationMap/mapApi";

const DroneImage: React.FC = () => {
  const currentSite = useAppSelector(currentSiteSelector);
  const siteId = currentSite?.SiteId ?? "";
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const locationHandlerId = useRef<(() => void) | null>(null);

  const setLocationHandlerId = (newValue: (() => void) | null): void => {
    locationHandlerId.current = newValue;
  };

  const handleActionChange = (e: RadioChangeEvent): void => {
    removePushPins();
    removeMovableOverlay();
    const action = e.target.value as "upload" | "edit" | "delete";
    setSelectedAction(action);

    if (action === "upload") {
      initMapLocationSelection(setLatitude, setLongitude, setLocationHandlerId);
    } else {
      if (locationHandlerId.current !== null) {
        finishMapLocationSelection(locationHandlerId.current);
        setLocationHandlerId(null);
      }
    }
  };

  let component = null;

  switch (selectedAction) {
    case "upload":
      component = (
        <UploadForm
          latitude={latitude}
          longitude={longitude}
          siteId={siteId}
          locationHandlerId={locationHandlerId.current}
        />
      );
      break;
    case "edit":
      component = <EditImageForm siteId={siteId} />;
      break;
    case "delete":
      component = <DeleteDroneImage siteId={siteId} />;
      break;
  }

  useEffect(() => {
    // Cleanup function to be executed when the component unmounts
    return () => {
      removeMovableOverlay();
      removePushPins();
      if (locationHandlerId.current !== null) {
        finishMapLocationSelection(locationHandlerId.current);
      }
    };
  }, []);

  return (
    <div>
      <Radio.Group onChange={handleActionChange} value={selectedAction}>
        <Radio value="upload">Upload Drone Image</Radio>
        <div style={{ marginTop: 5 }}></div>
        <Radio value="edit">Edit Image</Radio>
        <div style={{ marginTop: 5 }}></div>
        <Radio value="delete">Remove Image</Radio>
        <div style={{ marginTop: 5 }}></div>
      </Radio.Group>
      {component}
    </div>
  );
};

export default DroneImage;
