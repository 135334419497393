import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { EventConfidenceReturn, EventConfidencePayload } from "./types";

export const eventConfidenceApi = createApi({
  reducerPath: "eventConfidenceApi",
  baseQuery,
  tagTypes: ["EventConfidence"],
  endpoints: (builder) => ({
    getEventConfidence: builder.query<
    EventConfidenceReturn,
    EventConfidencePayload
    >({
      query: (payload) => {
        const { siteId, timestamp } = payload;
        return {
          url: `site/${siteId}/eventConfidence`,
          method: "GET",
          params: { timestamp },
        };
      },
      providesTags: ["EventConfidence"],
    }),
  }),
});

export const { useGetEventConfidenceQuery, useLazyGetEventConfidenceQuery } =
  eventConfidenceApi;
