import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import {
  NodeConfig,
  NodeConfigResponse,
  NodeConfigParams,
  UpdateNodeConifgResponse,
} from "./types";

export const nodeConfigApi = createApi({
  reducerPath: "nodeConfigApi",
  baseQuery,
  tagTypes: ["NodeConfig"],
  endpoints: (builder) => ({
    getNodeConfig: builder.query<NodeConfigResponse, NodeConfigParams>({
      query: (payload) => {
        const { id } = payload;
        return {
          url: `nodes/${id}/nodeConfig`,
          method: "GET",
        };
      },
      providesTags: ["NodeConfig"],
    }),
    updateNodeConfig: builder.mutation<UpdateNodeConifgResponse, NodeConfig>({
      query: (payload: NodeConfig) => ({
        url: `nodes/${payload.NodeId}/nodeConfig`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["NodeConfig"],
    }),
  }),
});

export const { useGetNodeConfigQuery, useUpdateNodeConfigMutation } =
  nodeConfigApi;
