import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";

interface SampleRatesQueryParams {
  id: string;
  queryType: string;
  date: string;
}

interface SampleRate {
  cellId: string;
  count: number;
  percentage: string;
}

interface SamplingRatesQueryResult {
  results: SampleRate[];
  Timestamp: string;
  SiteId: string;
  grid_timestamp?: string;
}

export const samplingRatesApi = createApi({
  reducerPath: "samplingRatesApi",
  baseQuery,
  tagTypes: ["samplingRates"],
  endpoints: (builder) => ({
    getSamplingRates: builder.query<
    SamplingRatesQueryResult,
    SampleRatesQueryParams
    >({
      query: (params) => {
        const { id, queryType, date } = params;
        return {
          url: `samplingRates/${id}`,
          params: {
            type: queryType,
            date,
          },
        };
      },
      providesTags: ["samplingRates"],
    }),
  }),
});

export const { useLazyGetSamplingRatesQuery } = samplingRatesApi;
