import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import {
  NodeSignOffPayload,
  NodeSignOffResponse,
  LogSignOffPayload,
  LogSignOffResponse
} from "./types";

export const nodeSignOffApi = createApi({
  reducerPath: "nodeSignOffApi",
  baseQuery,
  endpoints: (builder) => ({
    signOffNode: builder.query<NodeSignOffResponse, NodeSignOffPayload>({
      query: (payload) => `node/${payload.nodeId}/signOff`,
    }),
    logSignOff: builder.query<LogSignOffResponse, LogSignOffPayload>({
      query: (payload) => ({
        url: `node/${payload.nodeId}/logSignOff`,
        body: { email: payload.email },
        method: "POST",
      }),
    }),
  }),
});
