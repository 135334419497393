import { FC, useState } from "react";
import styles from "./styles.module.scss";
import { Button, Dropdown } from "antd";
import { LeftOutlined, RightOutlined, DownOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { showMapControllerSelector } from "../../../store/orgMap/selector";
import { setShowMapController } from "../../../store/orgMap/slice";
import Controllers from "./Controllers/Controllers";
import { MapControllerProps } from "./types";

export const mapControllers = {
  DRAW_GRID: "DRAW_GRID",
  LAYERS: "LAYERS",
  EDIT_GRID: "EDIT_GRID",
  GRID_VISIBILITY: "GRID_VISIBILITY",
  NODE_OPTIMIZATION: "NODE_OPTIMIZATION",
  DRONE_IMAGE: "DRONE_IMAGE",
};

const MapController: FC<MapControllerProps> = ({
  onSelectViewMap,
  onToggleNodesLayer,
  onToggleGridLayer,
  cleanDrawGrid,
  onSaveGridConfig,
  isAdmin,
  onSelectAlarmIncident,
  onClearSelectedAlarm,
  selectedAlarmId,
  onDateChange,
  selectedDate,
  alarmIncidents,
  fetchingAlarms,
  onToggleActiveGridCells,
  timezone,
  onTogglePPMLayer,
  setShowWinds,
  resetLayers,
  onEditGrid,
  selectedEditType,
  handleEditTypeChange,
  selectedCellToEdit,
  formKey,
  onSaveEditCell,
  showWinds,
  showPPMLayer,
  currentSite,
  showNodesLayer,
  showGridLayer,
  showActiveGridCellsLayer,
  siteNodes,
  siteGrid,
  showGPAQSLayer,
  onToggleGPAQSLayer,
  selectedGPAQSLargestValue,
  onStartCreateNewEvent,
  toggleCaughtEventsLayer,
  showCaughtEventsLayer,
  isSiteGridLoading,
  showAvgEmissions,
  onToggleAvgEmissions,
  showVOCLevels,
  setShowVOCLevels,
  showDroneImageLayer,
  setShowDroneImageLayer,
  requireDroneImage,
  isMultipleGrid,
}) => {
  const showMapController: boolean = useAppSelector(showMapControllerSelector);
  const dispatch = useAppDispatch();
  const handleSetShowMapController = (): void => {
    dispatch(setShowMapController(!showMapController));
  };
  const [selectedTool, setSelectedTool] = useState(mapControllers.LAYERS);

  let items = [
    {
      label: "Site View",
      key: mapControllers.LAYERS,
    },
    {
      label: "Equipment",
      key: mapControllers.EDIT_GRID,
    },
  ];
  if (isAdmin) {
    const adminItems = [
      {
        label: "Site Grid",
        key: mapControllers.DRAW_GRID,
      },
      {
        label: "Grid Vis.",
        key: mapControllers.GRID_VISIBILITY,
      },
      {
        label: "Node Optimization",
        key: mapControllers.NODE_OPTIMIZATION,
      },
      {
        label: "Drone Image",
        key: mapControllers.DRONE_IMAGE,
      },
    ];
    items = items.concat(adminItems);
  }

  const [selectedToolLabel, setSelectedToolLabel] = useState<string>(
    items[0].label
  );

  interface MenuChangeEvent {
    key: string;
  }
  const handleMenuClick = (e: MenuChangeEvent): void => {
    const selectedOption = e.key;
    const selectedItem = items.find((item) => item.key === selectedOption);
    if (selectedItem != null) {
      const selectedLabel = selectedItem.label;
      setSelectedToolLabel(selectedLabel);
    }
    if (selectedOption === mapControllers.DRAW_GRID) {
      resetLayers();
      cleanDrawGrid();
    }

    if (selectedOption === mapControllers.LAYERS) {
      resetLayers();
      onSelectViewMap();
      cleanDrawGrid();
    }

    if (selectedOption === mapControllers.EDIT_GRID) {
      resetLayers();
      onEditGrid(selectedEditType);
    }

    if (selectedOption === mapControllers.GRID_VISIBILITY) {
      resetLayers();
    }

    if (selectedOption === mapControllers.NODE_OPTIMIZATION) {
      resetLayers();
    }
    if (selectedOption === mapControllers.DRONE_IMAGE) {
      if (showNodesLayer) {
        onToggleNodesLayer();
      }
      resetLayers();
      if (showDroneImageLayer) {
        setShowDroneImageLayer();
      }
    }
    setSelectedTool(selectedOption);
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div
      className={styles.MapController}
      style={{
        height:
          showPPMLayer || showAvgEmissions || showVOCLevels
            ? "calc(100% - 210px)"
            : "100%",
      }}
    >
      {showMapController ? (
        <div className={styles.ControllerWrapper}>
          <div className={styles.MapOptions}>
            <Dropdown className={styles.MenuController} menu={menuProps}>
              <Button>
                {selectedToolLabel}
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <div>
            <Controllers
              selectedController={selectedTool}
              onToggleNodesLayer={onToggleNodesLayer}
              onToggleGridLayer={onToggleGridLayer}
              onSelectAlarmIncident={onSelectAlarmIncident}
              onClearSelectedAlarm={onClearSelectedAlarm}
              selectedAlarmId={selectedAlarmId}
              onDateChange={onDateChange}
              selectedDate={selectedDate}
              alarmIncidents={alarmIncidents}
              fetchingAlarms={fetchingAlarms}
              onToggleActiveGridCells={onToggleActiveGridCells}
              timezone={timezone}
              onTogglePPMLayer={onTogglePPMLayer}
              setShowWinds={setShowWinds}
              showWinds={showWinds}
              showPPMLayer={showPPMLayer}
              onSaveGridConfig={onSaveGridConfig}
              formKey={formKey}
              selectedCellToEdit={selectedCellToEdit}
              selectedEditType={selectedEditType}
              onSaveEditCell={onSaveEditCell}
              handleEditTypeChange={handleEditTypeChange}
              showNodesLayer={showNodesLayer}
              showGridLayer={showGridLayer}
              showActiveGridCellsLayer={showActiveGridCellsLayer}
              siteNodes={siteNodes}
              siteGrid={siteGrid}
              showGPAQSLayer={showGPAQSLayer}
              onToggleGPAQSLayer={onToggleGPAQSLayer}
              isAdmin={isAdmin}
              selectedGPAQSLargestValue={selectedGPAQSLargestValue}
              onStartCreateNewEvent={onStartCreateNewEvent}
              toggleCaughtEventsLayer={toggleCaughtEventsLayer}
              showCaughtEvents={showCaughtEventsLayer}
              isSiteGridLoading={isSiteGridLoading}
              showAvgEmissions={showAvgEmissions}
              onToggleAvgEmissions={onToggleAvgEmissions}
              showVOCLevels={showVOCLevels}
              setShowVOCLevels={setShowVOCLevels}
              requireDroneImage={requireDroneImage}
              showDroneImageLayer={showDroneImageLayer}
              setShowDroneImageLayer={setShowDroneImageLayer}
              isMultipleGrid={isMultipleGrid}
            />
          </div>
        </div>
      ) : null}

      <Button
        icon={showMapController ? <LeftOutlined /> : <RightOutlined />}
        style={{ margin: 10, border: "none" }}
        onClick={handleSetShowMapController}
        disabled={currentSite === null}
      />
    </div>
  );
};

export default MapController;
