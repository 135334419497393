import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";
import { iconColor } from "./Devices";

const width = "20px";
const height = "20px";

// https://www.svgrepo.com/svg/71859/wind
const windSvg = (): JSX.Element => (
  <svg
    fill={iconColor}
    height={height}
    width={width}
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          d="M287.64,94.921c-31.721,0-57.528,25.807-57.528,57.528h34.517c0-12.688,10.323-23.011,23.011-23.011
          c12.688,0,23.011,10.323,23.011,23.011c0,12.688-10.323,23.011-23.011,23.011H46.022v34.517H287.64
          c31.721,0,57.528-25.807,57.528-57.528C345.169,120.728,319.361,94.921,287.64,94.921z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M431.461,106.427c-34.893,0-63.281,28.388-63.281,63.281c0,25.377,20.646,46.022,46.022,46.022
          c25.377,0,46.022-20.646,46.022-46.022h-34.517c0,6.344-5.161,11.506-11.506,11.506c-6.344,0-11.506-5.161-11.506-11.506
          c0-15.861,12.904-28.764,28.764-28.764c25.377,0,46.022,20.646,46.022,46.022c0,25.377-20.646,46.022-46.022,46.022H0v34.517
          h431.461c44.409,0,80.539-36.13,80.539-80.539C512,142.557,475.87,106.427,431.461,106.427z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M345.169,290.517H46.022v34.517h299.146c15.861,0,28.764,12.904,28.764,28.764c0,15.861-12.904,28.764-28.764,28.764
          c-15.86,0-28.764-12.904-28.764-28.764h-34.517c0,34.893,28.388,63.281,63.281,63.281c34.893,0,63.281-28.388,63.281-63.281
          C408.449,318.905,380.062,290.517,345.169,290.517z"
        />
      </g>
    </g>
  </svg>
);

export const WindIcon = (
  props: Partial<CustomIconComponentProps>
): JSX.Element => <Icon component={windSvg} {...props} />;

// https://www.svgrepo.com/svg/500823/compass
const compassSvg = (): JSX.Element => (
  <svg
    fill={iconColor}
    height={height}
    width={width}
    viewBox="0 0 32 32"
  >
    <title>compass</title>
    <path d="M11.188 4.781c6.188 0 11.219 5.031 11.219 11.219s-5.031 11.188-11.219 11.188-11.188-5-11.188-11.188 5-11.219 11.188-11.219zM11.188 24.594c4.75 0 8.625-3.844 8.625-8.594s-3.875-8.625-8.625-8.625-8.594 3.875-8.594 8.625 3.844 8.594 8.594 8.594zM13.031 17.813l-6.688 3.031 3.031-6.688 6.688-3.031zM7.563 19.656l4.844-2.438-2.438-2.438z"></path>
  </svg>
);

export const CompassIcon = (
  props: Partial<CustomIconComponentProps>
): JSX.Element => <Icon component={compassSvg} {...props} />;

// https://www.svgrepo.com/svg/465135/frequency-2
const frequencySvg = (): JSX.Element => (
  <svg
    fill={iconColor}
    height="15px"
    width="15px"
    viewBox="0 0 24 24"
  >
    <path
      id="primary"
      d="M15,17a1,1,0,0,1-.89-.55l-3-6.07-2.15,5a1,1,0,0,1-.83.61,1,1,0,0,1-.92-.45L5.46,13H3a1,1,0,0,1,0-2H6a1,1,0,0,1,.83.45l1,1.46,2.27-5.3A1,1,0,0,1,11,7a1.05,1.05,0,0,1,.92.55L15,13.76l1.11-2.21A1,1,0,0,1,17,11h4a1,1,0,0,1,0,2H17.62l-1.73,3.45A1,1,0,0,1,15,17Z"
    ></path>
  </svg>
);

export const FrequencyIcon = (
  props: Partial<CustomIconComponentProps>
): JSX.Element => <Icon component={frequencySvg} {...props} />;
