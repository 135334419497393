import { FC, useRef, useState } from "react";
import { Row, Col, Button, Checkbox, Select } from "antd";
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import { PPMForSiteMap as PPMForSiteMapType } from "../../../store/ppmForSiteMap/types";
import { useGetGlobalConfigQuery } from "../../../store/globalConfig/api";
import { QuantityUnit, RateUnit, mcfHrToSiteUnits } from "../../../helpers/unitsHelpers";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Plugin,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import DatePickerWithArrows from "../../DatePickerWithArrows/DatePickerWithArrows";
import TimePickerWithArrows from "../../TimePickerWithArrows/TimePickerWithArrows";
import { MapPPMProps, TooltipContext } from "./types";
import styles from "./styles.module.scss";
import { unitOptions } from "../../../pages/SiteStats/types";
import { subtractMinutes } from "../../../helpers/dateTimeHelpers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const BingMapPPM: FC<MapPPMProps> = ({
  ppmForSiteMap,
  startDate,
  onDateChange,
  startTime,
  onTimeChange,
  onHoverOverPpm,
  onPlayPPM,
  isPPMPlaying,
  onStopPPM,
  showLocalTimeForMapPPM,
  handleChangeShowLocalTimeForMapPPM,
  timezone,
  singleNodeSitePAQS,
  showAvgEmissions,
  showPPMLayer,
  showVOCLevels,
  ppmEndDateHours,
  setPPMEndDateHours,
}) => {
  const chartRef = useRef(null);
  const [selectedUnit, setSelectedUnit] = useState(QuantityUnit.MCF);
  const onUnitOptionChange = (newUnit: QuantityUnit): void => {
    setSelectedUnit(newUnit);
  };
  const doDisableUnitOption = !showAvgEmissions;
  const globalConfig = useGetGlobalConfigQuery(null).data;
  const emissionRateSuggestedMax =
    selectedUnit === QuantityUnit.MCF ? 0.02 : 0.5;
  const emissionRateUnitText =
    selectedUnit === QuantityUnit.MCF ? RateUnit.MCF_DAY : RateUnit.KG_H;
  const data: PPMForSiteMapType[] = ppmForSiteMap;

  const findDataElement = (dataIndex: number): PPMForSiteMapType => {
    const element = data[dataIndex];
    return element;
  };

  const plugins: Plugin[] = [
    {
      id: "tooltipLine",
      afterDraw: (chart: ChartJS) => {
        if (
          chart?.tooltip != null &&
          chart.tooltip?.caretX !== undefined &&
          chart.scales?.y !== undefined
        ) {
          const { ctx } = chart;
          const { caretX } = chart.tooltip;
          const topY = chart.scales.y.top;
          const bottomY = chart.scales.y.bottom;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(caretX, topY - 5);
          ctx.lineTo(caretX, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "#000000";
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ];

  const options = {
    animation: {
      duration: 0,
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: "nearest" as const,
    },
    plugins: {
      tooltip: {
        filter: function (tooltipItem: TooltipContext) {
          return (
            tooltipItem.datasetIndex === 0 || tooltipItem.datasetIndex === 2
          );
        },
        callbacks: {
          label: function (context: TooltipContext) {
            const dataIndex: number = context.dataIndex;
            const dataSetIndex: number = context.datasetIndex;
            if (dataSetIndex === 0) {
              const result = findDataElement(dataIndex);
              onHoverOverPpm(result);
              return [`${result.maxCh4} ppm`];
            }
            if (dataSetIndex === 2) {
              const result = findDataElement(dataIndex);
              onHoverOverPpm(result);
              return [`${result.maxVOC} voc`];
            }
          },
        },
      },
      legend: {
        align: "start" as const,
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        offset: false,
        type: "time" as const,
        title: {
          display: false,
          text: "Timestamp Add TimeZone",
          align: "start" as const,
        },
        time: {
          unit: "minute" as const,
          displayFormats: {
            minute: "HH:mm" as const,
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        title: {
          display: true,
          text: "Methane (PPM)",
        },
        min: 1.5,
        display: showPPMLayer,
      },
      y1: {
        title: {
          display: true,
          text: `Rate (${emissionRateUnitText})`,
        },
        position: "right" as const,
        suggestedMax: emissionRateSuggestedMax,
        grid: {
          drawOnChartArea: showAvgEmissions && !showPPMLayer,
        },
        display: showAvgEmissions,
      },
      y2: {
        position:
          showAvgEmissions && !showPPMLayer
            ? ("left" as const)
            : ("right" as const),
        title: {
          display: true,
          text: "VOC (PPM)",
        },
        min: 0.4,
        display:
          (showVOCLevels && !showAvgEmissions) ||
          (showVOCLevels && !showPPMLayer),
        grid: {
          display: !showPPMLayer && !showAvgEmissions,
        },
      },
    },
  };

  const ppmDataset = {
    yAxisID: "y",
    data: data.map((dataEl) => {
      return { x: dataEl.Timestamp, y: dataEl.maxCh4 };
    }),
    spanGaps: true,
    backgroundColor: "#26a7de",
    borderColor: "#26a7de",
    hidden: !showPPMLayer,
    pointRadius: 0,
    type: "line" as const,
    order: 0,
    pointHitRadius: 1,
  };

  const vocDataset = {
    yAxisID: "y2",
    data: data.map((dataEl) => {
      return { x: dataEl.Timestamp, y: dataEl.maxVOC };
    }),
    spanGaps: true,
    backgroundColor: "#FFC300",
    borderColor: "#FFC300",
    hidden: !showVOCLevels,
    pointRadius: 0,
    type: "line" as const,
    order: 0,
    pointHitRadius: 1,
  };

  // to center Emissions Rate set the timestamp at the 5s and increase border thickness
  const avgEmissionDataSet = {
    yAxisID: "y1",
    minBarLength: 0.2,
    barThickness: 250,
    categoryPercentage: 0.8,
    data: singleNodeSitePAQS
      .filter((gpaqs) => gpaqs.Timestamp > `${startDate} ${startTime}`)
      .map((gpaqs) => {
        if (globalConfig === undefined) {
          return {
            x: subtractMinutes(gpaqs.Timestamp, 5),
            y: 0,
          };
        }

        return {
          x: subtractMinutes(gpaqs.Timestamp, 5),
          y: mcfHrToSiteUnits(
            Number(gpaqs.average_emission_rates),
            selectedUnit,
            globalConfig
          ),
        };
      }),
    spanGaps: true,
    backgroundColor: "rgba(219, 179, 177, 0.7)",
    pointRadius: 0,
    hidden: !showAvgEmissions,
    order: 1,
    type: "bar" as const,
    pointHitRadius: 0,
  };

  const chartData = {
    datasets: [ppmDataset, avgEmissionDataSet, vocDataset],
  };

  const playButtonIcon = isPPMPlaying ? (
    <PauseCircleOutlined />
  ) : (
    <PlayCircleOutlined />
  );
  const playButtonStyle = {
    background: "#52c41a",
    border: "none",
  };

  const onClickPlayButton = (): void => {
    if (isPPMPlaying) {
      onStopPPM();
    } else {
      onPlayPPM(chartRef.current);
    }
  };

  const onClickCheckbox = (): void => {
    handleChangeShowLocalTimeForMapPPM(!showLocalTimeForMapPPM);
  };

  return (
    <div className={styles.Wrapper}>
      <Row className={styles.Selection}>
        <Col className={styles.PlayButtonWrapper}>
          <Button
            icon={playButtonIcon}
            size="small"
            type="primary"
            style={playButtonStyle}
            onClick={onClickPlayButton}
          >
            {isPPMPlaying ? "Stop" : "Play"}
          </Button>
        </Col>
        <Col style={{ marginRight: 10 }}>
          <DatePickerWithArrows
            onDateChange={onDateChange}
            selectedDate={startDate}
            size="small"
          />
        </Col>
        <Col span={3}>
          <TimePickerWithArrows
            onTimeChange={onTimeChange}
            selectedTime={startTime}
            size="small"
          />
        </Col>
        <Col span={2}>
          <Checkbox
            checked={!showLocalTimeForMapPPM}
            onChange={onClickCheckbox}
          >
            {showLocalTimeForMapPPM ? timezone : "UTC"}
          </Checkbox>
        </Col>
        <Col span={3}>
          <Select
            style={{ width: "75%" }}
            defaultValue={selectedUnit}
            options={unitOptions}
            onChange={onUnitOptionChange}
            disabled={doDisableUnitOption}
          />
        </Col>
        <Col span={3}>
          <Select
            style={{ width: "75%" }}
            defaultValue={ppmEndDateHours}
            options={[
              { value: 1, label: "1 hour" },
              { value: 2, label: "2 hours" },
              { value: 6, label: "6 hours" },
              { value: 12, label: "12 hours" },
              { value: 24, label: "24 hours" },
            ]}
            onChange={(value: number) => setPPMEndDateHours(value)}
          />
        </Col>
      </Row>
      <Chart
        type="bar"
        options={options}
        data={chartData}
        plugins={plugins}
        ref={chartRef}
      />
    </div>
  );
};

export default BingMapPPM;
