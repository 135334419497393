const getCanvasOverlay = (Maps) => {
  const { CustomOverlay, Events, PixelReference, MapTypeId } = Maps;
  function CanvasOverlay (drawCallback) {
    this.canvas = document.createElement("canvas");
    this.canvas.style.position = "absolute";
    this.canvas.style.left = "0px";
    this.canvas.style.top = "0px";

    this._updatePosition = function (x, y, w, h) {
      this.canvas.style.left = x + "px";
      this.canvas.style.top = y + "px";

      this.canvas.style.width = w + "px";
      this.canvas.style.height = h + "px";
    };

    this._redraw = function () {
      this.canvas.width = this.getMap().getWidth();
      this.canvas.height = this.getMap().getHeight();

      drawCallback(this.canvas);
    };
  }

  CanvasOverlay.prototype = new CustomOverlay();

  CanvasOverlay.prototype.onAdd = function () {
    this.setHtmlElement(this.canvas);
  };

  CanvasOverlay.prototype.onLoad = function () {
    const self = this;
    const map = self.getMap();

    const zoomStart = map.getZoom();
    const centerStart = map.getCenter();

    self._redraw();

    self.viewChangeEvent = Events.addHandler(map, "viewchange", function (e) {
      if (map.getMapTypeId() === MapTypeId.streetside) {
        self.canvas.style.display = "none";
      } else {
        const zoomCurrent = map.getZoom();
        const centerCurrent = map.getCenter();

        const scale = Math.pow(2, zoomCurrent - zoomStart);

        const newWidth = map.getWidth() * scale;
        const newHeight = map.getHeight() * scale;

        const pixelPoints = map.tryLocationToPixel(
          [centerStart, centerCurrent],
          PixelReference.control
        );
        const centerOffsetX = pixelPoints[1].x - pixelPoints[0].x;
        const centerOffsetY = pixelPoints[1].y - pixelPoints[0].y;
        const x = -(newWidth - map.getWidth()) / 2 - centerOffsetX;
        const y = -(newHeight - map.getHeight()) / 2 - centerOffsetY;

        self._updatePosition(x, y, newWidth, newHeight);
      }
    });

    self.viewChangeEndEvent = Events.addHandler(map, "viewchangeend");
  };

  CanvasOverlay.prototype.onRemove = function () {
    Events.removeHandler(this.viewChangeEvent);
    Events.removeHandler(this.viewChangeEndEvent);
  };

  CanvasOverlay.prototype.drawOrder = 1;

  return CanvasOverlay;
};

export default getCanvasOverlay;
