import { GpaqsAlarmIncident } from "../../components/MapController/types";
import { Site } from "../sites/types";

export enum AlarmIncidentEnum {
  GPAQS = "gpaqsAlarm",
  CONCENTRATION = "highConcentrationAlarm",
}

export interface ConcentrationAlarmIncident {
  Timestamp: string;
  NodeId: string;
  SiteId: string;
  ppm: string;
  recipients: string[];
  type: AlarmIncidentEnum;
  incidentNotes?: string;
  incidentType?: string;
}

export type AlarmIncidentType = GpaqsAlarmIncident | ConcentrationAlarmIncident;

export interface GetOrgAlarmIncidentsParams {
  orgId: string;
  date: string;
}

export interface GetOrgAlarmIncidentsResult {
  orgAlarmIncidents: GpaqsAlarmIncident[];
  orgConcentrationAlarmIncidents: ConcentrationAlarmIncident[];
  sites: Site[];
}
