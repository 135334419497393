export const projectionStringToArray = (
  projectionExpression: string
): string[] => {
  const array = projectionExpression.split(",");
  const cleanArray = array.map((col) => col.replace(/\n/g, "").trim());
  return cleanArray;
};

export const nodePPMColumnNameMap: { [key: string]: string } = {
  NodeId: "ID",
  "#Timestamp": "Time",
  elevation: "Elevation",
  latitude: "Latitude",
  longitude: "Longitude",
  sampleHeight: "Sample Height",
  sourceV: "Battery Voltage",
  figaroV: "Figaro Voltage",
  pump1d: "pump1D",
  pumpOff: "pumpOff",
  outTemp: "Air Temp",
  outRH: "Air RH",
  outBP: "Atmospheric Pressure",
  outSH: "outSH",
  windDIR: "Wind Direction",
  windMPH: "Wind Speed",
  adxl1: "adxl1",
  adxl2: "adxl2",
  bmeTemp: "bmeTemp",
  bmeRH: "bmeRH",
  bmeSH: "bmeSH",
  bmeBP: "bmeBP",
  hsTemp: "hsTemp",
  hsRH: "hsRH",
  hsSH: "hsSH",
  gas1: "gas1",
  gas2: "gas2",
  gas3: "gas3",
  bmeVOC: "bmeVOC",
  VOCLevel: "VOCLevel",
  gas1R: "gas1R",
  gas2R: "gas2R",
  gas3R: "gas3R",
  gas1D: "CH4",
  gas2D: "VOC",
  gas3D: "gas3D",
};

export const adminNodePPMProjection =
  Object.keys(nodePPMColumnNameMap).join(",");

export const clientNodePPMProjection = `
  NodeId,
  #Timestamp,
  latitude,
  longitude,
  elevation,
  sampleHeight,
  outTemp,
  outRH,
  outBP,
  bmeTemp,
  bmeRH,
  bmeBP,
  adxl1,
  adxl2,
  gas1D,
  gas2D,
  VOCLevel,
  sourceV,
  windMPH,
  windDIR`;

export const partnerNodePPMProjection = `
  NodeId,
  #Timestamp,
  outTemp,
  outRH,
  outBP,
  gas1D,
  elevation,
  latitude,
  longitude,
  sampleHeight,
  windMPH,
  windDIR`;

// RawNodeSamples
export const rawNodeSamplesColumnNameMap: { [key: string]: string } = {
  NodeId: "NodeId",
  "#Timestamp": "#Timestamp",
  invalidFieldsDetails: "validations",
  FH: "FH",
  H1: "Humidity (Onboard)",
  H2: "Humidity (External)",
  H3: "Humidity (MOS)",
  IB: "Battery current",
  IM: "MOS current",
  M0: "Resistance (TGS2600)",
  M1: "Resistance (TGS2611)",
  M2: "Resistance (TGS2602)",
  P1: "Pressure",
  SC: "Shock sensor",
  T1: "Temperature (Internal)",
  T2: "Temperature (Onboard)",
  T3: "Temperature (External)",
  T4: "Temperature (MOS)",
  TS: "TS",
  UV: "UV sensor",
  VB: "Battery voltage",
  VM: "MOS 5V rail voltage",
  VS: "Solar voltage",
  WA: "Wind speed (average)",
  WD: "Wind direction",
  WG: "Wind speed (gust)",
  WS: "Wind speed",
};

export const adminRawSamplesProjection = Object.keys(
  rawNodeSamplesColumnNameMap
).join(",");
