export const generatePushPins = (siteGrid, cellCoords) => {
  const { minLong, maxLong, minLat, maxLat, gridX, gridY } = siteGrid;
  const cellWidth = (maxLong - minLong) / gridX;
  const cellHeight = (maxLat - minLat) / gridY;

  const pushPins = [];

  cellCoords.forEach((cellCoord) => {
    const [x, y] = cellCoord.replace(/[()]/g, "").split(",");
    const cellMinLong = minLong + (x - 1) * cellWidth;
    const cellMinLat = minLat + (y - 1) * cellHeight;

    const latitude = cellMinLat + cellHeight / 2;
    const longitude = cellMinLong + cellWidth / 2;

    pushPins.push({
      center: { latitude, longitude },
      options: { color: "red" }
    });
  });

  return pushPins;
};
