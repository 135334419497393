import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { formatDate } from "../../components/TimeRangePicker/helpers";
import { SelectionsState } from "./types";

const HOME = "Home";

const now = new Date();
const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
const startOfTomorrow = new Date(startOfToday);
startOfTomorrow.setDate(startOfToday.getDate() + 1);

const initialState = {
  startTimestamp: formatDate(startOfToday),
  endTimestamp: formatDate(startOfTomorrow),
  currentTab: HOME,
};

export const selectionsSlice = createSlice({
  name: "selections",
  initialState,
  reducers: {
    setStartTimestamp: (
      state: SelectionsState,
      action: PayloadAction<string>,
    ) => {
      state.startTimestamp = action.payload;
    },
    setEndTimestamp: (
      state: SelectionsState,
      action: PayloadAction<string>,
    ) => {
      state.endTimestamp = action.payload;
    },
    setCurrentTab: (
      state: SelectionsState,
      action: PayloadAction<string>,
    ) => {
      state.currentTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState;
    });
  },
});

export const {
  setStartTimestamp,
  setEndTimestamp,
  setCurrentTab,
} = selectionsSlice.actions;

export default selectionsSlice.reducer;
