export const cities = [
  {
    name: "Dallas, Texas",
    latitude: 32.821162,
    longitude: -96.729385,
    perimeterInMiles: 150,
    id: "1",
  },
  {
    name: "Oklahoma City, Oklahoma",
    latitude: 35.470835,
    longitude: -97.51748,
    perimeterInMiles: 150,
    id: "2",
  },
  {
    name: "San Antonio, Texas",
    latitude: 29.42204,
    longitude: -98.497231,
    perimeterInMiles: 150,
    id: "3",
  },
  {
    name: "Scranton, Pennsylvania",
    latitude: 41.405764,
    longitude: -75.661841,
    perimeterInMiles: 150,
    id: "4",
  },
  {
    name: "Pecos, Texas",
    latitude: 31.42466,
    longitude: -103.495231,
    perimeterInMiles: 150,
    id: "5",
  },
];

export const RELEASE_BY = ["organization", "location", "site", "node"];
