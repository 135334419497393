import { FC } from "react";
import { Input, Form, Select, Radio } from "antd";
import styles from "./styles.module.scss";

const { Option } = Select;

const SiteInfo: FC = () => {
  return (
    <div className={styles.SiteInfo}>
      <h2>Site Information</h2>
      <Form.Item
        label="Site Number"
        name="SiteId"
        rules={[{ required: true }]}
        style={{ display: "none" }}
      >
        <Input disabled type="hidden" />
      </Form.Item>

      <Form.Item label="Site Name" name="siteName" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Time Zone" name="timezone" rules={[{ required: true }]}>
        <Select
          placeholder="Select a option and change input text above"
          allowClear
        >
          <Option value={"PST"}>Pacific Standard Time (PST), UTC -8</Option>
          <Option value={"MST"}>Mountain Standard Time (MST), UTC -7</Option>
          <Option value={"CST"}>Central Standard Time (CST), UTC -6</Option>
          <Option value={"EST"}>Eastern Standard Time (EST), UTC -5</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Latitude"
        name="latAPI"
        initialValue={0}
        rules={[
          { required: true },
          {
            validator: async (_, value: string) => {
              if (value !== "" && !/^-?\d+(\.\d{1,6})?$/.test(value)) {
                return await Promise.reject(
                  new Error("Latitude must have up to 6 decimal places.")
                );
              }
              return await Promise.resolve();
            },
          },
        ]}
      >
        <Input type="number" />
      </Form.Item>

      <Form.Item
        label="Longitude"
        name="longAPI"
        initialValue={0}
        rules={[
          { required: true },
          {
            validator: async (_, value: string) => {
              if (value !== "" && !/^-?\d+(\.\d{1,6})?$/.test(value)) {
                return await Promise.reject(
                  new Error("Longitude must have up to 6 decimal places.")
                );
              }
              return await Promise.resolve();
            },
          },
        ]}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item label="Require Drone Image" name="requireDroneImage">
        <Radio.Group>
          <Radio value={true}>True</Radio>
          <Radio value={false}>False</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Is a multi grid site?" name="isMultipleGrid">
        <Radio.Group>
          <Radio value={true}>True</Radio>
          <Radio value={false}>False</Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default SiteInfo;
