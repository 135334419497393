import { FC, useState } from "react";
import { Button, Input, Form, Select } from "antd";
import { EditCellProps, EditCellForm } from "./types";
import style from "./styles.module.scss";

const EditCell: FC<EditCellProps> = ({ cell, onSaveEditCell }) => {
  const EQUIPMENT_OPTIONS = [
    "Oil Tank",
    "Water Tank",
    "Wellhead",
    "Separator",
    "Compressor",
    "Other",
  ];
  const [equipmentFields, setEquipmentFields] = useState(
    cell?.equipment != null ? [...cell.equipment] : [""]
  );
  const addEquipmentField = (): void => {
    setEquipmentFields([...equipmentFields, ""]);
  };
  const removeEquipmentField = (ix: number): void => {
    const updatedEquipment = equipmentFields.filter((field, i) => i !== ix);
    setEquipmentFields(updatedEquipment);
  };
  const handleEquipmentChange = (i: number, value: string): void => {
    const updatedEquipment = [...equipmentFields];
    updatedEquipment[i] = value;
    setEquipmentFields(updatedEquipment);
    form.setFieldValue(`equipmentFields[${i}]`, value);
  };
  const [form] = Form.useForm();
  const formInitialValues: EditCellForm = {
    name: cell?.name ?? "",
    height: cell?.height ?? "",
  };
  equipmentFields.forEach((x, i) => {
    // done for typescript
    switch (i) {
      case 0:
        formInitialValues["equipmentFields[0]"] = x;
        break;
      case 1:
        formInitialValues["equipmentFields[1]"] = x;
        break;
      case 2:
        formInitialValues["equipmentFields[2]"] = x;
        break;
      default:
        throw new Error("Invalid Equipment Field");
    }
  });
  return (
    <div>
      <h3>Select a Cell to Edit</h3>
      {cell !== undefined && cell !== null ? (
        <div>
          <div>
            Cell (x,y) or (lat, long): <strong>{cell.cellId}</strong>
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onSaveEditCell}
            initialValues={formInitialValues}
          >
            <Form.Item
              label="Name:"
              name="name"
              className={style.EditCellFormItem}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Approximate Height (meters):"
              name="height"
              className={style.EditCellFormItem}
            >
              <Input />
            </Form.Item>
            {equipmentFields.map((equipment: string, i: number) => (
              <Form.Item
                key={i}
                label={`Equipment ${i + 1}:`}
                name={`equipmentFields[${i}]`}
                className={style.EditCellFormItem}
              >
                <Select
                  value={equipment}
                  onChange={(value) => handleEquipmentChange(i, value)}
                >
                  {EQUIPMENT_OPTIONS.map((option) => (
                    <Select.Option key={option} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
                {i > 0 && (
                  <Button type="dashed" onClick={() => removeEquipmentField(i)}>
                    Remove
                  </Button>
                )}
              </Form.Item>
            ))}
            {equipmentFields.length < 3 ? (
              <Button
                className={style.EditCellFormButton}
                type="default"
                onClick={addEquipmentField}
              >
                Add Equipment
              </Button>
            ) : (
              <></>
            )}
            <Form.Item>
              <Button
                className={style.EditCellFormButton}
                type="primary"
                htmlType="submit"
              >
                Save Grid
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EditCell;
