import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Organization, OrganizationsState } from "./types";
import { PURGE } from "redux-persist";

const initialState: OrganizationsState = {
  currentOrganization: null,
};

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setCurrentOrganization: (
      state: OrganizationsState,
      action: PayloadAction<Organization>
    ) => {
      state.currentOrganization = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState;
    });
  }
});

export const { setCurrentOrganization } = organizationsSlice.actions;

export default organizationsSlice.reducer;
