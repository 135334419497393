import { FC } from "react";
import { TimePicker } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import styles from "./styles.module.scss";
import { SizeType } from "antd/lib/config-provider/SizeContext";

interface TimePickerWithArrowsProps {
  onTimeChange: (time: Dayjs, timeString: string | string[]) => void;
  selectedTime: string;
  size?: SizeType | undefined;
}

const TimePickerWithArrows: FC<TimePickerWithArrowsProps> = ({
  onTimeChange,
  selectedTime,
  size,
}) => {
  return (
    <div className={styles.Wrapper}>
      <LeftOutlined
        onClick={() => {
          onTimeChange(
            dayjs(selectedTime, "HH:mm").subtract(1, "hour"),
            dayjs(selectedTime, "HH:mm").subtract(1, "hour").format("HH:mm")
          );
        }}
      />
      <TimePicker
        onChange={onTimeChange}
        value={dayjs(selectedTime, "HH:mm")}
        format="HH:mm"
        size={size}
        allowClear={false}
      />
      <RightOutlined
        onClick={() => {
          onTimeChange(
            dayjs(selectedTime, "HH:mm").add(1, "hour"),
            dayjs(selectedTime, "HH:mm").add(1, "hour").format("HH:mm")
          );
        }}
      />
    </div>
  );
};

export default TimePickerWithArrows;
