import { ChangeEvent, FC, useState } from "react";
import { Space, Table, Radio, Modal, Input, Switch } from "antd";
import type { RadioChangeEvent } from "antd";
import type { ColumnsType } from "antd/es/table";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../store/hooks";
import { currentSiteSelector } from "../../store/sites/selector";
import {
  useGetSiteNodesQuery,
  useAssignNodeMutation,
} from "../../store/nodes/api";
import { currentUserSelector } from "../../store/auth/selector";
import { Node } from "../../store/nodes/types";
import { generateTimestamp } from "../../helpers/dateTimeHelpers";
import AvailableNodes from "./AvailableNodes/AvailableNodes";
import NodeForm from "./NodeForm/NodeForm";
import styles from "./styles.module.scss";
import NodeInfo from "../NodeInfo/NodeInfo";

const SITE_NODES = "SITE_NODES";
const AVAILABLE_NODES = "AVAILABLE_NODES";
const initialNodeState = {
  SiteId: "",
  NodeId: "",
  active: false,
  elevation: "",
  latitude: "",
  longitude: "",
  MacAddress: "",
  name: "",
  OrgId: "",
  sampleHeight: "",
  useWind: true,
};

const Nodes: FC = () => {
  const [formKey, setFormKey] = useState(0);
  const [node, setNode] = useState<Node>(initialNodeState);
  const [showEditModal, setShowEditModal] = useState(false);
  const currentSite = useAppSelector(currentSiteSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const siteId = currentSite?.SiteId ?? "";
  const { data, isError, isLoading } = useGetSiteNodesQuery({ id: siteId });
  const [selection, setSelection] = useState(SITE_NODES);
  const [assignNode] = useAssignNodeMutation();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const [searchInput, setSearchInput] = useState("");
  const [uninstallReason, setUninstallReason] = useState("");
  const [isReinstall, setIsReinstall] = useState(false);
  const [showNodeInfo, setShowNodeInfo] = useState(false);

  const onStartUnassignNode = (nodeData: Node): void => {
    setShowConfirmationModal(true);
    setSelectedNode(nodeData);
  };

  const onConfirmUnassignNode = (): void => {
    if (selectedNode !== null) {
      const payload = {
        ...selectedNode,
        installDate: "UNASSIGNED",
        SiteId: "UNASSIGNED",
        oldSiteId: siteId,
      };
      let uninstallReasonText = uninstallReason;
      if (isReinstall) {
        payload.signedOffBy = currentUser ?? "undefined";
        payload.signedOffOn = generateTimestamp();
        uninstallReasonText += " [reinstall]";
      }
      assignNode({ node: payload, uninstallReason: uninstallReasonText })
        .unwrap()
        .then((res) => {
          if (res?.Message === "Success") {
            window.alert("Node Unassigned Successfuly");
            onCancelUnassignNode();
          } else {
            window.alert("Error unassigning node");
            onCancelUnassignNode();
          }
        })
        .catch((err) => {
          console.log("Error unassigning node:\n", err);
          window.alert("Error Unassigning Node");
          onCancelUnassignNode();
        });
    } else {
      window.alert("Error while unassigning node");
      onCancelUnassignNode();
    }
  };

  const onCancelUnassignNode = (): void => {
    setShowConfirmationModal(false);
    setSelectedNode(null);
  };

  const onClickOkNodeInfoModal = (): void => {
    setShowNodeInfo(false);
    setSelectedNode(null);
  };

  const onClickCancelNodeInfoModal = (): void => {
    setShowNodeInfo(false);
    setSelectedNode(null);
  };

  const onClickNodeId = (node: Node): void => {
    setSelectedNode(node);
    setShowNodeInfo(true);
  };

  const columns: ColumnsType<Node> = [
    {
      title: "MAC Address",
      key: "NodeId",
      render: (node: Node) => {
        return (
          <a
            onClick={() => onClickNodeId(node)}
          >{node.NodeId}</a>
        );
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Active",
      key: "active",
      render: (nodeData: Node) => (
        <Space size="middle">
          {nodeData.active ? (
            <CheckCircleOutlined style={{ color: "green" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red" }} />
          )}
        </Space>
      ),
    },
    {
      title: "Installation Date",
      key: "installDate",
      render: (nodeData: Node) => (
        <Space size="middle">
          <p>{nodeData.installDate?.split("T")[0] ?? "-"}</p>
        </Space>
      ),
    },
    {
      title: "Action",
      key: "NodeId",
      render: (nodeData: Node) => (
        <Space size="middle">
          <a onClick={() => onClickEdit(nodeData)}>Edit</a>
          <a onClick={() => onStartUnassignNode(nodeData)}>Unassign</a>
        </Space>
      ),
    },
  ];

  const onClickEdit = (node: Node): void => {
    setFormKey(formKey + 1);
    setNode(node);
    setShowEditModal(true);
  };

  const onCancelEdit = (): void => {
    setShowEditModal(false);
  };

  const getRowClassName = (_: unknown, index: number): string => {
    return index % 2 === 0 ? styles.EvenRow : styles.OddRow;
  };

  const sortBooleans = (a: boolean, b: boolean): number => {
    return a === b ? 0 : a ? -1 : 1;
  };

  let tableData: Node[] = [];
  if (!isError && !isLoading && data !== undefined) {
    tableData = data.result?.map((ele) => {
      return { ...ele, key: ele.NodeId };
    });
    tableData.sort((a: Node, b: Node) => sortBooleans(a.active, b.active));
    tableData = tableData.filter((item) => {
      return (
        String(item.NodeId).toLowerCase().includes(searchInput) ||
        String(item.name).toLowerCase().includes(searchInput)
      );
    });
  }

  const siteNodes: JSX.Element = (
    <div>
      <Modal
        centered
        open={showNodeInfo}
        title={
          <span
            className={styles.NodeInfoModalTitle}
          >{`Node ${(selectedNode != null) ? selectedNode.NodeId : ""}`}</span>
        }
        onCancel={onClickCancelNodeInfoModal}
        onOk={onClickOkNodeInfoModal}
        width="90vw"
      >
        <NodeInfo
          nodeId={(selectedNode != null) ? selectedNode.NodeId : ""}
          siteName={(currentSite != null) ? currentSite.name : ""}
          userEmail={currentUser ?? ""}
        />
      </Modal>
      <h3>Site Nodes</h3>
      <Table
        columns={columns}
        dataSource={tableData}
        loading={isLoading}
        rowClassName={getRowClassName}
        pagination={false}
        scroll={{ y: "50vh" }}
      />
    </div>
  );

  const onChangeRadio = (event: RadioChangeEvent): void => {
    const newSelection: string = event.target.value as string;
    setSelection(newSelection);
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const search = e.target.value.toLowerCase();
    setSearchInput(search);
  };

  return (
    <div>
      <Modal
        centered
        open={showConfirmationModal}
        title={`Confirm Unassign: ${
          selectedNode !== null ? selectedNode.name : ""
        }`}
        onCancel={onCancelUnassignNode}
        onOk={onConfirmUnassignNode}
        okButtonProps={{
          disabled: uninstallReason === "",
        }}
      >
        <div>Add Uninstall Reason</div>
        <Input.TextArea
          value={uninstallReason}
          onChange={(e) => setUninstallReason(e.target.value)}
        />
        <div className={styles.ReinstallText}>
          Reinstall
          <br />
          <span>Moving node to new location</span>
        </div>
        <Switch
          value={isReinstall}
          onChange={(newBool) => setIsReinstall(newBool)}
        />
      </Modal>
      <Modal
        centered
        open={showEditModal}
        title="Edit Node"
        footer={null}
        onCancel={onCancelEdit}
      >
        <NodeForm
          key={formKey}
          node={node}
          onSubmit={onCancelEdit}
          onCancel={onCancelEdit}
        />
      </Modal>
      <div className={styles.Flex}>
        <div className={styles.ButtonWrapper}>
          <Radio.Group
            onChange={onChangeRadio}
            defaultValue={selection}
            size="middle"
          >
            <Radio.Button value={SITE_NODES} checked={selection === SITE_NODES}>
              Site Nodes
            </Radio.Button>
            <Radio.Button
              value={AVAILABLE_NODES}
              checked={selection === AVAILABLE_NODES}
            >
              Available Nodes
            </Radio.Button>
          </Radio.Group>
        </div>
        <div style={{ marginLeft: "15px" }}>
          <Input placeholder="Search..." allowClear onChange={onChangeSearch} />
        </div>
      </div>
      {selection === SITE_NODES ? (
        siteNodes
      ) : selection === AVAILABLE_NODES ? (
        <AvailableNodes searchInput={searchInput} />
      ) : (
        siteNodes
      )}
    </div>
  );
};

export default Nodes;
