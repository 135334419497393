import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import type { User } from "../users/types";

interface GetCurrentUserResponse {
  user: User;
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery,
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    getCurrentUser: builder.query<User, null>({
      query: () => "user",
      transformResponse: (returnValue: GetCurrentUserResponse) => {
        return returnValue.user;
      },
      providesTags: ["Auth"],
    }),
  }),
});

export const { useGetCurrentUserQuery } = authApi;
