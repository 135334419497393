import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { NodeReportsParams, NodeReportsResponse } from "./types";

export const nodeReportsApi = createApi({
  reducerPath: "nodeReportsApi",
  baseQuery,
  endpoints: (builder) => ({
    getLastNodeReport: builder.query<NodeReportsResponse, NodeReportsParams>({
      query: (payload) => {
        const { id } = payload;
        return {
          url: `nodes/${id}/lastNodeReport`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetLastNodeReportQuery } = nodeReportsApi;
