import { Form, Select, Button, Row, Col } from "antd";
import { FC, useState, useEffect } from "react";
import { useAppSelector } from "../../store/hooks";
import { currentSiteSelector } from "../../store/sites/selector";
import {
  startTimestampSelector,
  endTimestampSelector,
} from "../../store/selections/selector";
import { useGetCurrentUserQuery } from "../../store/auth/api";
import { requestCsvDownloadApi } from "../../store/requestCsvDownload/api";
import { siteNodesApi } from "../../store/nodes/api";
import {
  RequestCsvDownload,
  RequestCsvDownloadResponse,
} from "../../store/requestCsvDownload/types";
import TimeRangePicker from "../TimeRangePicker/TimeRangePicker";
import {
  NodeOption,
  ColumnOption,
  DataFile,
  FormSelections,
  FileTypes,
  AdminFileTypes,
} from "./types";
import {
  adminNodePPMProjection,
  clientNodePPMProjection,
  nodePPMColumnNameMap,
  adminRawSamplesProjection,
  rawNodeSamplesColumnNameMap,
  projectionStringToArray,
  partnerNodePPMProjection,
} from "./helpers";
import styles from "./styles.module.scss";

const { Option } = Select;

const MAX_NUM_NODES = 6;

const commonFileTypes = {
  NodePPM: "NodePPM",
  DailyEmissions: "DailyEmissions",
  GPAQSAvgEmissionEvents: "GPAQSAvgEmissionEvents",
};

const adminFileTypes = {
  GPAQS: "GPAQS",
  RawSamples: "RawSamples",
  RegressionCoefficients: "RegressionCoefficients",
};

const commonStatTypeOptions = [
  {
    label: "Node PPM",
    value: commonFileTypes.NodePPM,
  },
  {
    label: "Daily Emissions",
    value: commonFileTypes.DailyEmissions,
  },
  {
    label: "Average Emission Rates",
    value: "GPAQSAvgEmissionEvents",
  },
];

const adminStatTypeOptions = [
  {
    label: "Raw Samples",
    value: adminFileTypes.RawSamples,
  },
  {
    label: "GPAQS - Emission Events",
    value: adminFileTypes.GPAQS,
  },
  {
    label: "Regression Coefficients",
    value: adminFileTypes.RegressionCoefficients,
  },
];

const adminNodePPMColumns = projectionStringToArray(adminNodePPMProjection);
const clientNodePPMColumns = projectionStringToArray(clientNodePPMProjection);
const partnerNodePPMColumns = projectionStringToArray(partnerNodePPMProjection);

const adminRawSamplesColumns = projectionStringToArray(
  adminRawSamplesProjection
);

const ignoreColumns = ["NodeId", "SiteId", "#Timestamp"];

const DownloadRequest: FC = () => {
  const startTimestamp = useAppSelector(startTimestampSelector);
  const endTimestamp = useAppSelector(endTimestampSelector);
  const currentSite = useAppSelector(currentSiteSelector);
  const siteId = currentSite?.SiteId ?? "";
  const userData = useGetCurrentUserQuery(null);
  const emailAddress = userData?.data?.email ?? "";
  const isAdmin = userData?.data?.isAdmin ?? false;
  const isPartner = userData?.data?.isPartner ?? false;

  const FILE_TYPES: AdminFileTypes | FileTypes = commonFileTypes;
  const statTypeOptions: Array<{ label: string; value: string }> =
    commonStatTypeOptions;
  if (isAdmin) {
    Object.assign(FILE_TYPES, adminFileTypes);
    adminStatTypeOptions.forEach((option) => {
      if (!statTypeOptions.includes(option)) statTypeOptions.push(option);
    });
  }

  const [triggerGetRequestDownload] =
    requestCsvDownloadApi.endpoints.getRequestCsvDownload.useLazyQuery();

  const [form] = Form.useForm();
  const formSelectionsInitialState: FormSelections = {
    nodeIds: [],
    columns: [],
    fileType: "",
  };
  const [formSelections, setFormSelections] = useState<FormSelections>(
    formSelectionsInitialState
  );

  const resultSiteNodes = siteNodesApi.endpoints.getSiteNodes.useQuery({
    id: siteId,
  });
  const siteNodes = resultSiteNodes?.data?.result ?? [];
  const activeSiteNodes = siteNodes.filter((node) => node.active);
  const siteNodeOptions = activeSiteNodes.map((node) => {
    return {
      name: node.name,
      id: node.NodeId,
    };
  });

  let columns: string[];
  switch (formSelections.fileType) {
    case "NodePPM":
      columns = isAdmin
        ? adminNodePPMColumns
        : isPartner
          ? partnerNodePPMColumns
          : clientNodePPMColumns;
      break;
    case "RawSamples":
      columns = adminRawSamplesColumns;
      break;
    default:
      columns = [];
  }
  const filteredColumns = columns.filter((col) => !ignoreColumns.includes(col));
  const columnOptions = filteredColumns.map((name: string, index: number) => {
    let displayName = "";
    switch (formSelections.fileType) {
      case FILE_TYPES.NodePPM:
        displayName = nodePPMColumnNameMap[name];
        break;
      case (FILE_TYPES as AdminFileTypes).RawSamples:
        displayName = rawNodeSamplesColumnNameMap[name];
        break;
    }
    return {
      id: name,
      name,
      displayName,
    };
  });
  columnOptions.sort((a: ColumnOption, b: ColumnOption) => {
    return a.displayName
      .toLowerCase()
      .localeCompare(b.displayName.toLowerCase());
  });
  columnOptions.unshift({ name: "ALL", displayName: "ALL", id: "ALL" });

  const isMaxNumNodes = (id: string): boolean => {
    return formSelections.nodeIds.length >= MAX_NUM_NODES
      ? !formSelections.nodeIds.includes(id)
      : false;
  };

  const canSelectNodesColumns = (): boolean => {
    const { fileType } = formSelections;
    return (
      fileType === FILE_TYPES.NodePPM ||
      fileType === (FILE_TYPES as AdminFileTypes).RawSamples ||
      fileType === (FILE_TYPES as AdminFileTypes).RegressionCoefficients
    );
  };

  const canSelectDataColumns = (): boolean => {
    const { fileType } = formSelections;
    return (
      fileType === FILE_TYPES.NodePPM ||
      fileType === (FILE_TYPES as AdminFileTypes).RawSamples
    );
  };

  const handleClickClear = (): void => {
    setFormSelections(formSelectionsInitialState);
    form.resetFields();
  };

  const handleChangeNodes = (newNodes: string[]): void => {
    setFormSelections((formSelections) => ({
      ...formSelections,
      nodeIds: newNodes,
    }));
  };

  const handleChangeColumns = (newColumns: string[]): void => {
    setFormSelections((formSelections) => ({
      ...formSelections,
      columns: newColumns,
    }));
  };

  const handleFileTypeChange = (newVal: DataFile): void => {
    setFormSelections((formSelections) => ({
      ...formSelections,
      columns: [],
      fileType: newVal,
    }));
  };

  const handleDownloadRequest = async (): Promise<void> => {
    const { nodeIds, fileType } = formSelections;

    if (nodeIds.length === 0) {
      nodeIds.push("_"); // "nodeIds" must be truthy
    }

    let projectionColumns = [...formSelections.columns];
    projectionColumns.unshift(...["NodeId, #Timestamp"]);
    if (projectionColumns.includes("ALL")) {
      switch (formSelections.fileType) {
        case commonFileTypes.NodePPM:
          projectionColumns = isAdmin
            ? adminNodePPMColumns
            : isPartner
              ? partnerNodePPMColumns
              : clientNodePPMColumns;
          break;
        case adminFileTypes.RawSamples:
          projectionColumns = adminRawSamplesColumns;
          break;
      }
    }
    const projectionExpression = projectionColumns.join(",").replace(" ", "");

    const payload: RequestCsvDownload = {
      id: siteId,
      nodeIds,
      startDate: startTimestamp,
      endDate: endTimestamp,
      fileType,
      emailAddress,
      projectionExpression,
    };

    try {
      await triggerGetRequestDownload(payload)
        .unwrap()
        .then((response: RequestCsvDownloadResponse | null) => {
          if (response !== null && response.Message === "SUCCESS") {
            window.alert(
              `Data requested successfully and will be sent to ${emailAddress}`
            );
          }
        });
    } catch (e) {
      console.log(e);
      window.alert(
        "Error while requesting the data. Please try again later or contact Earthview"
      );
    }
  };

  useEffect(() => {
    form.setFieldsValue(formSelections);
  }, [formSelections]);

  return (
    <Form
      form={form}
      layout="vertical"
      wrapperCol={{ span: 12 }}
      onFinish={handleDownloadRequest}
      initialValues={formSelections}
    >
      <p>
        <b>Instructions:</b> Select the stat type and date range to request a
        data download in CSV format. The data will be generated and emailed to
        your email on file.
      </p>
      <Row>
        <Col span={5}>
          <Form.Item
            name="siteStatType"
            label="Stat Type"
            rules={[{ required: true, message: "Select a stat type" }]}
          >
            <Select
              options={statTypeOptions}
              onChange={handleFileTypeChange}
              allowClear={false}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            name="nodes"
            label="Nodes"
            rules={
              canSelectNodesColumns()
                ? [{ required: true, message: "Select 1-6 Node(s)" }]
                : undefined
            }
          >
            <Select
              mode="multiple"
              onChange={handleChangeNodes}
              allowClear={false}
              disabled={!canSelectNodesColumns()}
            >
              {siteNodeOptions.map((item: NodeOption) => {
                return (
                  <Option key={item.id} disabled={isMaxNumNodes(item.id)}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            name="columns"
            label="Data Columns"
            rules={
              canSelectDataColumns()
                ? [{ required: true, message: "Select Columns to Download" }]
                : undefined
            }
          >
            <Select
              mode="multiple"
              onChange={handleChangeColumns}
              allowClear={false}
              disabled={!canSelectDataColumns()}
            >
              {columnOptions.map((item: ColumnOption) => {
                return (
                  <Option key={item.id} style={{ height: "auto" }}>
                    {item.displayName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={9}>
          <TimeRangePicker />
        </Col>
      </Row>
      <div className={styles.Footer}>
        <div>
          <a onClick={handleClickClear}>Clear Entries</a>
        </div>
        <Form.Item style={{ margin: "15px" }}>
          <Button type="primary" htmlType="submit" size="large">
            Request Download
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default DownloadRequest;
