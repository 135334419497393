import { createGridOverlay } from "./gridOverlayHelper";
import { getCellMatrix } from "./mapApi";
import { applyMapLayers } from "./layerController";

export const applyMapLayersForDrone = (
  map,
  Maps,
  siteGrid,
  onClickCallback,
  showGridLayer,
  showActiveGridCells,
  additionalHighlightedCells
) => {
  let gMatrix = getCellMatrix(siteGrid);
  const defaultLineColor = "rgba(0, 0, 0, 1)";
  const defaultCellColor = "rgba(140, 140, 140, 0.39)";
  const transparentFill = "rgba(0, 0, 0, 0)";
  const activeCellFill = "rgba(82, 196, 26, 0.39)";
  const activeCellLineColor = "rgba(255, 255, 255, 1)";

  gMatrix = gMatrix.map((cell) => {
    return {
      ...cell,
      fillColor: transparentFill,
      strokeColor: transparentFill
    };
  });

  if (showGridLayer) {
    gMatrix = gMatrix.map((cell) => {
      return {
        ...cell,
        fillColor: defaultCellColor,
        strokeColor: defaultLineColor
      };
    });
  }
  if (showActiveGridCells) {
    gMatrix = gMatrix.map((cell) => {
      return {
        ...cell,
        fillColor: cell.isActive ? activeCellFill : cell.fillColor
      };
    });
  }

  additionalHighlightedCells.forEach((_cell) => {
    const indexInGMatrix = gMatrix.findIndex(
      (cell) => cell.cellId === _cell.cellId
    );
    if (indexInGMatrix !== -1) {
      const gMatrixItem = gMatrix[indexInGMatrix];
      gMatrixItem.fillColor = _cell.fillColor;
      if (gMatrixItem.isActive) {
        gMatrixItem.strokeColor = activeCellLineColor;
      }

      // move GPAQS item to back of cell matrix so it is rendered last
      gMatrix.splice(indexInGMatrix, 1);
      gMatrix.push(gMatrixItem);
    }
  });

  // draw active cells last so borders are rendered properly
  gMatrix.sort((a, b) => {
    return a.isActive === b.isActive ? 0 : a.isActive ? 1 : -1;
  });
  removeDroneLayersGrid();
  const gridLayer = createGridOverlay(Maps, map, siteGrid, gMatrix);
  gridLayer.id = "map-layer";
  map.layers.insert(gridLayer);
};

export const removeDroneLayersGrid = () => {
  const layer = window.map.layers.find((l) => l.id === "map-layer");
  if (layer) window.map.layers.remove(layer);
};

export const applyMapLayersForDroneWithVirtualGrid = (
  map,
  Maps,
  siteGrid,
  onClickCallback,
  showGridLayer,
  showActiveGridCells,
  additionalHighlightedCells,
  requireDroneImage,
  isMultiGrid
) => {
  if (requireDroneImage) {
    applyMapLayers(
      map,
      Maps,
      siteGrid,
      onClickCallback,
      false,
      false,
      [],
      isMultiGrid
    );
    applyMapLayersForDrone(
      map,
      Maps,
      siteGrid,
      null,
      showGridLayer,
      showActiveGridCells,
      additionalHighlightedCells
    );
  } else {
    applyMapLayers(
      map,
      Maps,
      siteGrid,
      onClickCallback,
      showGridLayer,
      showActiveGridCells,
      additionalHighlightedCells,
      isMultiGrid
    );
  }
};
