import { QuantityUnit } from "../../helpers/unitsHelpers";

export interface NodeOption {
  id: string;
  name: string;
  active: boolean;
}

export interface UnitOption {
  value: string;
  label: string;
}

export const unitOptions: UnitOption[] = [
  {
    value: QuantityUnit.MCF,
    label: QuantityUnit.MCF,
  },
  {
    value: QuantityUnit.KG,
    label: QuantityUnit.KG,
  },
];

export const STATS_TYPES = {
  NodePPM: "2",
  VOC: "3",
  NodePPMandVOC: "4",
  CO2: "5",
  AvgEmissionRates: "6",
};

export const epaCheckboxOptions: UnitOption[] = [
  { label: "90-day", value: "90-day" },
  { label: "7-day", value: "7-day" },
  { label: "Baseline", value: "Baseline" },
];

export interface EpaBaselineDisplays {
  doShow90Day: boolean;
  doShow7Day: boolean;
  doShowSiteBaseline: boolean;
}

export interface SiteStatsSelectionProps {
  selectedStat: string;
  statTypeOptions: Array<{ label: string; value: string }>;
  handleStatChange: (stat: string) => void;
  onNodeChange: (newNodes: string[]) => void;
  siteNodeOptions: NodeOption[];
  selectedNodeIds: string[];
  unitOptions: UnitOption[];
  selectedUnitOption: QuantityUnit;
  onUnitOptionChange: (unit: QuantityUnit) => void;
  refreshData: () => void;
  disabled: boolean;
  showLocalTime: boolean;
  handleChangeShowLocalTime: (newValue: boolean) => void;
  handleResetChartKey: () => void;
  handleSetChartTimerange: (
    startTimestamp: string,
    endTimestamp: string
  ) => void;
  doEnableEpasiteBaseline: boolean;
  setEpaBaselineDisplay: (newValue: EpaBaselineDisplays) => void;
}

export type TimeSeriesUnit = "hour" | "day";
