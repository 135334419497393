import { applyMapLayers } from "../../SiteMapv2/layerController";
import { applyMapLayersForDrone } from "../../SiteMapv2/layerControllerForDroneImages";

const emissionToColorHex = (value, maxValue, opacity) => {
  const normalizedValue = value / maxValue;

  const red = Math.round(255 * normalizedValue);
  const blue = Math.round(255 * (1 - normalizedValue));
  const green = 0;

  const colorHex = `#${((blue << 16) | (green << 8) | red)
    .toString(16)
    .padStart(6, "0")}`;

  const colorWithOpacity =
    colorHex +
    Math.round(opacity * 255)
      .toString(16)
      .padStart(2, "0");

  return colorWithOpacity;
};

export const toggleGPAQSLayerHelper = (
  newValue,
  setShowGPAQSLayer,
  showSiteGrid,
  onToggleGridLayer,
  isPpmToggled,
  onTogglePPMLayer,
  showWinds,
  onToggleWindsLayer,
  showActiveCells,
  onToggleActiveGridCells,
  showNodesLayer,
  onToggleNodesLayer,
  siteGrid,
  singleNodeSitePAQS,
  selectedPpmData,
  setSelectedGPAQSLargestValue,
  setAdditionalHighlightedCells,
  requireDroneImage
) => {
  setShowGPAQSLayer(newValue);
  window.showGPAQSLayer = newValue;
  if (newValue) {
    if (!showSiteGrid) onToggleGridLayer(true);
    if (!isPpmToggled) onTogglePPMLayer(true);
    if (!showWinds) onToggleWindsLayer(true);
    if (!showActiveCells) onToggleActiveGridCells(true);
    if (!showNodesLayer) onToggleNodesLayer(true);
  }
  if (siteGrid === undefined || Object.keys(siteGrid).length === 0) {
    window.alert("No site grid configurd for current site");
  } else {
    const onClickCallback = () => {};

    let [gpaqsData] = singleNodeSitePAQS;
    const selectedTimestamp = selectedPpmData?.Timestamp;
    if (selectedTimestamp) {
      const selectedTimestampMs = new Date(selectedTimestamp).getTime();
      for (const _gpaqsData of singleNodeSitePAQS) {
        const gpaqsDataTimeMs = new Date(_gpaqsData.Timestamp).getTime();
        if (gpaqsDataTimeMs > selectedTimestampMs) {
          gpaqsData = _gpaqsData;
          break;
        }
      }
    }

    const tempAdditionalHighlightedCells = [];
    if (newValue) {
      let largestGPAQSRate = 0.0;
      for (const gridCellId in gpaqsData) {
        const emissionRate = gpaqsData[gridCellId].Avg;
        if (emissionRate > largestGPAQSRate) largestGPAQSRate = emissionRate;
      }
      setSelectedGPAQSLargestValue(Number(largestGPAQSRate));

      for (const key in gpaqsData) {
        if (key.includes("(", ")")) {
          const emissionAvgMcfh = gpaqsData[key].Avg;
          tempAdditionalHighlightedCells.push({
            cellId: key,
            fillColor: emissionToColorHex(
              emissionAvgMcfh,
              largestGPAQSRate,
              0.5
            )
          });
        }
      }
    }
    setAdditionalHighlightedCells(tempAdditionalHighlightedCells);

    if (requireDroneImage) {
      applyMapLayersForDrone(
        window.map,
        window.Microsoft.Maps,
        siteGrid,
        onClickCallback,
        newValue ? true : showSiteGrid,
        showActiveCells,
        tempAdditionalHighlightedCells
      );
    } else {
      applyMapLayers(
        window.map,
        window.Microsoft.Maps,
        siteGrid,
        onClickCallback,
        newValue ? true : showSiteGrid,
        showActiveCells,
        tempAdditionalHighlightedCells
      );
    }
  }
};
